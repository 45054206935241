import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listAvailableServices,
  getServiceDetails,
  fetchIntegrationDetails,
  getClientKycDetail,
  voyceLogin,
  save_login_voyce,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
//import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  ADMIN_URL,
  KYC_URL,
  APP_URL,
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat from "dateformat";

import TextField from "../../../components/InputFields/TextField";

const auth = new HelperClass();

const newHeight = window.screen.height;

class Services extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      tableDataBundle: [],
      completeData: [],
      defaultServices: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      tableLoaderBundle: true,
      nrc_rate_type: "Flat",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: "",
      new_mrc: "",
      rate_type: "",
      rate: "",
      defaultRate: "",
      defaultSmsIn: "",
      defaultSmsOut: "",
      agent_mrc_comission: 0,
      agent_nrc_comission: 0.0,
      agent_rate_comission: 0,
      sms_in_comission: 0,
      sms_out_comission: 0,
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      product_desc: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
      default_mrc: "",
      default_nrc: "",
      default_rate: "",
      default_sms_in: "",
      default_sms_out: "",
      sms_in: "",
      sms_out: "",
      sms_in_percentage: "",
      sms_out_percentage: "",
      mrc_percentage: "",
      rate_percentage: "",
      select_service: "",
      bundleServiceDetails: [],
      bundleDetails: [],
      columnBundleServices: [],
      currentlink: "",
      currentPdfLink: "",
      sso_link: "",
      successMessageSso: "",
      errorMessageSso: "",
      forgotLink: "",
      login_email: "",
      errorLoginEmail: "",
      login_password: "",
      errorLoginPassword: "",
      disabled: false,
      voyceadmins_api_url: "",
      downloadLoader: false,

      unsigned_agreement_title: "",
      unsigned_agreement_accountno: "",
      service_id: "",
      form_accountno: "",
      form_link: "",

      columnServices: [
        { name: "Id" },
        { name: "SKU" },
        { name: "Title" },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date_initial(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Marketing",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultMarketing(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Order",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        { name: "connect", options: { display: "false" } },
        { name: "title", options: { display: "false" } },
        { name: "qbtitle", options: { display: "false" } },
        { name: "form_accountno", options: { display: "false" } },
        { name: "form_link", options: { display: "false" } },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Product Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>Product Details</span>
                                </a>
                              </li>
                              {tableMeta.rowData[8] &&
                                tableMeta.rowData[8] !== "" ? (
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.connect_integration(value, tableMeta)
                                    }
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=" integration "
                                  >
                                    <em className="icon ni ni-signin"></em>
                                    <span> Launch {tableMeta.rowData[8]} </span>
                                  </a>
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      columnBundleServices: [
        {
          name: "bundle_title",
          label: "Title",
          options: {
            filter: true,
          },
        },
        {
          name: "per_month_rate",
          label: "Per Month Rate (Charge Monthly)",
          options: {
            filter: true,
          },
        },
        {
          name: "per_year_rate",
          label: "Per Month Rate (Charge Yearly)",
          options: {
            filter: true,
          },
        },
        {
          name: "date_added",
          label: "Date Added",
          options: {
            customBodyRender: (value) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "id",
          label: "Actions",
          options: {
            customBodyRender: (value) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Tooltip
                                  title="Package Details"
                                  placement="top"
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.showBundleDetails(value)
                                    }
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-list"></em>
                                    <span>Package Details</span>
                                  </a>
                                </Tooltip>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  serviceSigning = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    await this.setServiceList();
    window.addEventListener("message", this.handlePostMessage);
  }

  componentWillUnmount() {
    // Remove event listener when component unmounts
    window.removeEventListener("message", this.handleMessage);
  }

  setServiceList = async () => {
    const servicesResponce = await listAvailableServices(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("listAvailableServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    }
  };

  connect_integration = async (value, tableMeta) => {
    let integration = tableMeta.rowData[7];
    let kyc_rerquired = tableMeta.rowData[5];

    if (kyc_rerquired === "Yes") {
      const servicesResponce = await getClientKycDetail(
        auth.getClientAccount(),
        auth.getClientToken()
      );
      console.log("KYC Details: ", servicesResponce.data);
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (servicesResponce.data.status === 406) {
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 404) {
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 200) {
        if (
          servicesResponce.data.steps_allowed ===
          servicesResponce.data.steps_completed
        ) {
          this.fetchIntegration(integration);
        } else {
          window.location.href =
            KYC_URL + "client/register/" + servicesResponce.data.kyc_key;
        }
      }
    } else {
      this.fetchIntegration(integration);
    }
  };

  fetchIntegration = async (integration) => {
    const servicesResponce = await fetchIntegrationDetails(
      auth.getClientAccount(),
      auth.getClientToken(),
      integration
    );
    console.log("fetchIntegrationDetails: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        clientData: servicesResponce.data.data[0],
      });
      let json = JSON.stringify({
        email: this.state.clientData.email, //"sufyain@tcpaas.com", // this.state.clientData.email,
        comp_name: this.state.clientData.comp_name,
        name: this.state.clientData.name,
        phone: this.state.clientData.phone,
        address: this.state.clientData.address,
        city: this.state.clientData.city,
        state: this.state.clientData.state,
        zip: this.state.clientData.zip,
        country: this.state.clientData.country,
      });
      const userEencodedString = btoa(json);
      if (integration === "voycetel") {
        var w = 600;
        var h = 650;
        var left = window.screen.width / 2 - w / 2;
        var top = window.screen.height / 2 - h / 2;
        var LoginUrl =
          servicesResponce.data.integration.other_settings.login_url;
        let childWindow = window.open(
          LoginUrl + "?user_hash=" + userEencodedString,
          "_blank",
          "width=600,height=650,top=" + top + ",left=" + left
        );
        setTimeout(function () {
          if (childWindow.opener) {
            console.log("Child window has a parent window.");
            console.log(" window.", childWindow);
          } else {
            console.log("Child window does not have a parent window.");
          }
        }, 6000);
      } else if (integration === "voyceadmins") {
        if (
          this.state.clientData.voyceadmins_access_token === null ||
          this.state.clientData.voyceadmins_access_token === ""
        ) {
          if (servicesResponce.data.user_exist === false) {
            var LoginUrl =
              servicesResponce.data.integration.other_settings.login_url +
              "?ref_url=" +
              btoa(APP_URL) +
              "&ref_key=1234567890&hash=" +
              userEencodedString;
            this.setState({
              sso_link: LoginUrl,
              successMessageSso: "",
              errorMessageSso: "",
            });
            window.$("#modalSsoRegister").modal("show");
          } else {
            /// open login modal for SSO  VoyceAdmins
            this.setState({
              login_email: this.state.clientData.email,
              forgotLink:
                servicesResponce.data.integration.api_url +
                "account/forgotpassword",
              errorLoginEmail: "",
              login_password: "",
              errorLoginPassword: "",
              voyceadmins_api_url: servicesResponce.data.integration.api_url,
              successMessageSso: "",
              errorMessageSso: "",
            });
            window.$("#modalSsoLogin").modal("show");
          }
        } else {
          let url =
            servicesResponce.data.integration.api_url +
            "account/access_auth?url=dashboard&access_token=" +
            this.state.clientData.voyceadmins_access_token;
          console.log("url = ", url);
          window.open(url);
        }
      }
    } else {
      //window.location.replace("/error");
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };
  format_date_initial = (value, tableMeta) => {
    let date = tableMeta.rowData[3];
    return dateFormat(date, "mmmm dS, yyyy");
  };

  defaultMarketing = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value.split("###");
    let marketing_content = is_default[0];
    let marketing_link = is_default[1];
    if (marketing_link && marketing_link != "") {
      if (marketing_content === "link") {
        return (
          <a
            style={{ cursor: "pointer" }}
            onClick={() => this.openLink(marketing_link)}
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-signin"></em> Product Info
            </span>
          </a>
        );
      } else if (marketing_content === "pdf") {
        return (
          <a
            style={{ cursor: "pointer" }}
            onClick={() => this.openPDFlink(marketing_link)}
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-download"></em> View PDF
            </span>
          </a>
        );
      } else {
        return "";
      }
    } else {
      return "---";
    }
  };

  defaultText = (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];
    let form_accountno = tableMeta.rowData[9];
    let form_link = tableMeta.rowData[10];

    return (
      <>
        {form_accountno && form_accountno !== "" ? (
          <a
            href={`${APP_URL}${auth.getResellerRegToken()}/client/client_form_submit/${form_accountno}/${form_link
              }/${service_id}`}
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-cart"></em> Order Now
            </span>
          </a>
        ) : (
          <a
            href={`${APP_URL}${auth.getResellerRegToken()}/client/EditSalesOrder/${this.generateRandomNumber()}/${service_id
              }`}
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-cart"></em> Order Now
            </span>
          </a>
        )}
      </>
    );
  };


  showDetails = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];
    let form_accountno = tableMeta.rowData[9];
    let form_link = tableMeta.rowData[10];
    this.setState({
      errorMessage: "",
      successMessage: "",
      unsigned_agreement_title: "",
      unsigned_agreement_accountno: "",
      service_id: service_id,
      form_accountno: form_accountno,
      form_link: form_link,
    });
    const servicesResponce = await getServiceDetails(
      auth.getClientAccount(),
      auth.getClientToken(),
      service_id
    );
    console.log("getAgentService: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        unsigned_agreement_title:
          servicesResponce.data.assigned_agreement_title,
        tableLoader: false,
        tableLoaderListing: false,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    window.$("#modalFormDetailDash").modal("show");
  };

  generateRandomNumber = () => {
    const min = 1000000; // Minimum 10-digit number
    const max = 9999999; // Maximum 10-digit number
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  };

  modalHide = () => {
    window.$("#modalFormDetailDash").modal("hide");
  };

  modalHideMarketingLink = () => {
    this.setState({
      currentlink: "",
    });
    window.$("#modalMarketingLink").modal("hide");
  };

  modalHideSsoRegister = () => {
    this.setState({
      sso_link: "",
      successMessageSso: "",
      errorMessageSso: "",
    });
    window.$("#modalSsoRegister").modal("hide");
  };

  modalHideSsoLogin = () => {
    this.setState({
      sso_link: "",
      successMessageSso: "",
      errorMessageSso: "",
    });
    window.$("#modalSsoLogin").modal("hide");
  };

  modalHideMarketingPDF = () => {
    this.setState({
      currentPdfLink: "",
    });
    window.$("#modalMarketingPDFLink").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  openLink = (link) => {
    this.setState({
      currentlink: link,
    });
    window.$("#modalMarketingLink").modal("show");
  };

  openPDFlink = (link) => {
    this.setState({
      currentPdfLink: link,
    });
    window.$("#modalMarketingPDFLink").modal("show");
  };

  downloadDeck = async (d_f_name) => {
    if (d_f_name !== "") {
      this.setState({ downloadLoader: true });
      var url = ADMIN_URL + "files_data/logos/" + d_f_name;
      const parts = url.split("/");
      let file_name = d_f_name;

      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
          this.setState({ downloadLoader: false });
        })
        .catch((error) => {
          this.setState({ downloadLoader: false });
          console.error("Fetch error:", error);
        });
    }
  };

  handlePostMessage = async (event) => {
    const message = event.data;
    console.log("Received message:", message);
    if (event.data.registration == "registration_success") {
      let asscessToken = event.data.token;
      let redirect_url = event.data.reg_complete_url;

      let apiResponce = await save_login_voyce(
        auth.getClientAccount(),
        auth.getClientToken(),
        asscessToken
      );
      console.log("save_login_voyce response -> ", apiResponce.data);

      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        // this.setState({
        //   successMessageSso: "Please wait, redirecting to the dashboard",
        // });
        this.setState({
          successMessageSso: "User Registred Successsfully",
        });
        setTimeout(() => {
          this.setState({
            successMessageSso: "",
          });
          this.modalHideSsoRegister();
          // let url =
          //   redirect_url + "?url=dashboard&access_token=" + asscessToken;
          //   console.log("url = ",url);
          window.open(redirect_url);
        }, 1000);
      } else {
        this.setState({
          errorMessageSso: apiResponce.data.message,
          disabled: false,
        });
        setTimeout(() => {
          this.setState({
            errorMessageSso: "",
            disabled: false,
          });
        }, 3000);
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  formLogin = async () => {
    let { login_email, login_password } = this.state;

    let is_validated = true;
    if (login_email === "") {
      this.setState({
        errorLoginEmail: "error",
        errorLoginPassword: "",
        errorMessageSso: "Email is required.",
      });
      is_validated = false;
    } else if (login_password === "") {
      this.setState({
        errorLoginEmail: "",
        errorLoginPassword: "error",
        errorMessageSso: "Password is required.",
      });
      is_validated = false;
    }

    if (is_validated) {
      this.setState({
        errorLoginEmail: "",
        errorLoginPassword: "",
        errorMessageSso: "",
        disabled: true,
      });

      let apiResponce = await voyceLogin(
        auth.getClientAccount(),
        auth.getClientToken(),
        login_email,
        login_password,
        this.state.voyceadmins_api_url
      );
      console.log("login response -> ", apiResponce.data);

      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        this.setState({
          errorLoginEmail: "",
          errorLoginPassword: "",
          successMessageSso: "Please wait, redirecting to the dashboard",
          disabled: true,
          login_email: "",
          forgotLink: "",
          login_password: "",
          errorLoginPassword: "",
          voyceadmins_api_url: "",
        });
        setTimeout(() => {
          this.setState({
            errorLoginEmail: "",
            errorLoginPassword: "",
            errorMessageSso: "",
            disabled: false,
          });
          this.modalHideSsoLogin();
          window.open(
            apiResponce.data.data.url.access_auth +
            "?url=dashboard&access_token=" +
            apiResponce.data.data.access_token
          );
        }, 3000);
      } else {
        this.setState({
          errorLoginEmail: "",
          errorLoginPassword: "",
          errorMessageSso: apiResponce.data.message,
          disabled: false,
        });
        setTimeout(() => {
          this.setState({
            errorLoginEmail: "",
            errorLoginPassword: "",
            errorMessageSso: "",
            disabled: false,
          });
        }, 3000);
      }
    } else {
      setTimeout(() => {
        this.setState({
          errorLoginEmail: "",
          errorLoginPassword: "",
          errorMessageSso: "",
          disabled: false,
        });
      }, 3000);
    }
  };

  formatAsCurrencys = (amount) => {
    console.log("amount: ", typeof amount);
    //amount = this.formatRate(amount);
    let decimal_point = 2;
    if (
      this.props.quotationSettings[0]?.decimal_point &&
      this.props.quotationSettings[0]?.decimal_point !== ""
    ) {
      decimal_point = this.props.quotationSettings[0]?.decimal_point;
    }
    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }

    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimal_point,
      maximumFractionDigits: decimal_point,
    });
  };

  formatRate = (rate) => {
    let decimal_point = 2;
    if (
      this.props.quotationSettings[0]?.decimal_point &&
      this.props.quotationSettings[0]?.decimal_point !== ""
    ) {
      decimal_point = this.props.quotationSettings[0]?.decimal_point;
    }
    // Ensure rate is a number
    if (typeof rate !== "number") {
      rate = parseFloat(rate);
    }

    // Handle case where rate is NaN (invalid input)
    if (isNaN(rate)) {
      //return "Invalid rate";
      return (0).toFixed(decimal_point);
    }

    // Handle case where rate is zero
    if (rate === 0) {
      return (0).toFixed(decimal_point);
    }

    // Round the rate to the specified decimal points
    const formattedRate = rate.toFixed(decimal_point);

    return formattedRate;
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    console.log(
      "Service Props: ",
      this.props.quotationSettings[0]?.decimal_point
    );
    return (
      <div
        className={
          this.props.from === "dashboard"
            ? "nk-content newcontent_padding"
            : "nk-content"
        }
        id="Services_Block"
      >
        <div
          className={
            this.props.from === "dashboard"
              ? "container-fluid newcontainerfluid_padding"
              : "container-fluid"
          }
        >
          {this.props.defaultSettings === "missing" &&
            this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
            this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Products & Services"
                        icon="icon ni ni-card-view"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        {/* <em className="icon ni ni-more-v"></em> */}
                      </a>
                      {/* <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            {this.props.defaultSettings !== "missing" ? (
                              <a
                                href={"/" + auth.getResellerIdentifier() +  "/client/services/Addservice"}
                                style={{
                                  color: "#fff",
                                }}
                                className="btn btn-primary"
                              >
                                <em className="icon ni ni-plus-round"></em>
                                <span>Add New Service</span>
                              </a>
                            ) : null}
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          <div class="tab-content">
                            <div class="tab-pane active" id="tabItem5">
                              {/* START DATATABLE */}
                              {this.state.tableLoader === true ? (
                                TableLoader()
                              ) : (
                                <DataTableExtended
                                  columns={this.state.columnServices}
                                  tableData={this.state.tableData}
                                  title="Products & Services"
                                />
                              )}

                              {/* END TABLE */}
                            </div>
                            <div class="tab-pane" id="tabItem6">
                              {this.state.tableLoaderBundle === true ? (
                                TableLoader()
                              ) : (
                                <>
                                  {this.state.tableDataBundle &&
                                    this.state.tableDataBundle.length > 0 ? (
                                    <DataTableExtended
                                      columns={this.state.columnBundleServices}
                                      tableData={this.state.tableDataBundle}
                                      title="All Packages"
                                    />
                                  ) : null}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/*  DETAILS MODAL*/}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDetail"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              Product Details
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          {/* NRC DIVS START */}

                          {this.state.serviceData ? (
                            <>
                              <div>
                                {this.state.serviceData.map((services, idx) => (
                                  <div
                                    className="card card-bordered"
                                    key={`servi${idx}`}
                                  >
                                    <div className="card-inner-group">
                                      {this.state.unsigned_agreement_title &&
                                        this.state.unsigned_agreement_title !==
                                        "" ? (
                                        <div class="example-alert">
                                          <div class="alert alert-warning alert-icon">
                                            <em class="icon ni ni-alert-circle"></em>{" "}
                                            Your agreement{" "}
                                            <strong>
                                              {
                                                this.state
                                                  .unsigned_agreement_title
                                              }
                                            </strong>{" "}
                                            is missing.
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "did" ||
                                        services[6] === "tfn" ||
                                        services[6] === "teams" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                {services[6] === "did"
                                                  ? "DID Origination"
                                                  : ""}
                                                {services[6] === "tfn"
                                                  ? "Toll Free Origination"
                                                  : ""}
                                                {services[6] === "term"
                                                  ? "Voice Termination"
                                                  : ""}
                                                {services[6] === "teams"
                                                  ? "Microsoft Teams UCAAS"
                                                  : ""}
                                                {services[6] === "ivr"
                                                  ? "Voyce Admins"
                                                  : ""}
                                                {services[6] === "tfn_term"
                                                  ? "Toll Free Termination"
                                                  : ""}
                                                {services[6] === "az_term"
                                                  ? "International A-Z Termination"
                                                  : ""}
                                                {services[6] === "live_agent"
                                                  ? "Live Agent"
                                                  : ""}
                                                {services[6] === "generic"
                                                  ? "Other Services"
                                                  : ""}
                                                {services[6] === "sms"
                                                  ? "SMS Inbound / Outbound"
                                                  : ""}
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    NRC
                                                    <Tooltip
                                                      title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[4]}
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    MRC{" "}
                                                    <Tooltip
                                                      title="Monthly Recurring Charge - what you pay each month for the service."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[5]}
                                                </p>
                                              </li>
                                              {services[23] !== "ratedeck" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Per Minute{" "}
                                                      <Tooltip
                                                        title="Rate assigned per minute."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${services[7]}
                                                  </p>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "term" ||
                                        services[6] === "ivr" ||
                                        services[6] === "live_agent" ||
                                        services[6] === "tfn_term" ||
                                        services[6] === "az_term" ||
                                        services[6] === "generic" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                {services[6] === "did"
                                                  ? "DID Origination"
                                                  : ""}
                                                {services[6] === "tfn"
                                                  ? "Toll Free Origination"
                                                  : ""}
                                                {services[6] === "term"
                                                  ? "DID Termination"
                                                  : ""}
                                                {services[6] === "teams"
                                                  ? "Microsoft Teams UCAAS"
                                                  : ""}
                                                {services[6] === "ivr"
                                                  ? "Voyce Admins"
                                                  : ""}
                                                {services[6] === "tfn_term"
                                                  ? "Toll Free Termination"
                                                  : ""}
                                                {services[6] === "az_term"
                                                  ? "International A-Z Termination"
                                                  : ""}
                                                {services[6] === "live_agent"
                                                  ? "Live Agent"
                                                  : ""}
                                                {services[6] === "sms"
                                                  ? "SMS Inbound / Outbound"
                                                  : ""}
                                                {services[6] === "generic"
                                                  ? "General Products & Services"
                                                  : ""}
                                                {services[23] === "ratedeck"
                                                  ? " ( Rate Deck )"
                                                  : ""}
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              {services[23] === "ratedeck" ? (
                                                <>
                                                  {/*<li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Agent Commission
                                                      </span>{" "}
                                                      {services[14]}%
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Effective Date
                                                      </span>{" "}
                                                      {services[24]}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Download Rate Deck
                                                      </span>{" "}
                                                      <a
                                                        onClick={() =>
                                                          this.downloadCsv(
                                                            services[25]
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="btn btn-outline-primary"
                                                      >
                                                        <em className="icon ni ni-download"></em>{" "}
                                                        Download Rate Deck
                                                      </a>
                                                    </p>
                                                  </li>*/}
                                                </>
                                              ) : (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      {services[6] === "generic"
                                                        ? "Price"
                                                        : "Rate Per Minute"}{" "}
                                                      <Tooltip
                                                        title={
                                                          services[6] ===
                                                            "generic"
                                                            ? "Rate assigned"
                                                            : "Rate assigned per minute"
                                                        }
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {this.formatRate(
                                                      services[7]
                                                    )}
                                                  </p>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                          {services[6] === "generic" ? (
                                            <>
                                              <div
                                                className="sp-plan-head"
                                                style={{
                                                  marginTop: "15px",
                                                  borderTop:
                                                    "1px solid #f6f6f6",
                                                  paddingTop: "15px",
                                                }}
                                              >
                                                <h6 className="title">
                                                  Other Service Rates
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <table className="table">
                                                  <thead className="thead-light">
                                                    <tr>
                                                      <th scope="col">Title</th>
                                                      <th scope="col">
                                                        Charge Type
                                                      </th>
                                                      <th scope="col">Cycle</th>
                                                      <th scope="col">Price</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {services[34].map(
                                                      (miscrate, idx) => (
                                                        <tr key={`mis${idx}`}>
                                                          <td>
                                                            {
                                                              miscrate.generic_title
                                                            }
                                                          </td>
                                                          <td>
                                                            {miscrate.pay_type ===
                                                              "recurring"
                                                              ? "Recurring"
                                                              : ""}

                                                            {miscrate.pay_type ===
                                                              "one_time"
                                                              ? "One Time"
                                                              : ""}
                                                          </td>
                                                          <td>
                                                            {miscrate.pay_type ===
                                                              "recurring" ? (
                                                              <>
                                                                {miscrate.generic_type ===
                                                                  "per_minute"
                                                                  ? "Per Minute"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                  "per_hour"
                                                                  ? "Per Hour"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                  "per_second"
                                                                  ? "Per Second"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                  "per_day"
                                                                  ? "Per Day"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                  "per_month"
                                                                  ? "Per Month"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                  "per_year"
                                                                  ? "Per Year"
                                                                  : ""}
                                                              </>
                                                            ) : null}
                                                          </td>
                                                          <td>
                                                            {miscrate.generic_rate !==
                                                              "" &&
                                                              miscrate.generic_rate >
                                                              0 ? (
                                                              <>
                                                                {this.formatAsCurrencys(
                                                                  miscrate.generic_rate
                                                                )}
                                                              </>
                                                            ) : (
                                                              "$0.00"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      ) : null}
                                      {services[6] === "sms" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                New
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS In{" "}
                                                    <Tooltip
                                                      title="Price applied to SMS In Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[10]}
                                                </p>
                                              </li>
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS Out{" "}
                                                    <Tooltip
                                                      title="Price applied to SMS Out Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[11]}
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "did" ||
                                        services[6] === "tfn" ||
                                        services[6] === "teams" ? (
                                        <>
                                          {services[30] &&
                                            services[30].length > 0 ? (
                                            <div className="card-inner">
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                  Porting Rates
                                                </h6>
                                              </div>

                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Sell Rate
                                                      </span>{" "}
                                                      $
                                                      {services[30][0].portin_sell_rate.toFixed(
                                                        4
                                                      )}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Sell Rate
                                                      </span>{" "}
                                                      $
                                                      {services[30][0].portout_sell_rate.toFixed(
                                                        4
                                                      )}
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                      <div className="card-inner">
                                        {services[23] !== "ratedeck" ? (
                                          <>
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Cancellation Fee
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Cancellation Fee
                                                    </span>{" "}
                                                    {services[29] &&
                                                      services[29] !== "" ? (
                                                      <>
                                                        $
                                                        {services[29].toFixed(
                                                          2
                                                        )}
                                                      </>
                                                    ) : (
                                                      "$0.00"
                                                    )}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </>
                                        ) : null}
                                        {services[23] === "ratedeck" ? (
                                          <>
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Rate Deck / Effective Date
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Deck
                                                    </span>{" "}
                                                    {services[25] &&
                                                      services[25] !== "" ? (
                                                      <>
                                                        <a
                                                          onClick={() =>
                                                            this.downloadCsv(
                                                              services[25]
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="btn btn-outline-primary"
                                                        >
                                                          <em className="icon ni ni-download"></em>{" "}
                                                          Download Rate Deck
                                                        </a>
                                                      </>
                                                    ) : null}
                                                  </p>
                                                </li>
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Effective Date
                                                    </span>{" "}
                                                    {services[24] &&
                                                      services[24] !== "" ? (
                                                      <>
                                                        {this.format_date(
                                                          services[24]
                                                        )}
                                                      </>
                                                    ) : null}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </>
                                        ) : null}
                                        {services[31] &&
                                          services[31].length > 0 ? (
                                          <>
                                            <div
                                              className="sp-plan-head"
                                              style={{
                                                marginTop: "15px",
                                                borderTop: "1px solid #f6f6f6",
                                                paddingTop: "15px",
                                              }}
                                            >
                                              <h6 className="title">
                                                Miscellaneous Rates
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <table className="table">
                                                <thead className="thead-light">
                                                  <tr>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">
                                                      Rate Type
                                                    </th>
                                                    <th scope="col">Rate</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {services[31].map(
                                                    (miscrate, idx) => (
                                                      <tr key={`mis${idx}`}>
                                                        <td>
                                                          {miscrate.misc_title}
                                                        </td>
                                                        <td>
                                                          {miscrate.misc_type ===
                                                            "per_minute"
                                                            ? "Per Minute"
                                                            : ""}
                                                          {miscrate.misc_type ===
                                                            "per_hour"
                                                            ? "Per Hour"
                                                            : ""}
                                                          {miscrate.misc_type ===
                                                            "one_time"
                                                            ? "One Time"
                                                            : ""}
                                                        </td>
                                                        <td>
                                                          {miscrate.misc_rate !==
                                                            "" &&
                                                            miscrate.misc_rate >
                                                            0 ? (
                                                            <>
                                                              $
                                                              {miscrate.misc_rate.toFixed(
                                                                2
                                                              )}
                                                            </>
                                                          ) : (
                                                            "$0.00"
                                                          )}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        ) : null}
                                      </div>

                                      <div className="card-inner">
                                        <div className="sp-plan-head-group">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Short Description
                                            </h6>
                                            <span className="ff-italic text-soft">
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: services[3],
                                                }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DETAILS MODAL */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDetailBundle"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em> Bundle
                              Details
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          {/* NRC DIVS START */}

                          {this.state.bundleDetails &&
                            this.state.bundleDetails.length > 0 ? (
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                <div className="card-inner">
                                  <div className="sp-plan-head">
                                    <h6 className="title">
                                      {this.state.bundleDetails[0].bundle_title}
                                    </h6>
                                  </div>
                                  <div
                                    className="sp-plan-desc sp-plan-desc-mb"
                                    style={{
                                      marginBottom: "15px",
                                      borderBottom:
                                        "1px solid rgb(246, 246, 246)",
                                      paddingBottom: "7px",
                                    }}
                                  >
                                    <ul className="row gx-1">
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Per Month Rate (Charge Monthly){" "}
                                            <Tooltip
                                              title="Per Month Rate (Charge Monthly)"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>
                                          </span>{" "}
                                          $
                                          {this.state.bundleDetails[0].per_month_rate.toFixed(
                                            4
                                          )}
                                        </p>
                                      </li>
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Per Month Rate (Charge Yearly)
                                            <Tooltip
                                              title="Per Month Rate (Charge Yearly)"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>
                                          </span>{" "}
                                          $
                                          {this.state.bundleDetails[0].per_year_rate.toFixed(
                                            4
                                          )}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    className="sp-plan-desc sp-plan-desc-mb"
                                    style={{
                                      marginBottom: "15px",
                                      borderBottom:
                                        "1px solid rgb(246, 246, 246)",
                                      paddingBottom: "7px",
                                    }}
                                  >
                                    <ul className="row gx-1">
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Date Added
                                            <Tooltip
                                              title="Date Added"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>{" "}
                                          </span>{" "}
                                          {this.state.bundleDetails[0]
                                            .date_added !== ""
                                            ? this.format_date(
                                              this.state.bundleDetails[0]
                                                .date_added
                                            )
                                            : null}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="sp-plan-desc sp-plan-desc-mb">
                                    <ul className="row gx-1">
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Description
                                            <Tooltip
                                              title="Package Description"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>{" "}
                                          </span>{" "}
                                          {
                                            this.state.bundleDetails[0]
                                              .product_desc
                                          }
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DEFAULT MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDefault"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <h4 className="nk-modal-title">Set Default</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.waitMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-warning">
                                      <div className="alert-text">
                                        <h4>Waiting...</h4>
                                        <p>{this.state.waitMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="nk-modal-action"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DEFAULT MODAL End */}

                  {/*  DETAILS MODAL*/}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDetailDash"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              Product Details
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {/* NRC DIVS START */}

                          {this.state.serviceData &&
                            this.state.serviceData.length > 0 ? (
                            <>
                              <div
                                style={{
                                  marginBottom: "15px",
                                  textAlign: "end",

                                  cursor: "pointer",
                                }}
                                className="text-primary"
                              >
                                <a
                                  href={
                                    this.state.form_accountno && this.state.form_accountno !== ""
                                      ? `${APP_URL}${auth.getResellerRegToken()}/client/client_form_submit/${this.state.form_accountno}/${this.state.form_link}/${this.state.service_id}`
                                      : `${APP_URL}${auth.getResellerRegToken()}/client/EditSalesOrder/${this.generateRandomNumber()}/${this.state.service_id}`
                                  }
                                  className="btn btn-outline-primary"
                                >
                                  <em className="icon ni ni-cart"></em>{" "}
                                  <span>Order Now</span>
                                </a>
                              </div>
                              <div>
                                {this.state.serviceData.map((services, idx) => (
                                  <div
                                    className="card card-bordered"
                                    key={`servi${idx}`}
                                  >
                                    {this.state.unsigned_agreement_title &&
                                      this.state.unsigned_agreement_title !==
                                      "" ? (
                                      <div class="example-alert">
                                        <div class="alert alert-warning alert-icon">
                                          <em class="icon ni ni-alert-circle"></em>{" "}
                                          Your agreement (
                                          {this.state.unsigned_agreement_title})
                                          for this service is pending sign.
                                          Please sign this agreement to fully
                                          access and use this service.{" "}
                                          <a
                                            href={`${APP_URL}${auth.getResellerRegToken()}/client/agreements/unsignedAgreement/${services[43]
                                              }`}
                                            className="text-primary"
                                            style={{
                                              cursor: "pointer",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Click Here
                                          </a>{" "}
                                          to sign this agreement.{" "}
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="card-inner-group">
                                      {services[6] === "did" ||
                                        services[6] === "tfn" ||
                                        services[6] === "teams" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <div className="col-md-8">
                                              <h6 className="title">
                                                {services[1]}
                                              </h6>
                                            </div>
                                            <div className="4">
                                              <div class="price-plan-amount">
                                                <div class="amount"></div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    NRC
                                                    <Tooltip
                                                      title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[4]}
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    MRC{" "}
                                                    <Tooltip
                                                      title="Monthly Recurring Charge - what you pay each month for the service."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[5]}
                                                </p>
                                              </li>
                                              {services[23] !== "ratedeck" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Per Minute{" "}
                                                      <Tooltip
                                                        title="Rate assigned per minute."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${services[7]}
                                                  </p>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "term" ||
                                        services[6] === "ivr" ||
                                        services[6] === "live_agent" ||
                                        services[6] === "tfn_term" ||
                                        services[6] === "az_term" ||
                                        services[6] === "generic" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <div className="row g-gs">
                                              <div className="col-md-8">
                                                <h6 className="title">
                                                  {services[1]}
                                                </h6>
                                              </div>
                                              <div className="col-md-4 text-right">
                                                <div class="price-plan-amount">
                                                  <div class="amount">
                                                    <span className="bill fs-12px">
                                                      Price:{" "}
                                                    </span>

                                                    {this.formatAsCurrencys(
                                                      services[7]
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            {services[42]}
                                          </div>
                                          {services[6] === "generic" ? (
                                            <>
                                              <div
                                                className="sp-plan-head"
                                                style={{
                                                  marginTop: "15px",
                                                  borderTop:
                                                    "1px solid #f6f6f6",
                                                  paddingTop: "15px",
                                                }}
                                              >
                                                <h6 className="title">
                                                  Other Service Rates
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <table className="table">
                                                  <thead className="thead-light">
                                                    <tr>
                                                      <th scope="col">Title</th>
                                                      <th scope="col">
                                                        Charge Type
                                                      </th>
                                                      <th scope="col">Cycle</th>
                                                      <th scope="col">Price</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {services[34].map(
                                                      (miscrate, idx) => (
                                                        <tr key={`mis${idx}`}>
                                                          <td>
                                                            {
                                                              miscrate.generic_title
                                                            }
                                                          </td>
                                                          <td>
                                                            {miscrate.pay_type ===
                                                              "recurring"
                                                              ? "Recurring"
                                                              : ""}

                                                            {miscrate.pay_type ===
                                                              "one_time"
                                                              ? "One Time"
                                                              : ""}
                                                          </td>
                                                          <td>
                                                            {miscrate.pay_type ===
                                                              "recurring" ? (
                                                              <>
                                                                {miscrate.generic_type ===
                                                                  "per_minute"
                                                                  ? "Per Minute"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                  "per_hour"
                                                                  ? "Per Hour"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                  "per_second"
                                                                  ? "Per Second"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                  "per_day"
                                                                  ? "Per Day"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                  "per_month"
                                                                  ? "Per Month"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                  "per_year"
                                                                  ? "Per Year"
                                                                  : ""}
                                                              </>
                                                            ) : null}
                                                          </td>
                                                          <td>
                                                            {miscrate.generic_rate !==
                                                              "" &&
                                                              miscrate.generic_rate >
                                                              0 ? (
                                                              <>
                                                                {this.formatAsCurrencys(
                                                                  miscrate.generic_rate
                                                                )}
                                                              </>
                                                            ) : (
                                                              "$0.00"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      ) : null}
                                      {services[6] === "sms" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                New
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS In{" "}
                                                    <Tooltip
                                                      title="Price applied to SMS In Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[10]}
                                                </p>
                                              </li>
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS Out{" "}
                                                    <Tooltip
                                                      title="Price applied to SMS Out Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[11]}
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "did" ||
                                        services[6] === "tfn" ||
                                        services[6] === "teams" ? (
                                        <>
                                          {services[30] &&
                                            services[30].length > 0 ? (
                                            <div className="card-inner">
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                  Porting Rates
                                                </h6>
                                              </div>

                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Sell Rate
                                                      </span>{" "}
                                                      $
                                                      {services[30][0].portin_sell_rate.toFixed(
                                                        4
                                                      )}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Sell Rate
                                                      </span>{" "}
                                                      $
                                                      {services[30][0].portout_sell_rate.toFixed(
                                                        4
                                                      )}
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}

                                      {services[23] !== "ratedeck" ? (
                                        <>
                                          {services[29] &&
                                            services[29] !== "" &&
                                            services[29] > 0 ? (
                                            <div className="card-inner">
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                  Cancellation Fee
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      {services[29] &&
                                                        services[29] !== "" ? (
                                                        <>
                                                          {this.formatAsCurrencys(
                                                            services[29]
                                                          )}
                                                        </>
                                                      ) : (
                                                        "$0.00"
                                                      )}
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}

                                      {services[23] === "ratedeck" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Rate Deck / Effective Date
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Rate Deck
                                                  </span>{" "}
                                                  {services[25] &&
                                                    services[25] !== "" ? (
                                                    <>
                                                      <a
                                                        onClick={() =>
                                                          this.downloadCsv(
                                                            services[25]
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="btn btn-outline-primary"
                                                      >
                                                        <em className="icon ni ni-download"></em>{" "}
                                                        Download Rate Deck
                                                      </a>
                                                    </>
                                                  ) : null}
                                                </p>
                                              </li>
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Effective Date
                                                  </span>{" "}
                                                  {services[24] &&
                                                    services[24] !== "" ? (
                                                    <>
                                                      {this.format_date(
                                                        services[24]
                                                      )}
                                                    </>
                                                  ) : null}
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}

                                      {services[31] &&
                                        services[31].length > 0 ? (
                                        <div className="card-inner">
                                          <div
                                            className="sp-plan-head"
                                            style={{
                                              marginTop: "15px",
                                              borderTop: "1px solid #f6f6f6",
                                              paddingTop: "15px",
                                            }}
                                          >
                                            <h6 className="title">
                                              Miscellaneous Rates
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <table className="table">
                                              <thead className="thead-light">
                                                <tr>
                                                  <th scope="col">Title</th>
                                                  <th scope="col">Rate Type</th>
                                                  <th scope="col">Rate</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {services[31].map(
                                                  (miscrate, idx) => (
                                                    <>
                                                      {miscrate.misc_title &&
                                                        miscrate.misc_title !==
                                                        "" ? (
                                                        <tr key={`mis${idx}`}>
                                                          <td>
                                                            {
                                                              miscrate.misc_title
                                                            }
                                                          </td>
                                                          <td>
                                                            {miscrate.misc_type ===
                                                              "per_minute"
                                                              ? "Per Minute"
                                                              : ""}
                                                            {miscrate.misc_type ===
                                                              "per_hour"
                                                              ? "Per Hour"
                                                              : ""}
                                                            {miscrate.misc_type ===
                                                              "one_time"
                                                              ? "One Time"
                                                              : ""}
                                                          </td>
                                                          <td>
                                                            {miscrate.misc_rate !==
                                                              "" &&
                                                              miscrate.misc_rate >
                                                              0 ? (
                                                              <>
                                                                {this.formatAsCurrencys(
                                                                  miscrate.misc_rate
                                                                )}
                                                              </>
                                                            ) : (
                                                              "$0.00"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      ) : null}
                                                    </>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      ) : null}

                                      {services[35] &&
                                        services[35] === "link" &&
                                        services[36] !== "" ? (
                                        <>
                                          {services[36] !== "" ? (
                                            <div className="card-inner">
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                  Marketing Link
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Marketing Link
                                                      </span>{" "}
                                                      <a
                                                        href={services[36]}
                                                        target="_blank"
                                                      >
                                                        <em className="icon ni ni-link-h"></em>{" "}
                                                        Click to open link
                                                      </a>
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                      {services[35] &&
                                        services[35] === "pdf" &&
                                        services[36] !== "" ? (
                                        <>
                                          {services[36] !== "" ? (
                                            <div className="card-inner">
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                  Marketing Link
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Marketing Link
                                                      </span>{" "}
                                                      <a
                                                        href={services[36]}
                                                        target="_blank"
                                                      >
                                                        <em className="icon ni ni-file-pdf"></em>{" "}
                                                        View PDF
                                                      </a>
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}

                                      {services[39] && services[39] !== "" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head-group">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Document / Rate Deck{" "}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <br />
                                                  <a
                                                    onClick={() =>
                                                      this.downloadDeck(
                                                        services[39]
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    className="text-primary"
                                                  >
                                                    <em class="icon ni ni-download"></em>{" "}
                                                    {services[38]}{" "}
                                                    {this.state
                                                      .downloadLoader ===
                                                      true ? (
                                                      <div
                                                        class="spinner-border spinner-border-sm text-primary"
                                                        role="status"
                                                      >
                                                        <span class="sr-only">
                                                          Loading...
                                                        </span>
                                                      </div>
                                                    ) : null}
                                                  </a>
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}

                                      {services[40] && services[40] !== "" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head-group">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                3rd Party Integration{" "}
                                              </h6>
                                              <span className="text-body">
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: services[41],
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                      <div className="card-inner p_description">
                                        <div className="sp-plan-head-group">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Description
                                            </h6>
                                            <span className="text-body">
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: services[3],
                                                }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DETAILS MODAL */}

                  {/* Link Modal MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalMarketingLink"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              Marketing Link
                            </strong>
                          </div>
                          <a
                            className="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.modalHideMarketingLink();
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          <iframe
                            src={this.state.currentlink}
                            title="Marketing Link"
                            width="100%"
                            height={newHeight * 0.61}
                            style={{ border: "none" }}
                          ></iframe>
                          {console.log("my screen => ", window.screen.height)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* LINK MODAL End */}

                  {/* Link Modal MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalMarketingPDFLink"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              Marketing PDF
                            </strong>
                          </div>
                          <a
                            className="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.modalHideMarketingPDF();
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          <embed
                            src={this.state.currentPdfLink}
                            type="application/pdf"
                            width="100%"
                            height={newHeight * 0.61}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* LINK MODAL End */}

                  {/* SSO Modal MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalSsoRegister"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              VoyceAdmins
                            </strong>
                          </div>
                          <a
                            className="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.modalHideSsoRegister();
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          {this.state.successMessageSso !== "" ? (
                            <div className="example-alert mb-4">
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessageSso}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.errorMessageSso !== "" ? (
                            <div className="example-alert mb-3">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessageSso}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <iframe
                            src={this.state.sso_link}
                            title="Voyceadmins"
                            width="100%"
                            height={newHeight * 0.61}
                            style={{ border: "none" }}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* SSSO MODAL End */}

                  {/* SSO Modal MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalSsoLogin"
                  >
                    <div
                      className="modal-dialog modal-lg modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              VoyceAdmins
                            </strong>
                          </div>
                          <a
                            className="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.modalHideSsoLogin();
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {this.state.errorMessageSso !== "" ? (
                            <div className="example-alert mb-3">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessageSso}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessageSso !== "" ? (
                            <div className="example-alert mb-3">
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessageSso}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="row g-4">
                            <div className="col-lg-12">
                              <div
                                className="card"
                                style={{ alignItems: "center" }}
                              >
                                <div
                                  style={{ width: "150px", height: "120px" }}
                                >
                                  <img
                                    src="../../../assets/integrations/voice-adm.png"
                                    className="card-img-top img_settings"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <TextField
                                  label="Email"
                                  error={this.state.errorLoginEmail}
                                  id="login_email"
                                  name="login_email"
                                  value={this.state.login_email}
                                  placeholder="Email"
                                  change={this.handleChange}
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <TextField
                                  label="Password"
                                  error={this.state.errorLoginPassword}
                                  id="login_password"
                                  name="login_password"
                                  value={this.state.login_password}
                                  placeholder="Password"
                                  change={this.handleChange}
                                  type="Password"
                                />
                              </div>
                              <a
                                class="link link-primary link-sm"
                                tabindex="-1"
                                href={this.state.forgotLink}
                                style={{
                                  float: "right",
                                }}
                              >
                                Forgot Password?
                              </a>
                            </div>
                            <div className="col-lg-12">
                              <button
                                type="button"
                                className="btn btn-lg btn-block"
                                onClick={() => {
                                  this.formLogin();
                                }}
                                style={{
                                  backgroundColor: THEME_COLOR,
                                  color: THEME_TEXT_COLOR,
                                }}
                                disabled={this.state.disabled}
                              >
                                Login
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* SSSO MODAL End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
    quotationSettings: state.quotationSettings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Services);
