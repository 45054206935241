import { React, createRef } from "react";
import { Component } from "react";
import axios from "axios";
import { axiosConfig, ValidateEmail, normalize } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { addNewSubClient } from "./../../../config/client_api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import Radio from "@material-ui/core/Radio";
import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Select from "react-select";
import countryList from "react-select-country-list";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import PasswordStrengthBar from 'react-password-strength-bar';

const auth = new HelperClass();

class Addsubclient extends Component {
  constructor() {
    super();
    this.state = {
      serviceData: [],
      select_service: "",
      product_desc: "",
      errorMessage: "",
      serviceId: "",
      client_name: "",
      client_title: "",
      client_email: "",
      client_phone: "",
      client_city: "",
      client_country: "",
      client_state: "",
      client_zip: "",
      client_address: "",
      client_address2: "",
      client_comp: "",
      successMessage: "",
      tableLoader: true,
      client_password: "",
      client_c_password: "",
      disabled: false,
      passwordShown: false,
      confirmPasswordShown: false,
      notifyCheckBox: false,
      account_status: "",
      errorName: "",
      errorClientStatus: "",
      errorClientCompany: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientAddress: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientCountry: "",
      errorClientPassword: "",
      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",
    };
    this.phoneInputRef = createRef();
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
  }

  async componentDidMount() {
    //console.log("auth.getAccount(): ", auth.getClientAccount());
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(
      "/" + auth.getResellerIdentifier() + "/" + link
    );
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  handleChange = async (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    if (name === "client_name") {
      value = value.replace(/[^a-zA-Z\s'.,-]/g, "");
    } else if (name === "client_title") {
      value = value.replace(/[^a-zA-Z0-9\s\-_.,&()]/g, "");
    } else if (name === "client_comp") {
      value = value.replace(/[^a-zA-Z0-9\s\-_,&().]/g, "");
    }
    this.setState({
      [name]: value,
    });
    //console.log("handleChange:", name + " " + value);
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }
        this.setState({
          client_city: city && city !== "" ? city : "",
          client_state: state && state !== "" ? state : "",
          client_zip: zipCode && zipCode !== "" ? zipCode : "",
          client_country: country && country !== "" ? country : "",
          client_address:
            streetAddress && streetAddress !== "" ? streetAddress : "",
          client_address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  formSave = async () => {
    let {
      client_title,
      client_name,
      client_email,
      client_password,
      client_c_password,
      client_phone,
      client_address,
      client_address2,
      client_zip,
      client_country,
      client_city,
      client_state,
      client_comp,
      account_status,
    } = this.state;
    this.setState({
      successMessage: "",
      errorMessage: "",
      errorName: "",
      errorClientStatus: "",
      errorClientCompany: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientAddress: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientCountry: "",
      errorClientPassword: "",
      errorCPassword: "",
    });
    let is_validated = true;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    if (client_name === "") {
      this.setState({
        errorName: "error",
        errorMessage: "Sub user name is required.",
      });
      is_validated = false;
    } else if (client_email === "") {
      this.setState({
        errorName: "",
        errorClientEmail: "error",
        errorMessage: "Email is required.",
      });
      is_validated = false;
    } else if (ValidateEmail(client_email) === false) {
      this.setState({
        errorName: "",
        errorClientEmail: "error",
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (client_comp === "") {
      this.setState({
        errorClientEmail: "",
        errorClientCompany: "error",
        errorMessage: "Company is required.",
      });
      is_validated = false;
    } else if (client_phone === "") {
      this.setState({
        errorClientCompany: "",
        errorClientPhone: "error",
        errorMessage: "Phone is required.",
      });
      is_validated = false;
    } else if (!this.isPhoneValid(client_phone)) {
      this.setState({
        errorClientCompany: "",
        errorClientPhone: "error",
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (client_address === "") {
      this.setState({
        errorClientPhone: "",
        errorClientAddress: "error",
        errorMessage: "Address is required.",
      });
      is_validated = false;
    } else if (this.state.client_city === "") {
      this.setState({
        errorClientAddress: "",
        errorClientCity: "error",
        errorMessage: "City is required.",
      });
      is_validated = false;
    } else if (this.state.client_state === "") {
      this.setState({
        errorClientCity: "",
        errorClientState: "error",
        errorMessage: "State is required.",
      });
      is_validated = false;
    } else if (this.state.client_zip === "") {
      this.setState({
        errorClientState: "",
        errorClientZip: "error",
        errorMessage: "Zip is required.",
      });
      is_validated = false;
    } else if (this.state.client_country === "") {
      this.setState({
        errorClientZip: "",
        errorClientCountry: "error",
        errorMessage: "Country is required.",
      });
      is_validated = false;
    } else if (client_password === "") {
      this.setState({
        errorClientCountry: "",
        errorClientPassword: "error",
        errorMessage: "Password is required.",
      });
      is_validated = false;
    } else if (!passwordRegex.test(client_password)) {
      this.setState({
        errorClientCountry: "",
        errorClientPassword: "error",
        errorMessage: "Password must be at least 8 characters, with uppercase, lowercase, number & special character.",
      });
      is_validated = false;
    } else if (client_password !== "") {
      if (client_password.length < 8) {
        this.setState({
          errorClientCountry: "",
          errorClientPassword: "error",
          errorMessage: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (client_c_password === "") {
        this.setState({
          errorClientPassword: "",
          errorCPassword: "error",
          errorMessage: "Confirm password is required.",
        });
        is_validated = false;
      } else if (client_c_password.length < 8) {
        this.setState({
          errorClientPassword: "",
          errorCPassword: "error",
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (client_password.trim() != client_c_password.trim()) {
        this.setState({
          errorClientPassword: "",
          errorCPassword: "error",
          errorMessage: "Password and confirm password does not match.",
        });
        is_validated = false;
      } else if (this.state.account_status === "") {
        this.setState({
          errorCPassword: "",
          errorClientStatus: "error",
          errorMessage: "Account Status is required.",
        });
        is_validated = false;
      }
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    if (is_validated) {
      let apiResponce = await addNewSubClient(
        auth.getClientAccount(),
        auth.getClientToken(),
        client_title,
        client_name,
        client_email,
        client_password,
        client_phone,
        client_address,
        client_address2,
        client_zip,
        client_country,
        client_city,
        client_state,
        client_comp,
        account_status,
        this.state.notifyCheckBox.toString()
      );
      //console.log("Add Sub User Result:", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 409 &&
        apiResponce.data.message === "Address validation failed"
      ) {
        const formattedErrorMessage = apiResponce.data.errors
          ? String(apiResponce.data.errors).replace(/,/g, "<br />")
          : "Address validation failed";
        this.setState({
          errorMessage: formattedErrorMessage,
          disabled: false,
        });
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        this.setState({
          successMessage: "Sub user added successfully.",
          tableLoader: false,
        });
        setTimeout(async function () {
          window.location.replace("/client/SubClients");
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "already_exists"
      ) {
        this.setState({
          errorMessage:
            "Email already exists. Please try different email address.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessage: "Something went wrong,try again later.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessage: "Something went wrong,try again later.",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const client_country = event.label;
    this.setState({
      client_country: client_country,
    });
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(4);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown,
    });
  };

  render() {
    const { useGooglePlaces, toggleButtonText } = this.state;
    const errorStyle = {
      control: (provided) => ({
        ...provided,
        borderColor: this.state.errorClientAddress ? "red" : "#d3d3d3",
        height: "57px",
      }),
    };
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("client")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("client/SubClients")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Sub Users
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add New Sub User
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div class="card-head">
                    <h5 class="card-title">Enter sub user details below.</h5>
                  </div>
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.errorMessage,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  <form method="post" encType="multipart/form-data">
                    <div className="row g-4">
                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="client_name"
                          name="client_name"
                          type="text"
                          label="Sub user name"
                          inputProps={{ maxLength: 100 }}
                          value={this.state.client_name}
                          onChange={this.handleChange}
                          helperText="Enter the sub user name. Allowed characters: (&nbsp; ' &nbsp; - &nbsp; . &nbsp; , )"
                          variant="outlined"
                          fullWidth
                          error={this.state.errorName === "error"}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="client_email"
                          name="client_email"
                          type="email"
                          label="Email Address"
                          defaultValue={this.state.client_email}
                          onChange={this.handleChange}
                          helperText="Enter your email address here."
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientEmail === "error"}
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <MutextField

                          id="client_title"
                          name="client_title"
                          type="text"
                          inputProps={{ maxLength: 30 }}
                          label="Sub user title"
                          value={this.state.client_title}
                          onChange={this.handleChange}
                          helperText="Enter the title here. Allowed characters: (&nbsp; - &nbsp; _ &nbsp; . &nbsp; &nbsp; , &nbsp; &amp; &nbsp; ( ) )"
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <MutextField
                          required
                          id="client_comp"
                          name="client_comp"
                          type="text"
                          inputProps={{ maxLength: 30 }}
                          label="Sub user company"
                          value={this.state.client_comp}
                          onChange={this.handleChange}
                          helperText="Enter the company here. Allowed characters: (&nbsp; _ &nbsp; . &nbsp; - &nbsp; , &nbsp; &amp; &nbsp; ( ) )"
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientCompany === "error"}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        {/*<PhoneInput
                          style={{ height: "71%" }}
                          required
                          defaultCountry={"us"}
                          error={this.state.errorPhone}
                          inputProps={{
                            name: "client_phone",
                            id: "client_phone",
                            maxLength: 20,
                          }}
                          value={this.state.client_phone}
                          onChange={(value) =>
                            this.handleChange({
                              target: { name: "client_phone", value },
                            })
                          }
                          helperText="Enter the client_phone number."
                          label="client_phone Number"
                          ref={this.phoneInputRef}
                          onFocus={this.handleFocus}
                        />*/}
                        <PhoneInput
                          style={{ height: "71%" }}
                          required
                          defaultCountry={"us"}
                          error={this.state.errorPhone}
                          inputProps={{
                            name: "client_phone",
                            id: "client_phone",
                            maxLength: 20,
                            className: this.state.errorClientPhone
                              ? "error-border"
                              : "default-border",
                          }}
                          dialCodePreviewStyleProps={{
                            className: this.state.errorClientPhone
                              ? "error-border"
                              : "default-border",
                          }}
                          value={this.state.client_phone}
                          onChange={(value) =>
                            this.handleChange({
                              target: { name: "client_phone", value },
                            })
                          }
                          helperText="Enter the sub user phone number."
                          label="Sub user phone number"
                          ref={this.phoneInputRef}
                          onFocus={this.handleFocus}
                        />
                      </div>

                      <div className="col-md-6 mt-3 mt-md-0 googleLocation-autocomplete-wrapper">
                        <div
                          class="nk-block-between-md g-4"
                          style={{
                            position: "absolute",
                            zIndex: 9999,
                            right: "13px",
                            top: "-15px",
                          }}
                        >
                          <div class="nk-block-head-content"></div>
                          <div class="nk-block-head-content">
                            <ul class="nk-block-tools gx-3">
                              <li>
                                <a
                                  onClick={this.handleToggle}
                                  class="text-primary fs-11"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "11px",
                                  }}
                                >
                                  {toggleButtonText}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div>
                          {useGooglePlaces ? (
                            <>
                              <GooglePlacesAutocomplete
                                apiKey={
                                  process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                }
                                selectProps={{
                                  value: this.state.client_address
                                    ? {
                                      label: this.state.client_address,
                                      value: this.state.client_address,
                                    }
                                    : null,
                                  onChange: this.handleAddressSelect,
                                  placeholder: this.state.client_address
                                    ? null
                                    : "Enter your address..",
                                  styles: {
                                    ...errorStyle,
                                    input: (provided) => ({
                                      ...provided,
                                      color: "black",
                                    }),
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: "9",
                                    }),
                                  },
                                }}
                                id="googleLocation-autocomplete"
                              />
                            </>
                          ) : (
                            <MutextField
                              required
                              id="client_address"
                              name="client_address"
                              type="text"
                              label="PO Box Address"
                              value={this.state.client_address}
                              onChange={this.handleChange}
                              helperText="Enter valid PO Box Address. i.e, PO Box Number, City, State Zip Code"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                                required: false,
                              }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="client_address2"
                          name="client_address2"
                          type="text"
                          label="Address 2"
                          value={this.state.client_address2}
                          onChange={this.handleChange}
                          helperText="If needed, enter additional address details, such as apartment number, suite, or unit."
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                            required: false,
                          }}
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <MutextField
                          required
                          id="client_city"
                          name="client_city"
                          type="text"
                          label="City"
                          value={this.state.client_city}
                          onChange={this.handleChange}
                          helperText="Enter your city here."
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientCity === "error"}
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <MutextField
                          required
                          id="client_state"
                          name="client_state"
                          type="text"
                          label="State"
                          value={this.state.client_state}
                          onChange={this.handleChange}
                          helperText="Enter your state here."
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientState === "error"}
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <MutextField
                          required
                          id="client_zip"
                          name="client_zip"
                          type="text"
                          label="Zip"
                          value={this.state.client_zip}
                          onChange={this.handleChange}
                          helperText="Enter your zip here."
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientZip === "error"}
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <Select
                          className="customStyle-country"
                          options={this.countryOptions}
                          value={{
                            label: this.state.client_country,
                            value: this.state.client_country,
                          }}
                          onChange={this.handleChangeCountry}
                          placeholder="Select a country"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              minHeight: "56px",
                            }),
                          }}
                        />
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-required">
                          Select country
                        </p>
                      </div>

                      <div
                        className="form-group col-md-6"
                        style={{ position: "relative" }}
                      >
                        <MutextField
                          required
                          id="client_password"
                          name="client_password"
                          type={this.state.passwordShown ? "text" : "password"}
                          label="Password"
                          defaultValue={this.state.client_password}
                          inputProps={{ maxLength: 30 }}
                          onChange={this.handleChange}
                          helperText=""
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientPassword === "error"}
                        />
                        <a
                          style={{
                            left: "auto",
                            right: "10px",
                            top: "44px",
                            position: "absolute",
                            cursor: "pointer",
                          }}
                          tabIndex="-1"
                          onClick={this.togglePassword}
                          className="form-icon form-icon-right passcode-switch"
                          data-target="password"
                        >
                          {this.state.passwordShown ? (
                            <em className="icon ni ni-eye-off"></em>
                          ) : (
                            <em className="icon ni ni-eye"></em>
                          )}
                        </a>
                        <small
                          className="form-text"
                          style={{
                            position: "absolute",
                            top: "69px",
                            left: "26px",
                            color: "#0000008a",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                          }}
                        >
                          Password must be at least 8 characters, with uppercase, lowercase, number & special character.
                        </small>
                        <div style={{ marginTop: "30px" }}>
                          <PasswordStrengthBar password={this.state.client_password} />
                        </div>
                      </div>

                      <div
                        className="form-group col-md-6"
                        style={{ position: "relative" }}
                      >
                        <MutextField
                          required
                          id="client_c_password"
                          name="client_c_password"
                          type={
                            this.state.confirmPasswordShown
                              ? "text"
                              : "password"
                          }
                          label="Confirm Password"
                          defaultValue={this.state.client_c_password}
                          inputProps={{ maxLength: 30 }}
                          onChange={this.handleChange}
                          helperText=""
                          variant="outlined"
                          fullWidth
                          error={this.state.errorCPassword === "error"}
                        />
                        <a
                          style={{
                            left: "auto",
                            right: "10px",
                            top: "44px",
                            position: "absolute",
                            cursor: "pointer",
                          }}
                          tabIndex="-1"
                          onClick={() => {
                            this.toggleConfirmPassword();
                          }}
                          className="form-icon form-icon-right passcode-switch"
                          data-target="password"
                        >
                          {this.state.confirmPasswordShown ? (
                            <em className="icon ni ni-eye-off"></em>
                          ) : (
                            <em className="icon ni ni-eye"></em>
                          )}
                        </a>
                        <small
                          className="form-text"
                          style={{
                            position: "absolute",
                            top: "69px",
                            left: "26px",
                            color: "#0000008a",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                          }}
                        >
                          Confirm Password must be at least 8 characters, with uppercase, lowercase, number & special character. Confirm Password must match the Password.
                        </small>
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          id="account_status"
                          name="account_status"
                          select
                          label="Account status"
                          value={this.state.account_status}
                          onChange={this.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          helperText="Select the account status here."
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientStatus === "error"}
                        >
                          <option key="" value=""></option>
                          <option key="5" value="active">
                            Active
                          </option>
                          <option key="10" value="inactive">
                            Inactive
                          </option>
                        </MutextField>
                      </div>

                      <div
                        className="custom-control custom-control-lg custom-checkbox col-lg-3"
                        style={{ padding: 0, marginLeft: "3rem" }}
                      >
                        <div className="form-group">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck2"
                            checked={this.state.notifyCheckBox}
                            onChange={() => {
                              const isChecked = !this.state.notifyCheckBox;
                              this.setState({ notifyCheckBox: isChecked });
                              console.log(
                                "Notify Checkbox Checked:",
                                isChecked
                              );
                            }}
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck2"
                          >
                            Notify Client
                          </label>
                        </div>
                      </div>

                      <div style={{ clear: "both" }}></div>
                      <hr />
                      <div className="form-group text-right col-md-12">
                        <button
                          className="btn btn-lg btn-primary"
                          type="button"
                          disabled={this.state.disabled}
                          onClick={this.formSave}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  {/*<StepWizard>
                    <Step1
                      handleChange={this.handleChangeStep}
                      values={values}
                    />
                    <Step2
                      handleChange={this.handleChangeStep}
                      values={values}
                    />
                  </StepWizard>*/}

                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addsubclient);
