import { React, Fragment } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { viewClientFormSingleSubmission } from "./../../../config/agent_forms_api_calls";
import { AGENT_PORTAL_URL, APP_LIVE_URL } from "../../../config/config";
import $ from "jquery";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import FormRenderer from "../../../components/Reseller/formRenderAgent";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const auth = new HelperClass();

class viewSubmission extends Component {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      successMessage: "",
      successMessaged: "",
      errorMessaged: "",
      mainError: "",
      tableLoader: true,
      disabled: false,
      formData: [],
      submissionData: [],
      groups: [],
      user_response: [],
      form_accountno: "",
      form_token: "",
      order_no: "",
    };
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  async componentDidMount() {
    let form_token = this.props.props.match.params.form_token;
    console.log("form_token: ", form_token);
    this.setState({ form_token: form_token });
    const servicesResponce = await viewClientFormSingleSubmission(
      auth.getClientAccount(),
      auth.getClientToken(),
      form_token
    );
    console.log("viewGeneralFormSubmission: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        formData: [],
        submissionData: [],
        mainError: "There is some error while getting the services Types",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let groups_data = JSON.parse(servicesResponce.data.forms[0]["groups"]);
      groups_data.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

      let user_response = JSON.parse(
        servicesResponce.data.data[0]["response"]
      );
      console.log("user_response: ", user_response[0].questions);
      this.setState({
        formData: servicesResponce.data.forms,
        form_accountno: servicesResponce.data.data[0]["form_accountno"],
        order_no: servicesResponce.data.data[0]["order_no"],
        groups: groups_data,
        submissionData: servicesResponce.data.data,
        user_response: user_response[0].questions,
        tableLoader: false,
      });
    } else {
      this.setState({
        formData: [],
        submissionData: [],
        mainError: "There is some error while getting the services",
      });
    }
  }

  downloadPdfGeneral = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url = APP_LIVE_URL + "files_data/form_response_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "Attachement not found.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
            this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
            this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between g-3">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      <a onClick={() => this.addMenuHistory("/" + auth.getResellerRegToken() + "/client/forms")} style={{ cursor: "pointer" }}>Forms</a> /{" "}<a onClick={() =>
                        this.addMenuHistory(
                          "/" + auth.getResellerRegToken() + "/client/client_form_submissions/" + this.state.form_accountno + "/" + this.state.form_token
                        )
                      } style={{ cursor: "pointer" }}>Form Submissions</a> /{" "}
                      <strong class="text-primary small">
                        View Submission
                      </strong>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <ul class="list-inline">
                        <li>
                          Form ID:{" "}
                          <span class="text-base">
                            {this.state.form_accountno}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <a
                      onClick={() =>
                        this.addMenuHistory(
                          "/" + auth.getResellerRegToken() + "/client/client_form_submissions/" + this.state.form_accountno + "/" + this.state.form_token
                        )
                      }

                      class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                      <span>Back</span>
                    </a>
                    <a
                      onClick={() =>
                        this.addMenuHistory(
                          "/" + auth.getResellerRegToken() + "/client/client_form_submissions/" + this.state.form_accountno + "/" + this.state.form_token
                        )
                      }

                      class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                    </a>
                  </div>
                </div>
              </div>

              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.mainError !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.mainError}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {/* START DATATABLE */}
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <>
                              {this.state.submissionData &&
                                this.state.submissionData.length > 0 ? (
                                <table class="table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        {this.state.formData &&
                                          this.state.formData.length > 0 ? (
                                          <h3>
                                            {this.state.formData[0].form_title}
                                          </h3>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {this.state.submissionData[0]
                                          .pdf_name &&
                                          this.state.submissionData[0]
                                            .pdf_name !== "" ? (
                                          <>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                this.downloadPdfGeneral(
                                                  this.state.submissionData[0]
                                                    .pdf_name
                                                )
                                              }
                                              class="btn btn-outline-primary"
                                            >
                                              <em class="icon ni ni-download"></em>{" "}
                                              <span>View PDF</span>
                                            </button>
                                            {this.state.order_no && this.state.order_no !== "" && (
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.addMenuHistory(
                                                    "/" +
                                                    auth.getResellerRegToken() +
                                                    "/client/ViewSalesOrder/" +
                                                    this.state.order_no
                                                  )
                                                }
                                                className="btn btn-outline-primary ml-2"
                                              >
                                                <em className="icon ni ni-arrow-up"></em>
                                                <span>View Sales Order</span>
                                              </button>
                                            )}
                                          </>
                                        ) : null}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan={2}
                                        className="text-dark pt-3 pb-3"
                                      >
                                        {this.state.formData &&
                                          this.state.formData.length > 0 ? (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                this.state.formData[0]
                                                  .form_desc,
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                    {this.state.groups.map((grp, index) => (
                                      <tr key={`grp_${index}`}>
                                        <td colSpan={2} class="pt-4 pb-4">
                                          <h6>{grp.group_title}</h6>
                                          {grp.group_desc &&
                                            grp.group_desc !== "" ? (
                                            <p className="text-dark">
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: grp.group_desc,
                                                }}
                                              />
                                            </p>
                                          ) : null}

                                          {this.state.user_response &&
                                            this.state.user_response.length >
                                            0 ? (
                                            <table class="table table-bordered text-dark">
                                              <tbody>
                                                {Array.isArray(grp.questions) &&
                                                  grp.questions
                                                    .map((element) => {
                                                      // Find the corresponding user response
                                                      return this.state.user_response.find(
                                                        (question) =>
                                                          question.id ===
                                                          element
                                                      );
                                                    })
                                                    .filter(
                                                      (matchedQuestion) =>
                                                        matchedQuestion !==
                                                        undefined
                                                    ) // Filter out undefined values
                                                    .sort(
                                                      (a, b) =>
                                                        a.sequenceNumber -
                                                        b.sequenceNumber
                                                    ) // Sort based on sequenceNumber
                                                    .map(
                                                      (
                                                        matchedQuestion,
                                                        index
                                                      ) => (
                                                        <>
                                                          {matchedQuestion.answerType &&
                                                            matchedQuestion.answerType ===
                                                            "label" ? (
                                                            <tr
                                                              key={`inner_${index}`}
                                                            >
                                                              <th colSpan={2}>
                                                                {matchedQuestion.questionText
                                                                  ? matchedQuestion.questionText
                                                                  : "N/A"}
                                                              </th>
                                                            </tr>
                                                          ) : (
                                                            <>
                                                              <tr
                                                                key={`inner_${index}`}
                                                              >
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "50%",
                                                                  }}
                                                                >
                                                                  {matchedQuestion.questionText
                                                                    ? matchedQuestion.questionText
                                                                    : "N/A"}
                                                                </td>
                                                                <td>
                                                                  {matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "input" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        ""
                                                                        ? matchedQuestion.answer
                                                                        : ""}
                                                                    </>
                                                                  ) : null}
                                                                  {matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "fixed" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        ""
                                                                        ? matchedQuestion.answer
                                                                        : ""}
                                                                    </>
                                                                  ) : null}
                                                                  {matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "label" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        ""
                                                                        ? matchedQuestion.questionText
                                                                        : ""}
                                                                    </>
                                                                  ) : null}
                                                                  {matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "textarea" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        ""
                                                                        ? matchedQuestion.answer
                                                                        : ""}
                                                                    </>
                                                                  ) : null}
                                                                  {(matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "dropdown") ||
                                                                    matchedQuestion.answerType ===
                                                                    "radio" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        ""
                                                                        ? matchedQuestion.answer
                                                                        : ""}
                                                                    </>
                                                                  ) : null}
                                                                  {matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "date" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        ""
                                                                        ? matchedQuestion.answer
                                                                        : ""}
                                                                    </>
                                                                  ) : null}
                                                                  {matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "datetime" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        ""
                                                                        ? matchedQuestion.answer
                                                                        : ""}
                                                                    </>
                                                                  ) : null}
                                                                  {matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "image_input" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        ""
                                                                        ? (() => {
                                                                          const fileExtension =
                                                                            matchedQuestion.answer
                                                                              .split(
                                                                                "."
                                                                              )
                                                                              .pop()
                                                                              .toLowerCase();

                                                                          const imageExtensions =
                                                                            [
                                                                              "png",
                                                                              "jpg",
                                                                              "jpeg",
                                                                              "gif",
                                                                            ];
                                                                          const isImage =
                                                                            imageExtensions.includes(
                                                                              fileExtension
                                                                            );

                                                                          if (
                                                                            isImage
                                                                          ) {
                                                                            return (
                                                                              <img
                                                                                src={
                                                                                  APP_LIVE_URL +
                                                                                  "files_data/form_response_uploads/" +
                                                                                  matchedQuestion.answer
                                                                                }
                                                                                alt="Uploaded Image"
                                                                                style={{
                                                                                  width:
                                                                                    "100px",
                                                                                }}
                                                                              />
                                                                            );
                                                                          } else {
                                                                            return (
                                                                              <a
                                                                                onClick={() =>
                                                                                  this.downloadPdfGeneral(
                                                                                    matchedQuestion.answer
                                                                                  )
                                                                                }
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              >
                                                                                <span class="badge badge-outline-primary">
                                                                                  <em class="icon ni ni-download"></em>{" "}
                                                                                  Download
                                                                                </span>
                                                                              </a>
                                                                            );
                                                                          }
                                                                        })()
                                                                        : "No file provided"}
                                                                    </>
                                                                  ) : null}
                                                                  {matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "signature" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        "" ? (
                                                                        <img
                                                                          src={
                                                                            APP_LIVE_URL +
                                                                            "files_data/form_response_uploads/" +
                                                                            matchedQuestion.answer
                                                                          }
                                                                          width={
                                                                            200
                                                                          }
                                                                        ></img>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                    </>
                                                                  ) : null}
                                                                  {matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "time" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        ""
                                                                        ? matchedQuestion.answer
                                                                        : ""}
                                                                    </>
                                                                  ) : null}
                                                                  {matchedQuestion.answerType &&
                                                                    matchedQuestion.answerType ===
                                                                    "checkbox" ? (
                                                                    <>
                                                                      {matchedQuestion.answer &&
                                                                        matchedQuestion.answer !==
                                                                        "" ? (
                                                                        <>
                                                                          <ul class="list-plain">
                                                                            {matchedQuestion.answer.map(
                                                                              (
                                                                                matchedQuestions,
                                                                                inde
                                                                              ) => (
                                                                                <li
                                                                                  key={`chk${inde}`}
                                                                                >
                                                                                  <em class="icon ni ni-check-round"></em>
                                                                                  <span>
                                                                                    {
                                                                                      matchedQuestions
                                                                                    }
                                                                                  </span>
                                                                                </li>
                                                                              )
                                                                            )}
                                                                          </ul>
                                                                        </>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                    </>
                                                                  ) : null}
                                                                </td>
                                                              </tr>
                                                            </>
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                              </tbody>
                                            </table>
                                          ) : null}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              ) : null}
                            </>
                          )}

                          {/* END TABLE */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(viewSubmission);
