import { React } from "react";
import { Component } from "react";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import "../../../components/Client/signatureCanvas.css";
import SignatureCanvas from "react-signature-canvas";

import { addSettings } from "../../../actions";
import {
  ValidateEmail,
  axiosConfig,
  normalizePhone,
  ValidatePasswordPattern,
  formatAsCurrency,
} from "../../../config/utility";
import {
  getSingleSalesOrder,
  listSingleClient,
  getPaymentMethodForOrder,
  getPaymentSettings,
  getPaymentLogs,
  listServicesAllowed,
  getOrderComments,
  getSingleRfq,
  sendOrderEmail,
  rejectSalesOrder,
  saveOrderAsDraft,
  saveOrderAndSent,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import Questions from "../../../components/Client/RequestForQuotation/questions";
import ExtraDetails from "../../../components/Client/RequestForQuotation/extra";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { HeadBodyGrid } from "../../../components/ContentLoaders/newLoaders/HeadBodyGrid";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
  API_URL,
  APP_URL,
  ADMIN_URL
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
//import { Editor } from "@tinymce/tinymce-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CopyToClipboard } from "react-copy-to-clipboard";
import htmlToFormattedText from "html-to-formatted-text";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import html2canvas from "html2canvas";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  getServiceDetails,
} from "./../../../config/client_api_calls";

const animatedComponents = makeAnimated();

const auth = new HelperClass();

class ViewOrder extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      tableDataBundle: [],
      completeData: [],
      defaultServices: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      successMessagePay: "",
      errorMessagePay: "",
      errorMessagePayTitle: "",
      tableLoader: true,
      tableLoaderListing: true,
      mcqLoader: true,
      tableLoaderBundle: true,
      errorMessageEmail: "",
      VerifyBusinessButton: false,
      VerifyFinancialButton: false,
      VerifyTradeButton: false,
      verifyDetailsButton: false,
      resendPinButton: false,
      finalPayButton: false,
      successMessageEmail: "",
      errorMessagePassword: "",
      errorMessageConsent: "",
      errorMessageFinal: "",
      convErrorMessage: "",
      service_success_message: "",
      service_already_eists_message: "",
      payment_method: "bank",
      bank_account_details: "",
      wire_transfer_details: "",
      bank_account_details_plain: "",
      wire_transfer_details_plain: "",
      final_form_step: "signature",
      step: 1,
      email: "",
      copied: false,
      cpied_value: "",
      show_signature: false,
      disabled: false,
      //Client Details
      client_name: "",
      client_company: "",
      client_email: "",
      client_phone: "",
      client_address: "",
      client_address2: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      client_country: "",
      //Reseller Details
      reseller_name: "",
      reseller_company: "",
      reseller_email: "",
      reseller_phone: "",
      reseller_address: "",
      reseller_city: "",
      reseller_state: "",
      reseller_zip: "",
      //Discount Entries
      discount_type: "amount",
      discount_value: 0,
      discount_value_total: 0,
      discount_reason: "",
      //Shipping Entries
      shipping_title: "",
      shipping_value: 0,
      shipping_value_total: 0,
      quotation_signature: "",
      //Tax Details
      tax_type: "amount",
      tax_value: 0,
      tax_value_total: 0,
      tax_reason: "",
      //Quotation Settings
      quotation_notes: "",
      payment_terms: "",
      currency: "USD",
      contact_person: "",
      contact_email: "",
      validity: "",
      errorMessageInfo: "",
      successMessageInfo: "",
      info_button: false,
      conversations: [],
      quote_accountno: "",
      orderno: "",
      quote_comment_button: false,
      quote_comment: "",

      payErrorMessage: "",
      paySuccessMessage: "",
      paymentLogLoader: true,
      payLogErrorMessage: "",
      paymentMethodLoader: true,

      emailto: "",
      emailcc: "",
      emailbcc: "",
      emailsubject: "",
      emailcontent: "",
      email_button: false,
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",

      rejection_reason: "",
      rejectErrorMessage: "",
      rejectErrorMessageTitle: "",
      rejectSuccessMessage: "",
      rejectionButton: false,

      service_grand_total: 0,
      service_grand_sub_total: 0,
      rows: [],
      all_Services: [],
      all_select_services: [],
      all_select_services_optional: [],

      signatureText: "",
      signatureClass: "'Amita', cursive",
      signature_type: "draw",

      quote_status: "",

      isEditorReady: false,
      service_unsigned_agreements: [],
      final_unsigned_list: [],

      savedRows: new Set(),
      editingRows: new Set(),
      editingSection: null,
      editableRows: new Set(),

      saveButtonEnabled: false,
      saveButtonEnabledOptional: false,

      rows_service_temp: [
        {
          temp_serv_id: "row-135",
          temp_serv_data: {
            temp_serv_inner_id: 940,
            temp_serv_inner_name: "Item 1",
            temp_serv_inner_quantity: 2,
            temp_serv_inner_unit: "sbc",
            temp_serv_inner_price: 10,
            temp_serv_inner_total: 10,
            temp_serv_inner_type: "service",
          },
        },
      ],
      rows_optional: [],
      rows_optional_temp: [
        {
          temp_opt_id: "row-145",
          temp_opt_data: {
            temp_opt_inner_id: 940,
            temp_opt_inner_name: "Item 1",
            temp_opt_inner_quantity: 2,
            temp_opt_inner_unit: "sbc",
            temp_opt_inner_price: 10,
            temp_opt_inner_total: 10,
            temp_opt_inner_type: "service",
          },
        },
      ],
      rows_custom_temp: [
        {
          temp_id: "row-125",
          temp_data: {
            temp_inner_id: 94,
            temp_inner_name: "Item 1",
            temp_inner_quantity: 2,
            temp_inner_unit: "sbc",
            temp_inner_price: 10,
            temp_inner_total: 10,
            temp_inner_type: "custom",
          },
        },
      ],
    };
    this.cursiveFonts = [
      { name: "Your Signature", value: "'Amita', cursive" },
      { name: "Your Signature", value: "'Bad Script', cursive" },
      { name: "Your Signature", value: "'Damion', cursive" },
      { name: "Your Signature", value: "'Dancing Script', cursive" },
      { name: "Your Signature", value: "'Felipa', cursive" },
      { name: "Your Signature", value: "'Julee', cursive" },
      { name: "Your Signature", value: "'Kaushan Script', cursive" },
      { name: "Your Signature", value: "'Lugrasimo', cursive" },
      { name: "Your Signature", value: "'Pacifico', cursive" },
      { name: "Your Signature", value: "'Sofia', cursive" },
      { name: "Your Signature", value: "'Yellowtail', cursive" },
      // Add more cursive fonts here
    ];
  }

  async componentDidMount() {
    let orderno = this.props.props.match.params.orderno;
    let service_id = this.props.props.match.params.service_id;
    this.setState({
      orderno: orderno,
      quote_title: "Order - " + orderno,
      service_id: service_id,
    });
    console.log("quote_accountno: ", orderno);
    if (
      this.props.props.match.params.orderno &&
      this.props.props.match.params.orderno !== ""
    ) {
      this.getSalesOrder(orderno);
      this.getServices();
    }
    this.getSingleClient();
    setTimeout(() => {
      this.getPaymentSettings();
      this.getPaymentMethod();
    }, 1000);
    if (service_id) {
      this.addRowService(service_id);
    }
  }

  getDecimalPoint = () => {
    if (
      this.props.quotationSettings[0]?.decimal_point &&
      this.props.quotationSettings[0]?.decimal_point !== ""
    ) {
      const decimalPoint = this.props.quotationSettings[0]?.decimal_point;
      return decimalPoint !== "" ? Number(decimalPoint) : 2;
    }
    return 2;
  };

  getBillingDecimalPoint = () => {
    if (
      this.props.quotationSettings[0]?.billing_decimal_point &&
      this.props.quotationSettings[0]?.billing_decimal_point !== ""
    ) {
      const decimalPoint = this.props.quotationSettings[0]?.billing_decimal_point;
      return decimalPoint !== "" ? Number(decimalPoint) : 2;
    }
    return 2;
  };

  getSalesOrder = async (orderno) => {
    const servicesResponce = await getSingleSalesOrder(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno
    );
    console.log("getSalesOrder: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({ isEditorReady: true });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let discount_value_total = 0;
      let discount_type = "amount";
      let discount_value = 0;
      let discount_reason = "";
      if (
        servicesResponce.data.data[0]["discounts"] &&
        servicesResponce.data.data[0]["discounts"].length > 0
      ) {
        discount_value_total =
          servicesResponce.data.data[0]["discounts"][0]["discount_total"];
        discount_type =
          servicesResponce.data.data[0]["discounts"][0]["discount_type"];
        discount_value =
          servicesResponce.data.data[0]["discounts"][0]["discount_value"];
        discount_reason =
          servicesResponce.data.data[0]["discounts"][0]["discount_reason"];
      }
      let tax_value_total = 0;
      let tax_type = "amount";
      let tax_value = 0;
      let tax_reason = "";
      if (
        servicesResponce.data.data[0]["taxes"] &&
        servicesResponce.data.data[0]["taxes"].length > 0
      ) {
        tax_value_total =
          servicesResponce.data.data[0]["taxes"][0]["tax_total"];
        tax_type = servicesResponce.data.data[0]["taxes"][0]["tax_type"];
        tax_value = servicesResponce.data.data[0]["taxes"][0]["tax_value"];
        tax_reason = servicesResponce.data.data[0]["taxes"][0]["tax_reason"];
      }
      let shipping_value_total = 0;
      let shipping_title = "";
      let shipping_value = "";
      if (
        servicesResponce.data.data[0]["shipping"] &&
        servicesResponce.data.data[0]["shipping"].length > 0
      ) {
        shipping_value_total =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
        shipping_title =
          servicesResponce.data.data[0]["shipping"][0]["shipping_title"];
        shipping_value =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
      }
      //console.log("rfq_questions: "+servicesResponce.data.data);
      let servicesRows = [];
      let savedRowIds = new Set();
      let total = 0;
      if (
        servicesResponce.data.data[0]["quote_services"] &&
        servicesResponce.data.data[0]["quote_services"].length > 0
      ) {
        let rfq_services = servicesResponce.data.data[0]["quote_services"];
        for (let i = 0; i < rfq_services.length; i++) {
          let rowId = `row-${i}`;
          savedRowIds.add(rowId);
          let description = rfq_services[i]["description"];
          if (
            rfq_services[i]["description"] &&
            rfq_services[i]["description"] !== ""
          ) {
            description = rfq_services[i]["description"];
          } else {
            description = "";
          }
          let newArray = {
            id: `row-${i}`,
            data: {
              id: rfq_services[i]["service_id"],
              sku: rfq_services[i]["sku"],
              name: rfq_services[i]["service_name"],
              quantity: rfq_services[i]["quantity"],
              unit: rfq_services[i]["service_unit"],
              price: rfq_services[i]["service_price"],
              total: Number(
                Number(rfq_services[i]["quantity"]) *
                rfq_services[i]["service_price"]
              ).toFixed(this.getBillingDecimalPoint()),
              type: rfq_services[i]["item_type"],
              description: description,
            },
          };
          servicesRows.push(newArray);
          total =
            total +
            Number(rfq_services[i]["service_price"]) *
            Number(rfq_services[i]["quantity"]);
        }
      }
      //Minus Discount
      let with_discount = total - parseFloat(discount_value_total);
      //Calculate Tax
      with_discount = with_discount + parseFloat(tax_value_total);
      //Calculate Shipping
      with_discount = with_discount + parseFloat(shipping_value_total);

      //**** Get Optional Services ****/rows_optional
      let servicesOptionalRows = [];
      if (
        servicesResponce.data.data[0]["quote_optional_services"] &&
        servicesResponce.data.data[0]["quote_optional_services"].length > 0
      ) {
        let optional_services =
          servicesResponce.data.data[0]["quote_optional_services"];
        for (let i = 0; i < optional_services.length; i++) {
          let newArrays = {
            id: `row-${i}`,
            data: {
              id: optional_services[i]["service_id"],
              name: optional_services[i]["service_name"],
              quantity: optional_services[i]["quantity"],
              unit: optional_services[i]["service_unit"],
              price: optional_services[i]["service_price"],
              total: Number(
                Number(optional_services[i]["quantity"]) *
                optional_services[i]["service_price"]
              ).toFixed(this.getBillingDecimalPoint()),
              type: optional_services[i]["item_type"],
            },
          };
          servicesOptionalRows.push(newArrays);
        }
      }
      if (
        servicesResponce.data.data[0]["client_accountno"] &&
        servicesResponce.data.data[0]["client_accountno"] !== ""
      ) {
        //this.getSingleClient();
      }
      console.log(
        "service_grand_total: " +
        typeof with_discount +
        " service_grand_sub_total: " +
        typeof total
      );

      let all_select_services = [];

      if (servicesResponce.data.data[0]["quote_services"]) {
        const services = servicesResponce.data.data[0]["quote_services"];
        for (let service of services) {
          all_select_services.push({
            label: service.service_name,
            value: service.service_id,
          });
        }
      }

      if (this.state.rows && this.state.rows.length > 0) {
        for (let row of this.state.rows) {
          if (row.data.type === "service" && row.data.id) {
            all_select_services = all_select_services.filter(
              (item) => item.value !== row.data.id
            );
          }
        }
      }

      if (this.state.all_services && this.state.all_services.length > 0) {
        all_select_services = this.state.all_services.map(service => ({
          value: service[0],
          label: service[1] || service[0]
        }));
        if (servicesRows.length > 0) {
          for (let row of servicesRows) {
            if (row.data.id && row.data.id !== "") {
              all_select_services = all_select_services.filter(
                item => item.value !== row.data.id
              );
            }
          }
        }
        if (servicesOptionalRows.length > 0) {
          for (let row of servicesOptionalRows) {
            if (row.data.id && row.data.id !== "") {
              all_select_services = all_select_services.filter(
                item => item.value !== row.data.id
              );
            }
          }
        }
      }

      this.setState({
        rows: servicesRows,
        savedRows: savedRowIds,
        rows_optional: servicesOptionalRows,
        tableData: servicesResponce.data.data,
        quotation_services: servicesResponce.data.data[0]["quote_services"],
        quote_title: servicesResponce.data.data[0]["quote_title"],
        quote_notes: servicesResponce.data.data[0]["quote_notes"],
        quote_attachement: servicesResponce.data.data[0]["quote_attachement"],
        all_answers: servicesResponce.data.all_answers,
        service_grand_total: with_discount,
        service_grand_sub_total: total,
        discount_value_total: discount_value_total,
        discount_type: discount_type,
        discount_value: discount_value,
        discount_reason: discount_reason,
        tax_value_total: tax_value_total,
        tax_type: tax_type,
        tax_value: tax_value,
        tax_reason: tax_reason,
        shipping_value_total: shipping_value_total,
        shipping_value: shipping_value,
        shipping_title: shipping_title,
        email_sent: servicesResponce.data.data[0]["email_sent"],
        conversations: servicesResponce.data.data[0]["conversations"],
        quote_status: servicesResponce.data.data[0]["quote_status"],
        order_date: servicesResponce.data.data[0]["dateCreated"],

        payment_status: servicesResponce.data.data[0]["payment_status"],
        client_accountno: servicesResponce.data.data[0]["client_accountno"],
        quote_accountno: servicesResponce.data.data[0]["quote_accountno"],
        rfq_accountno: servicesResponce.data.data[0]["rfq_accountno"],
        tableLoader: false,
        isEditorReady: true,

        all_select_services: all_select_services,
        all_select_services_optional: all_select_services,
        saveButtonEnabled: servicesRows.length > 0,
        saveButtonEnabledOptional: servicesOptionalRows.length > 0,
        rows_service_temp: servicesRows.map(row => ({
          temp_serv_id: row.id,
          temp_serv_data: {
            temp_serv_inner_id: row.data.id,
            temp_serv_inner_sku: row.data.sku || "",
            temp_serv_inner_name: row.data.name,
            temp_serv_inner_quantity: row.data.quantity || 1,
            temp_serv_inner_unit: row.data.unit || "",
            temp_serv_inner_price: row.data.price,
            temp_serv_inner_total: row.data.total,
            temp_serv_inner_type: "service",
            temp_serv_inner_serviceType: row.data.serviceType || "",
          }
        })),
      });
      setTimeout(() => {
        this.checkServicesSignedAgreements();
      }, 500);
    } else {
      this.setState({ isEditorReady: true });
      // window.location.replace("/error");
    }
  };

  getSingleClient = async () => {
    const servicesResponce = await listSingleClient(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    //console.log("getSingleClient: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        client_details: servicesResponce.data.client_details,
        //quotation_notes: quotation_notes,
        //quote_notes: quotation_notes,
        client_name:
          servicesResponce.data.client_details[0]["name"] &&
            servicesResponce.data.client_details[0]["name"] !== ""
            ? servicesResponce.data.client_details[0]["name"]
            : "",
        client_company: servicesResponce.data.client_details[0]["comp_name"]
          ? servicesResponce.data.client_details[0]["comp_name"]
          : "",
        client_email: servicesResponce.data.client_details[0]["email"]
          ? servicesResponce.data.client_details[0]["email"]
          : "",
        client_phone: servicesResponce.data.client_details[0]["phone"]
          ? servicesResponce.data.client_details[0]["phone"]
          : "",
        client_address: servicesResponce.data.client_details[0]["address"]
          ? servicesResponce.data.client_details[0]["address"]
          : "",
        client_address2: servicesResponce.data.client_details[0]["address2"]
          ? servicesResponce.data.client_details[0]["address2"]
          : "",
        client_city: servicesResponce.data.client_details[0]["city"]
          ? servicesResponce.data.client_details[0]["city"]
          : "",
        client_state: servicesResponce.data.client_details[0]["state"]
          ? servicesResponce.data.client_details[0]["state"]
          : "",
        client_zip: servicesResponce.data.client_details[0]["zip"]
          ? servicesResponce.data.client_details[0]["zip"]
          : "",
        client_country: servicesResponce.data.client_details[0]["country"]
          ? servicesResponce.data.client_details[0]["country"]
          : "",
        reseller_details:
          servicesResponce.data.reseller_details.reseller_details,
        reseller_name: servicesResponce.data.reseller_details
          .reseller_details[0]["name"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["name"]
          : "",
        reseller_company: servicesResponce.data.reseller_details
          .reseller_details[0]["comp_name"]
          ? servicesResponce.data.reseller_details.reseller_details[0][
          "comp_name"
          ]
          : "",
        reseller_email: servicesResponce.data.reseller_details
          .reseller_details[0]["email"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["email"]
          : "",
        reseller_phone: servicesResponce.data.reseller_details
          .reseller_details[0]["phone"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["phone"]
          : "",
        reseller_address: servicesResponce.data.reseller_details
          .reseller_details[0]["address"]
          ? servicesResponce.data.reseller_details.reseller_details[0][
          "address"
          ]
          : "",
        reseller_city: servicesResponce.data.reseller_details
          .reseller_details[0]["city"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["city"]
          : "",
        reseller_state: servicesResponce.data.reseller_details
          .reseller_details[0]["state"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["state"]
          : "",
        reseller_zip: servicesResponce.data.reseller_details
          .reseller_details[0]["zip"]
          ? servicesResponce.data.reseller_details.reseller_details[0]["zip"]
          : "",
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getServices = async () => {
    const servicesResponce = await listServicesAllowed(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("getServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_select_services = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label: servicesResponce.data.data[i][1],
            value: servicesResponce.data.data[i][0],
          };
          all_select_services.push(newArr);
        }
      }
      if (this.state.rows && this.state.rows.length > 0) {
        for (let i = 0; i < this.state.rows.length; i++) {
          if (this.state.rows[i].data.type === "service") {
            if (
              this.state.rows[i].data.id &&
              this.state.rows[i].data.id !== ""
            ) {
              all_select_services = all_select_services.filter(
                (item) => item.value !== this.state.rows[i].data.id
              );
            }
          }
        }
      }
      this.setState({
        all_select_services: all_select_services,
        all_select_services_optional: all_select_services,
        all_services: servicesResponce.data.data,
        service_unsigned_agreements:
          servicesResponce.data.service_unsigned_agreements,
      });
    }
  };

  getPaymentSettings = async () => {
    const { orderno } = this.state;
    this.setState({
      paymentMethodLoader: true,
      payErrorMessage: "",
    });
    const servicesResponce = await getPaymentSettings(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno
    );
    //console.log("getPaymentSettings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        payment_terms: servicesResponce.data.payment_terms,
        currency: servicesResponce.data.currency,
        validity: servicesResponce.data.validity,
        contact_person: servicesResponce.data.contact_person,
        contact_email: servicesResponce.data.contact_email,
      });
    } else {
    }
  };

  getPaymentMethod = async () => {
    const { orderno } = this.state;
    this.setState({
      paymentMethodLoader: true,
      payErrorMessage: "",
    });
    const servicesResponce = await getPaymentMethodForOrder(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno
    );
    //console.log("getPaymentMethod: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        paymentMethodLoader: false,
        payErrorMessage:
          "There is some error while getting the payment methods.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        payment_method: servicesResponce.data.data[0]["payment_method"],
        payment_details: servicesResponce.data.data[0]["payment_details"],
        paymentMethodLoader: false,
      });
    } else {
      this.setState({
        paymentMethodLoader: false,
        payErrorMessage:
          "There is some error while getting the payment methods.",
      });
      // window.location.replace("/error");
    }
    if (this.state.payErrorMessage !== "") {
      setTimeout(() => {
        this.setState({
          payErrorMessage: "",
        });
      }, 4000);
    }
  };

  getPaymentLogs = async () => {
    const { orderno } = this.state;
    this.setState({
      paymentLogLoader: true,
      payLogErrorMessage: "",
    });
    const servicesResponce = await getPaymentLogs(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno
    );
    console.log("getPaymentLogs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        paymentLogLoader: false,
        payLogErrorMessage:
          "There is some error while getting the payment logs.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        payment_logs: servicesResponce.data.data,
        paymentLogLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        payment_logs: [],
        payLogErrorMessage: "Payment logs not found.",
        paymentLogLoader: false,
      });
    } else {
      this.setState({
        paymentLogLoader: false,
        payLogErrorMessage:
          "There is some error while getting the payment logs.",
      });
      // window.location.replace("/error");
    }
  };

  getOrderComments = async () => {
    const { orderno } = this.state;
    this.setState({
      orderCommentLoader: true,
      payLogErrorMessage: "",
    });
    const servicesResponce = await getOrderComments(
      auth.getClientAccount(),
      auth.getClientToken(),
      orderno
    );
    console.log("getPaymentLogs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        orderCommentLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        order_comments: servicesResponce.data.data,
        orderCommentLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        payment_logs: [],

        orderCommentLoader: false,
      });
    } else {
      this.setState({
        orderCommentLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  showRequirements = async () => {
    const { rfq_accountno } = this.state;
    this.setState({
      formLoader: true,
      rfq_requirements: "",
    });

    const servicesResponce = await getSingleRfq(
      auth.getClientAccount(),
      auth.getClientToken(),
      rfq_accountno
    );
    console.log("getSingleRfq: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        rfq_details: [],
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      console.log(
        "servicesResponce.data.all_answers: ",
        servicesResponce.data.data[0].all_answers
      );
      if (
        servicesResponce.data.data[0]["rfq_questions"] &&
        servicesResponce.data.data[0]["rfq_questions"].length > 0
      ) {
        let rfq_questions = servicesResponce.data.data[0]["rfq_questions"];
        for (let i = 0; i < rfq_questions.length; i++) {
          if (
            rfq_questions[i]["question_type"] === "radio" ||
            rfq_questions[i]["question_type"] === "dropdown"
          ) {
            let answer_id = this.doesAnswerIdExist(
              Number(rfq_questions[i]["answer_id"]),
              servicesResponce.data.data[0].all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
            console.log(
              "radio answer answer_id: " +
              rfq_questions[i]["answer_id"] +
              " " +
              answer_id
            );
          } else if (rfq_questions[i]["question_type"] === "checkbox") {
            let answer_id = this.doesAnswerArrayExist(
              JSON.parse(rfq_questions[i]["answer_id"]),
              servicesResponce.data.data[0].all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
            console.log(
              "checkbox answer answer_id: " +
              JSON.parse(rfq_questions[i]["answer_id"]) +
              " " +
              answer_id
            );
          } else {
            rfq_questions[i]["answer"] = rfq_questions[i]["answer_id"];
          }
        }
      }

      this.setState({
        rfq_details: servicesResponce.data.data,
        quotation_services: servicesResponce.data.data[0]["rfq_services"],
        rfq_requirements: servicesResponce.data.data[0]["rfq_comments"],
        rfq_attachment: servicesResponce.data.data[0]["rfq_attachement"],
        all_answers: servicesResponce.data.all_answers,
      });
    } else {
      this.setState({
        rfq_details: [],
      });
      // window.location.replace("/error");
    }

    window.$("#modalFormOptions").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  doesAnswerIdExist = (answerIdToCheck, answerArray) => {
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const answer = answerArray.find((item) => item.id === answerIdToCheck);
    return answer ? answer.question_options : "";
  };

  doesAnswerArrayExist = (answerIdToCheck, answerArray) => {
    console.log(
      "answerIdToCheck, answerArray: " + answerIdToCheck + " " + answerArray
    );
    answerIdToCheck = JSON.stringify(answerIdToCheck);
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const objectValues = answerIdToCheck.slice(1, -1).split(",");
    var matchingItems = "";
    objectValues.map((setString, index) => {
      console.log("setString: ", setString);
      let answer = answerArray.find((item) => item.id === Number(setString));
      if (answer) {
        matchingItems +=
          "<p>" + (Number(index) + 1) + ": " + answer.question_options + "</p>";
      }
    });

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: matchingItems,
        }}
      />
    );
  };

  downloadOrderPdf = async (d_f_name) => {
    //var url = d_f_name;
    let file_name = d_f_name;
    var url = APP_LIVE_URL + "files_data/rfq/" + d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  fieldValidation = () => {
    let { emailto, emailsubject, emailcontent } = this.state;

    if (emailto === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "To email address is required.",
      });
      return false;
    } else if (this.validateEmailAdd(emailto) === false) {
      this.setState({
        emailErrorMessageTitle: "Invalid Format",
        emailErrorMessage: "Invalid email address format.",
      });
      return false;
    } else if (emailsubject === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Subject is required.",
      });
      return false;
    } else if (emailcontent === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Custom content is required.",
      });
      return false;
    } else {
      this.setState({
        emailErrorMessageTitle: "",
        emailErrorMessage: "",
      });
    }
    return true;
  };

  submitOrderEmail = async () => {
    const { orderno, emailto, emailcc, emailbcc, emailsubject, emailcontent } =
      this.state;

    if (this.fieldValidation() === true) {
      this.setState({
        email_button: true,
        emailErrorMessage: "",
        emailErrorMessageTitle: "",
        emailSuccessMessage: "",
      });
      const servicesResponce = await sendOrderEmail(
        auth.getClientAccount(),
        auth.getClientToken(),
        orderno,
        emailto,
        emailcc,
        emailbcc,
        emailsubject,
        emailcontent
      );
      console.log("submitOrderEmail: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          emailSuccessMessage: "Email sent successfully.",
          payment_status: servicesResponce.data.data,
          emailErrorMessageTitle: "",
          emailErrorMessage: "",
          emailsubject: "",
          emailcontent: "",
          email_button: false,
        });
      } else {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      }
    }
    if (
      this.state.emailErrorMessage !== "" ||
      this.state.emailSuccessMessage !== ""
    ) {
      setTimeout(() => {
        this.setState({
          emailErrorMessage: "",
          emailSuccessMessage: "",
        });
      }, 4000);
    }
  };

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  openEmailWindow = async () => {
    const { orderno } = this.state;
    this.setState({
      email_button: false,
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
    });
    window.$("#modalFormEmail").modal("show");
  };

  rejectSalesOrder = async () => {
    const { orderno } = this.state;
    this.setState({
      rejection_reason: "",
      rejectErrorMessage: "",
      rejectSuccessMessage: "",
    });
    window.$("#modalRejectSalesOrder").modal("show");
  };

  rejectQuotationSubmit = async () => {
    const { orderno, rejection_reason } = this.state;
    this.setState({
      rejectionButton: true,
      rejectErrorMessage: "",
      rejectSuccessMessage: "",
    });
    if (rejection_reason === "") {
      this.setState({
        rejectErrorMessage: "Please enter the reason.",
        rejectErrorMessageTitle: "Missing Fields",
      });
    } else {
      const rejectionResponse = await rejectSalesOrder(
        auth.getClientAccount(),
        auth.getClientToken(),
        orderno,
        rejection_reason
      );
      console.log("rejectionResponse: ", rejectionResponse.data);
      if (
        rejectionResponse.data.status === 403 ||
        rejectionResponse.data.errors === "authentication missing" ||
        rejectionResponse.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (
        rejectionResponse.data.status === 404 &&
        rejectionResponse.data.message === "not_found"
      ) {
        this.setState({
          rejectErrorMessage: "Sales order not found.",
          rejectErrorMessageTitle: "Missing Sales Order",
          rejectSuccessMessage: "",
          rejectionButton: false,
        });
      } else if (
        rejectionResponse.data.status === 200 &&
        rejectionResponse.data.message === "success"
      ) {
        this.setState({
          rejectErrorMessage: "",
          rejectErrorMessageTitle: "",
          rejectSuccessMessage: "Sales order rejected successfully.",
          rejectionButton: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.setState({
          rejectErrorMessage:
            "There is some error while processing your request.",
          rejectErrorMessageTitle: "Sales Order Error",
          rejectSuccessMessage: "",
          rejectionButton: false,
        });
      }
      setTimeout(() => {
        this.setState({
          rejectErrorMessage: "",
          rejectErrorMessageTitle: "",
          rejectSuccessMessage: "",
        });
      }, 4000);
    }
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: ", typeof amount);
    let decimal_point = this.getBillingDecimalPoint();
    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimal_point,
      maximumFractionDigits: decimal_point,
    });
  };

  edit_quotation_info = async () => {
    const { client_accountno } = this.state;
    let qoutationAgreementsList = [];
    //Zc5DaPETvz7oXkd
    this.setState({
      quotation_agreements: qoutationAgreementsList,
    });
    window.$("#modalDefaultInfo").modal("show");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  addRow = () => {
    this.getServices();
    let id = `row-${Date.now()}`;

    const newRow = {
      id: id,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "service",
      },
    };

    const newRows = {
      temp_serv_id: id,
      temp_serv_data: {
        temp_serv_inner_id: "",
        temp_serv_inner_sku: "",
        temp_serv_inner_name: "",
        temp_serv_inner_quantity: 1,
        temp_serv_inner_unit: "",
        temp_serv_inner_price: 0,
        temp_serv_inner_total: 0,
        temp_serv_inner_type: "service",
      },
    };

    this.setState((prevState) => ({
      rows: [...prevState.rows, newRow],
      rows_service_temp: [...prevState.rows_service_temp, newRows],
      saveButtonEnabled: true,
    }));
    console.log("addRow rows_service_temp: ", this.state.rows_service_temp);
  };

  addRowService = async (service_id) => {
    await this.getServices();

    let id = `row-${Date.now()}`;

    const newRow = {
      id: id,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "service",
      },
    };

    const newRows = {
      temp_serv_id: id,
      temp_serv_data: {
        temp_serv_inner_id: "",
        temp_serv_inner_sku: "",
        temp_serv_inner_name: "",
        temp_serv_inner_quantity: 1,
        temp_serv_inner_unit: "",
        temp_serv_inner_price: 0,
        temp_serv_inner_total: 0,
        temp_serv_inner_type: "service",
      },
    };

    this.setState(
      (prevState) => ({
        rows: [...prevState.rows, newRow],
        rows_service_temp: [...prevState.rows_service_temp, newRows],
      }),
      () => {
        // Automatically select the product if service_id is provided
        if (service_id) {
          const selectedOption = this.state.all_select_services.find(
            (service) => service.value === parseInt(service_id)
          );
          if (selectedOption) {
            this.handleChangeSearchNew(selectedOption, {
              action: "select-option",
              row: newRow,
              index: this.state.rows.length - 1,
            });
          }
        }
        setTimeout(() => {
          this.addNewService(newRow.id);
        }, 500);
      }
    );
    console.log("addRow rows_service_temp: ", this.state.rows_service_temp);
  };

  addRowOptional = () => {
    this.getServices();
    const newRow = {
      id: `row-${Date.now()}`,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "service",
      },
    };

    this.setState((prevState) => ({
      rows_optional: [...prevState.rows_optional, newRow],
    }));
  };

  addRowSec = () => {
    //this.getServices();
    const newRow = {
      id: `row-${Date.now()}`,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "section",
      },
    };

    this.setState((prevState) => ({
      rows: [...prevState.rows, newRow],
      saveButtonEnabled: true,
    }));
  };

  addRowSecOptional = () => {
    //this.getServices();
    const newRow = {
      id: `row-${Date.now()}`,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "section",
      },
    };

    this.setState((prevState) => ({
      rows_optional: [...prevState.rows_optional, newRow],
    }));
  };

  addRowCustom = () => {
    //this.getServices();
    let id = `row-${Date.now()}`;
    const newRow = {
      temp_id: id,
      temp_data: {
        temp_inner_id: "",
        temp_inner_sku: "",
        temp_inner_name: "",
        temp_inner_quantity: 1,
        temp_inner_unit: "",
        temp_inner_price: 1,
        temp_inner_total: 1,
        temp_inner_type: "custom",
        temp_inner_description: "",
      },
    };

    const newRow2 = {
      id: id,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 1,
        total: 1,
        type: "custom",
        description: "",
      },
    };

    this.setState((prevState) => ({
      rows_custom_temp: [...prevState.rows_custom_temp, newRow],
      rows: [...prevState.rows, newRow2],
    }));
  };

  removeRow = (id) => {
    const { rows } = this.state;
    const updatedRows = rows.filter((row) => row.id !== id);

    this.setState((prevState) => ({
      rows: prevState.rows.filter((row) => row.id !== id),
      rows_service_temp: prevState.rows_service_temp.filter((row) => row.temp_serv_id !== id),
      [`select_a_service_${this.getRowIndex(id)}`]: null,
      saveButtonEnabled: updatedRows.length > 0,
    }));

    setTimeout(() => {
      this.calculateTotalRate();
      if (this.state.discount_type) {
        this.applyDiscount();
      }

      if (this.state.tax_type) {
        this.applyTax();
      }
    }, 500);
  };

  getRowIndex = (rowId) => {
    const { rows } = this.state;
    return rows.findIndex(row => row.id === rowId);
  };

  removeRowOptional = (id) => {
    this.setState((prevState) => ({
      rows_optional: prevState.rows_optional.filter((row) => row.id !== id),
    }));
  };

  handleChangeServices = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    const rows_service_temp = [...this.state.rows_service_temp];
    console.log("services_rows[idx][value]: ", value);
    console.log("services_rows[idx][name]: ", name);
    console.log("handleChangeServices rows: ", rows);
    let sub_total = 0;
    if (rows_service_temp) {
      for (let i = 0; i < rows_service_temp.length; i++) {
        console.log(
          "select_service: " + " " + rows_service_temp[i].temp_serv_id
        );
        if (idx == rows_service_temp[i].temp_serv_id) {
          if (name === "name") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_name = value;
          }
          if (name === "unit") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_unit = value;
          }
          if (name === "quantity") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity =
              value;
            const quantity = parseInt(value) || 0;
            const price =
              parseFloat(
                rows_service_temp[i].temp_serv_data.temp_serv_inner_price
              ) || 0;
            sub_total = price * quantity;
            rows_service_temp[i].temp_serv_data.temp_serv_inner_total = Number(
              sub_total
            ).toFixed(this.getBillingDecimalPoint());
          }

          if (name === "price") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_price = value;
            const quantity =
              parseInt(
                rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity
              ) || 0;
            const price = parseFloat(value) || 0;
            sub_total = price * quantity;
            rows_service_temp[i].temp_serv_data.temp_serv_inner_total = Number(
              sub_total
            ).toFixed(this.getBillingDecimalPoint());
          }
        }
      }
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows_service_temp = [...prevState.rows_service_temp];
        if (rows_service_temp) {
          rows_service_temp.temp_serv_data[name] = value;
        }
      });
    }
    //Handle Actual Rows
    if (rows) {
      for (let i = 0; i < rows.length; i++) {
        console.log("select_service: " + " " + rows[i].id);
        if (idx == rows[i].id) {
          if (name === "name") {
            rows[i].data.name = value;
          }
          if (name === "unit") {
            rows[i].data.unit = value;
          }
          if (name === "quantity") {
            rows[i].data.quantity = value;
            const quantity = parseInt(value) || 0;
            const price = parseFloat(rows[i].data.price) || 0;
            sub_total = price * quantity;
            rows[i].data.total = Number(sub_total).toFixed(
              this.getBillingDecimalPoint()
            );
          }

          if (name === "price") {
            rows[i].data.price = value;
            const quantity = parseInt(rows[i].data.quantity) || 0;
            const price = parseFloat(value) || 0;
            sub_total = price * quantity;
            rows[i].data.total = Number(sub_total).toFixed(
              this.getBillingDecimalPoint()
            );
          }
        }
      }
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows = [...prevState.rows];
        if (rows) {
          rows.data[name] = value;
        }
      });
    }

    this.setState({
      rows_service_temp,
      rows,
      isBlocking: false,
    });
    if (name === "quantity" || name === "price") {
      this.calculateTotalRate();
    }
    console.log("rows_service_temp: ", this.state.rows_service_temp);
    //
  };

  calculateTotalRate = async () => {
    const { rows, discount_value_total } = this.state;

    console.log("calculateTotalRate: ", rows);
    let total = 0;

    if (rows && rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        total =
          total + Number(rows[i].data.price) * Number(rows[i].data.quantity);
      }
    }
    console.log("calculateTotalRate Total: ", total);
    let with_discount = total - parseFloat(discount_value_total);
    //Calculate Tax
    let tax_value_total = this.state.tax_value_total;
    with_discount = with_discount + parseFloat(tax_value_total);
    //Calculate Shipping
    let shipping_value_total = this.state.shipping_value_total;
    with_discount = with_discount + parseFloat(shipping_value_total);

    //Calculate Shipping and Discount

    //with_discount = with_discount + total_tax;
    console.table({
      with_discount_shipping_tax: with_discount,
      discount_value_total: discount_value_total,
      tax_value_total: tax_value_total,
      shipping_value_total: shipping_value_total,
    });
    console.log("service_grand_sub_total: ", total);
    console.log("service_grand_total: ", with_discount);
    this.setState({
      service_grand_total:
        with_discount && with_discount > 0
          ? with_discount.toFixed(this.getBillingDecimalPoint())
          : (0).toFixed(this.getBillingDecimalPoint()),
      service_grand_sub_total:
        total && total > 0
          ? total.toFixed(this.getBillingDecimalPoint())
          : (0).toFixed(this.getBillingDecimalPoint()),
    });
  };

  getServiceWithId = async (select_service) => {
    const { all_services } = this.state;
    console.log("all_services: ", all_services);
    if (all_services && all_services.length > 0) {
      for (let i = 0; i < all_services.length; i++) {
        console.log(
          "select_service: " + select_service + " " + all_services[i][0]
        );
        if (select_service == all_services[i][0]) {
          return all_services[i];
        }
      }
    }
  };

  handleChangeSearchNew = (selectedOption, { action, row, index }) => {
    const { rows_service_temp, all_services } = this.state;

    if (selectedOption) {
      const selectedService = all_services.find(
        (service) => service[0] === selectedOption.value
      );
      const servicePrice = selectedService ? selectedService[8] : 0;
      const serviceQuantity = row.data.quantity || 1;
      const serviceTotal = servicePrice * serviceQuantity;

      this.setState((prevState) => {
        const updatedRows = prevState.rows.map((r) => {
          if (r.id === row.id) {
            return {
              ...r,
              data: {
                ...r.data,
                price: servicePrice,
                total: Number(serviceTotal).toFixed(this.getBillingDecimalPoint()),
              },
            };
          }
          return r;
        });

        return {
          select_service: selectedOption.value,
          [`select_a_service_${index}`]: selectedOption,
          rows: updatedRows,
        };
      });

      // Update rows_service_temp with the new selection
      if (rows_service_temp) {
        this.setState((prevState) => {
          const filteredRows = prevState.rows_service_temp.filter(
            (rows) => rows.temp_serv_id !== row.id
          );

          const newRow = {
            temp_serv_id: row.id,
            temp_serv_data: {
              temp_serv_inner_id: selectedOption.value,
              temp_serv_inner_sku: selectedService ? selectedService[9] : "",
              temp_serv_inner_name: selectedOption.label,
              temp_serv_inner_quantity: row.data.quantity || 1,
              temp_serv_inner_unit: row.data.unit || "",
              temp_serv_inner_price: servicePrice,
              temp_serv_inner_total: serviceTotal.toFixed(
                this.getBillingDecimalPoint()
              ),
              temp_serv_inner_type: "service",
            },
          };

          return {
            rows_service_temp: [...filteredRows, newRow],
          };
        });
      }
    } else {
      this.setState({ [`select_a_service_${index}`]: null });
      this.setState((prevState) => {
        const filteredRows = prevState.rows_service_temp.filter(
          (rows) => rows.temp_serv_id !== row.id
        );
        return {
          rows_service_temp: filteredRows
        };
      });
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    //console.log("handleChangeSearch idx: ", idx);
    if (changed_value) {
      this.setState((prevState) => ({
        select_service: changed_value.value,
        select_a_service: changed_value,
      }));
    } else {
      this.setState({ select_a_service: null });
    }
  };

  handleChangeSearchOptional = async (changed_value) => {
    console.log("handleChangeSearchOptional: ", changed_value);
    if (changed_value) {
      this.setState((prevState) => ({
        select_service_optional: changed_value.value,
        select_a_service_optional: changed_value,
      }));
    } else {
      this.setState({ select_a_service_optional: null });
    }
  };

  handleRemoveSpecificRowGeneric = (idx) => () => {
    const quotation_services = [...this.state.quotation_services];
    quotation_services.splice(idx, 1);
    this.setState({ quotation_services });
    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
  };

  handleInputChange = (index, event) => {
    const { services } = this.state;
    const { name, value } = event.target;

    const updatedServices = services.map((service, i) => {
      if (i === index) {
        return { ...service, [name]: value };
      }
      return service;
    });

    this.setState({ services: updatedServices }, () => {
      this.calculateSubtotal(index);
    });
  };

  calculateSubtotal = (index) => {
    const { services } = this.state;
    const service = services[index];
    const subtotal = service.price * service.quantity;

    const updatedServices = services.map((s, i) => {
      if (i === index) {
        return { ...s, subtotal };
      }
      return s;
    });

    this.setState({ services: updatedServices });
  };

  addNewService = async (row_id) => {
    const { rows, rows_service_temp } = this.state;

    // Find the selected row
    const rowToUpdate = rows_service_temp.find(
      (row) => row.temp_serv_id === row_id
    );
    if (!rowToUpdate) return; // Exit if no matching row is found

    const index = rows_service_temp.indexOf(rowToUpdate);
    const selectedServiceId = rowToUpdate.temp_serv_data?.temp_serv_inner_id;

    if (selectedServiceId) {
      const selectedServiceRow = await this.getServiceWithId(selectedServiceId);

      if (selectedServiceRow && selectedServiceRow.length > 0) {
        const qty = rowToUpdate.temp_serv_data.temp_serv_inner_quantity || 1;
        const unit = rowToUpdate.temp_serv_data.temp_serv_inner_unit || "";

        // Remove the old row and replace it with the new data
        this.setState((prevState) => ({
          rows: prevState.rows.map((r) =>
            r.id === row_id
              ? {
                ...r,
                data: {
                  id: selectedServiceRow[0],
                  sku: selectedServiceRow[9] || "",
                  name: rowToUpdate.temp_serv_data.temp_serv_inner_name,
                  quantity: qty,
                  unit: unit,
                  price: selectedServiceRow[8]
                    ? Number(selectedServiceRow[8]).toFixed(
                      this.getBillingDecimalPoint()
                    )
                    : (0).toFixed(this.getBillingDecimalPoint()),
                  total: Number(selectedServiceRow[8] * qty).toFixed(
                    this.getBillingDecimalPoint()
                  ),
                  type: "service",
                },
              }
              : r
          ),
          savedRows: new Set([...prevState.savedRows, row_id]),
        }));

        setTimeout(() => {
          this.calculateTotalRate();
          this.checkServicesSignedAgreements();
        }, 500);
      }
    }
  };

  validateAllRowsSaved = () => {
    const { rows, savedRows, editingRows, editingSection, editableRows } =
      this.state;

    let messages = [];
    let lineItemIssue = false;

    // Validate all line items (regular and custom)
    const unsavedRows = rows.filter(
      (row) =>
        !savedRows.has(row.id) ||
        editingRows.has(row.id) ||
        editableRows.has(row.id)
    );
    if (unsavedRows.length > 0 || editingSection !== null) {
      lineItemIssue = true;
    }

    // Generate messages based on the issues found
    if (lineItemIssue) {
      messages.push("Please save all line items before proceeding.");
    }
    // Return the appropriate message
    return messages.length > 0 ? messages[0] : null;
  };

  checkServicesSignedAgreements = async () => {
    const { service_unsigned_agreements, rows } = this.state;
    const matchedRecords = this.matchAgreementRecords(
      rows,
      service_unsigned_agreements
    );
    this.setState({
      final_unsigned_list: matchedRecords,
    });
  };

  matchAgreementRecords = (firstArray, secondArray) => {
    // Check if either array is empty and return an empty array if true
    if (
      !Array.isArray(firstArray) ||
      firstArray.length === 0 ||
      !Array.isArray(secondArray) ||
      secondArray.length === 0
    ) {
      return [];
    }

    // Filter items from firstArray that match any item in secondArray by ID
    const matchedRecords = firstArray
      .filter((item1) =>
        secondArray.some((item2) => item1.data.id === item2.service_id)
      )
      .map((item1) => {
        const matchingData = secondArray.find(
          (item2) => item1.data.id === item2.service_id
        );
        return matchingData;
      });

    return matchedRecords;
  };

  handleChangeSection = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];

    this.setState({
      select_name: value,
      isBlocking: false,
    });
  };

  addNewSection = async (row_id) => {
    const { select_name, rows } = this.state;

    console.log("select_name: ", select_name + " " + row_id);
    if (select_name !== "") {
      const newRow = {
        id: row_id,
        data: {
          id: "",
          name: select_name,
          quantity: "",
          unit: "",
          price: "",
          total: "",
          type: "section",
        },
      };

      const updatedRows = rows.map((row) => (row.id === row_id ? newRow : row));

      const isRowExists = rows.some((row) => row.id === row_id);
      const finalRows = isRowExists ? updatedRows : [...rows, newRow];

      this.setState((prevState) => ({
        rows: finalRows,
        select_service: "",
        select_name: "",
        savedRows: new Set([...prevState.savedRows, row_id]),
      }));
    }
  };

  handleChangeCustom = (idx) => (e) => {
    const { name, value } = e.target;
    const rows_custom_temp = [...this.state.rows_custom_temp];
    const rows = [...this.state.rows];
    console.log("services_rows[idx][value]: ", value);
    console.log("services_rows[idx][name]: ", name);
    console.log("handleChangeCustom rows: ", rows_custom_temp);
    let sub_total = 0;
    if (!rows_custom_temp.find((row) => row.temp_id === idx)) {
      const existingRow = rows.find((row) => row.id === idx);
      if (existingRow) {
        const newTempRow = {
          temp_id: idx,
          temp_data: {
            temp_inner_id: existingRow.data.id,
            temp_inner_sku: existingRow.data.sku,
            temp_inner_name: existingRow.data.name,
            temp_inner_description: existingRow.data.description,
            temp_inner_quantity: existingRow.data.quantity,
            temp_inner_unit: existingRow.data.unit,
            temp_inner_price: existingRow.data.price,
            temp_inner_total: existingRow.data.total,
            temp_inner_type: "custom",
          },
        };
        rows_custom_temp.push(newTempRow);
      }
    }
    if (rows_custom_temp) {
      for (let i = 0; i < rows_custom_temp.length; i++) {
        //console.log("select_service: " + " " + rows_custom_temp[i].id);
        console.log(
          "select_custom_skus idx = temp_id: " +
          " " +
          idx +
          " " +
          rows_custom_temp[i].temp_id
        );
        if (idx == rows_custom_temp[i].temp_id) {
          if (name === "select_custom_id") {
            rows_custom_temp[i].temp_data.temp_inner_id = value;
          }
          if (name === "select_custom_sku") {
            rows_custom_temp[i].temp_data.temp_inner_sku = value;
          }
          if (name === "select_custom_name") {
            rows_custom_temp[i].temp_data.temp_inner_name = value;
          }
          if (name === "select_custom_description") {
            rows_custom_temp[i].temp_data.temp_inner_description = value;
          }
          if (name === "select_custom_unit") {
            rows_custom_temp[i].temp_data.temp_inner_unit = value;
          }
          if (name === "select_custom_quantity") {
            rows_custom_temp[i].temp_data.temp_inner_quantity = value;

            const quantity = parseInt(value) || 0;
            const price =
              parseFloat(rows_custom_temp[i].temp_data.temp_inner_price) || 0;
            sub_total = price * quantity;

            rows_custom_temp[i].temp_data.temp_inner_total = sub_total;
          }

          if (name === "select_custom_price") {
            rows_custom_temp[i].temp_data.temp_inner_price = value;

            const quantity =
              parseInt(rows_custom_temp[i].temp_data.temp_inner_quantity) || 0;
            const price = parseFloat(value) || 0;
            sub_total = price * quantity;

            parseFloat(value);
            rows_custom_temp[i].temp_data.temp_inner_total = sub_total;
          }
        }
      }
      console.log("handleChangeCustom rows after: ", rows_custom_temp);
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows_custom_temp = [...prevState.rows_custom_temp];
        if (rows_custom_temp) {
          rows_custom_temp.data[name] = value;
        }
      });
    }

    this.setState({
      rows_custom_temp,
      isBlocking: false,
    });

    if (
      name === "select_custom_quantity" ||
      name === "select_custom_price" ||
      name === "select_custom_sku" ||
      name === "select_custom_description"
    ) {
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          if (idx == rows[i].id) {
            if (rows[i].data.name !== "" && rows[i].data.id !== "") {
              if (name === "select_custom_id") {
                rows[i].data.id = value;
              }
              if (name === "select_custom_name") {
                rows[i].data.name = value;
              }
              if (name === "select_custom_description") {
                rows[i].data.description = value;
              }
              /*if (name === "select_custom_sku") {
                rows[i].data.sku = value;
              }*/
              if (name === "select_custom_unit") {
                rows[i].data.unit = value;
              }
              if (name === "select_custom_quantity") {
                rows[i].data.quantity = value;

                const quantity = parseInt(value) || 0;
                const price = parseFloat(rows[i].data.price) || 0;
                sub_total = price * quantity;

                rows[i].data.total = sub_total;
              }

              if (name === "select_custom_price") {
                rows[i].data.price = value;

                const quantity = parseInt(rows[i].data.quantity) || 0;
                const price = parseFloat(value) || 0;
                sub_total = price * quantity;

                rows[i].data.total = sub_total;
              }
            }
          }
        }
        setTimeout(() => {
          this.calculateTotalRate();
        }, 500);
      }
    }

    console.log("this.state.rows_custom_temp: ", this.state.rows_custom_temp);
  };

  addNewCustomService = async (row_id) => {
    const { select_name, rows, rows_custom_temp } = this.state;
    const customTempRow = rows_custom_temp.find(
      (row) => row.temp_id === row_id
    );
    if (!customTempRow) {
      console.error("Custom row not found in temporary storage.");
      return;
    }
    console.log("select_name: ", select_name + " " + row_id);
    if (rows_custom_temp) {
      for (let i = 0; i < rows_custom_temp.length; i++) {
        if (row_id == rows_custom_temp[i].temp_id) {
          const updatedRow = {
            id: row_id,
            data: {
              id: rows_custom_temp[i].temp_data.temp_inner_id,
              sku: rows_custom_temp[i].temp_data.temp_inner_sku,
              name: rows_custom_temp[i].temp_data.temp_inner_name,
              description: rows_custom_temp[i].temp_data.temp_inner_description,
              quantity: rows_custom_temp[i].temp_data.temp_inner_quantity,
              unit: rows_custom_temp[i].temp_data.temp_inner_unit,
              price:
                rows_custom_temp[i].temp_data.temp_inner_price &&
                  rows_custom_temp[i].temp_data.temp_inner_price > 0
                  ? Number(
                    rows_custom_temp[i].temp_data.temp_inner_price
                  ).toFixed(this.getBillingDecimalPoint())
                  : (0).toFixed(this.getBillingDecimalPoint()),
              total: (
                Number(rows_custom_temp[i].temp_data.temp_inner_quantity) *
                Number(rows_custom_temp[i].temp_data.temp_inner_price)
              ).toFixed(this.getBillingDecimalPoint()),
              type: "custom",
            },
          };

          // Update rows while preserving the original order
          const updatedRows = rows.map((row) =>
            row.id === row_id ? updatedRow : row
          );

          this.setState((prevState) => ({
            rows: updatedRows,
            select_service: "",
            savedRows: new Set([...prevState.savedRows, row_id]),
            editableRows: new Set(
              [...prevState.editableRows].filter((id) => id !== row_id)
            ),
          }));
        }
      }

      // Remove the row from temporary storage
      this.setState((prevState) => ({
        rows_custom_temp: prevState.rows_custom_temp.filter(
          (row) => row.temp_id !== row_id
        ),
      }));

      // Recalculate total rate after a slight delay
      setTimeout(() => {
        this.calculateTotalRate();
      }, 500);
    }
  };

  open_discount = () => {
    window.$("#modalFormDiscount").modal("show");
  };

  applyDiscount = async () => {
    const { service_grand_total, service_grand_sub_total } = this.state;
    console.log(
      "applyDiscount: " +
      this.state.discount_value +
      " " +
      this.state.discount_type +
      " " +
      this.state.discount_reason +
      " service_grand_total: " +
      service_grand_total +
      " service_grand_sub_total: " +
      service_grand_sub_total
    );
    let total_with_discount = service_grand_total;
    let discount_value_total = 0;
    if (this.state.discount_type === "amount") {
      total_with_discount =
        parseFloat(total_with_discount) + parseFloat(this.state.discount_value);
      discount_value_total = parseFloat(this.state.discount_value);
      this.setState({
        discount_value_total: discount_value_total,
      });
    } else if (this.state.discount_type === "percentage") {
      let percentage = this.calculatePercent(
        parseFloat(this.state.discount_value),
        parseFloat(service_grand_sub_total)
      );
      total_with_discount =
        parseFloat(total_with_discount) + parseFloat(percentage);
      discount_value_total = parseFloat(percentage);
      this.setState({
        discount_value_total: discount_value_total,
      });
    }

    setTimeout(() => {
      this.calculateTotalRate();
      window.$("#modalFormDiscount").modal("hide");
    }, 500);

    //await this.calculateTotalRate();
  };

  removeDiscount = async () => {
    this.setState({
      discount_reason: "",
      discount_type: "amount",
      discount_value: 0,
      discount_value_total: 0,
    });
    setTimeout(async () => {
      // Your async function here
      await this.applyDiscount();
    }, 500);
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(4);
  };

  open_shipping = () => {
    window.$("#modalFormShipping").modal("show");
  };

  applyShipping = async () => {
    const { service_grand_total, service_grand_sub_total } = this.state;
    console.log(
      "applyDiscount: " +
      this.state.shipping_title +
      " " +
      this.state.shipping_value +
      " " +
      this.state.shipping_value_total +
      " service_grand_total: " +
      service_grand_total +
      " service_grand_sub_total: " +
      service_grand_sub_total
    );

    let shipping_value_total = 0;

    shipping_value_total = parseFloat(this.state.shipping_value);

    this.setState({
      shipping_value_total: shipping_value_total,
    });
    window.$("#modalFormShipping").modal("hide");
    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
    //await this.calculateTotalRate();
  };

  removeShipping = async () => {
    this.setState({
      shipping_title: "",
      shipping_value: 0,
      shipping_value_total: 0,
    });
    await this.applyShipping();
  };

  open_tax = async () => {
    window.$("#modalFormTax").modal("show");
  };

  applyTax = async () => {
    const {
      service_grand_total,
      service_grand_sub_total,
      discount_value_total,
    } = this.state;

    console.table({
      applyTax: "",
      tax_value: parseFloat(this.state.tax_value),
      tax_type: this.state.tax_type,
      service_grand_total: parseFloat(service_grand_total),
      service_grand_sub_total: parseFloat(service_grand_sub_total),
      discount_value_total: parseFloat(discount_value_total),
    });

    let tax_value_total = 0;
    if (this.state.tax_type === "amount") {
      tax_value_total = parseFloat(this.state.tax_value);
      this.setState({
        tax_value_total: tax_value_total,
      });
    } else if (this.state.tax_type === "percentage") {
      let discount_difference =
        parseFloat(service_grand_sub_total) - parseFloat(discount_value_total);
      let percentage = this.calculatePercent(
        parseFloat(this.state.tax_value),
        parseFloat(discount_difference)
      );

      console.log("Tax Percentage: ", percentage);
      tax_value_total = parseFloat(percentage);
      this.setState({
        tax_value_total: tax_value_total,
      });
    }
    setTimeout(() => {
      this.calculateTotalRate();
      window.$("#modalFormTax").modal("hide");
    }, 500);
  };

  removeTax = async () => {
    this.setState({
      tax_reason: "",
      tax_type: "amount",
      tax_value: 0,
      tax_value_total: 0,
    });
    await this.applyTax();
  };

  modalHide = (modalId) => {
    window.$("#" + modalId).modal("hide");
  };

  save_as_draft = async () => {
    const {
      discount_type,
      discount_value,
      discount_value_total,
      discount_reason,
      shipping_title,
      shipping_value,
      shipping_value_total,
      tax_type,
      tax_value,
      tax_value_total,
      tax_reason,
      orderno,
      quote_title,
      quote_notes,
      quotation_notes,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      rows,
      service_grand_sub_total,
      service_grand_total,
      order_paid,
      payment_details,
    } = this.state;
    console.log("items_list: ", rows);
    const validationError = this.validateAllRowsSaved();
    if (validationError) {
      this.setState({
        errorMessage: validationError,
        draft_button: false,
        disabled: false,
      });
      document
        .getElementById("editQuotations")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        this.setState({
          errorMessage: "",
          successMessage: "",
        });
      }, 4000);
      return;
    }
    if (rows && rows.length > 0) {
      this.setState({ draft_button: true, disabled: true });
      const servicesResponce = await saveOrderAsDraft(
        auth.getClientAccount(),
        auth.getClientToken(),
        discount_type,
        discount_value,
        discount_value_total,
        discount_reason,
        shipping_title,
        shipping_value,
        shipping_value_total,
        tax_type,
        tax_value,
        tax_value_total,
        tax_reason,
        orderno,
        quote_title,
        quote_notes,
        quotation_notes,
        payment_terms,
        currency,
        contact_person,
        contact_email,
        validity,
        JSON.stringify(rows),
        service_grand_sub_total,
        service_grand_total,
        order_paid,
        payment_details
      );
      console.log("saveOrderAsDraft: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while saving the order.",
          draft_button: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage:
            "Order saved successfully. Please wait, page will be refreshed automatically in a moment.",
          tableLoader: false,
          draft_button: false,
          disabled: false,
        });
        setTimeout(() => {
          window.location.href =
            APP_URL +
            auth.getResellerRegToken() +
            "/client/EditSalesOrder/" +
            orderno; // Replace with your URL
        }, 3000);
      } else {
        this.setState({
          errorMessage: "There is some error while saving the order.",
          draft_button: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Please add order lines to proceed.",
        draft_button: false,
        disabled: false,
      });
    }
    document
      .getElementById("editQuotations")
      ?.scrollIntoView({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      this.setState({
        errorMessage: "",
        successMessage: "",
      });
    }, 4000);
  };

  send_to_customer = async () => {
    const {
      discount_type,
      discount_value,
      discount_value_total,
      discount_reason,
      shipping_title,
      shipping_value,
      shipping_value_total,
      tax_type,
      tax_value,
      tax_value_total,
      tax_reason,
      orderno,
      quote_title,
      quote_notes,
      quotation_notes,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      rows,
      service_grand_sub_total,
      service_grand_total,
      order_paid,
      payment_details,
    } = this.state;
    console.log("items_list: ", rows);
    const validationError = this.validateAllRowsSaved();
    if (validationError) {
      this.setState({
        errorMessage: validationError,
        draft_button: false,
        disabled: false,
      });
      document
        .getElementById("editQuotations")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        this.setState({
          errorMessage: "",
          successMessage: "",
        });
      }, 4000);
      return;
    }
    if (rows && rows.length > 0) {
      this.setState({ send_client_button: true, disabled: true });
      const servicesResponce = await saveOrderAndSent(
        auth.getClientAccount(),
        auth.getClientToken(),
        discount_type,
        discount_value,
        discount_value_total,
        discount_reason,
        shipping_title,
        shipping_value,
        shipping_value_total,
        tax_type,
        tax_value,
        tax_value_total,
        tax_reason,
        orderno,
        quote_title,
        quote_notes,
        quotation_notes,
        payment_terms,
        currency,
        contact_person,
        contact_email,
        validity,
        JSON.stringify(rows),
        service_grand_sub_total,
        service_grand_total,
        order_paid,
        payment_details
      );
      console.log("saveOrderAndSent: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while saving the order.",
          send_client_button: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage:
            "Order created successfully. Please wait, page will be refreshed automatically.",
          tableLoader: false,
          send_client_button: false,
          disabled: false,
        });
        setTimeout(() => {
          window.location.href =
            APP_URL +
            auth.getResellerRegToken() +
            "/client/EditSalesOrder/" +
            orderno; // Replace with your URL
        }, 3000);
      } else {
        this.setState({
          errorMessage: "There is some error while saving the order.",
          send_client_button: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Please add order lines to proceed.",
        draft_button: false,
        disabled: false,
      });
    }
    document
      .getElementById("editQuotations")
      ?.scrollIntoView({ top: 0, behavior: "smooth" });
    window.$("#modalQuotationConfirmation").modal("hide");
    setTimeout(() => {
      this.setState({
        errorMessage: "",
        successMessage: "",
      });
    }, 4000);
  };

  toggleEditMode = (rowId) => {
    this.getServices();
    this.setState((prevState) => {
      const newEditingRows = new Set(prevState.editingRows);
      if (newEditingRows.has(rowId)) {
        newEditingRows.delete(rowId);
      } else {
        newEditingRows.add(rowId);
      }
      return { editingRows: newEditingRows };
    });
  };

  toggleEditModeCustom = (rowId) => {
    const { rows, rows_custom_temp } = this.state;

    const existingTempRow = rows_custom_temp.find(
      (row) => row.temp_id === rowId
    );
    if (!existingTempRow) {
      const rowToEdit = rows.find((row) => row.id === rowId);
      if (rowToEdit) {
        const newTempRow = {
          temp_id: rowId,
          temp_data: {
            temp_inner_id: rowToEdit.data.id,
            temp_inner_sku: rowToEdit.data.sku,
            temp_inner_name: rowToEdit.data.name,
            temp_inner_description: rowToEdit.data.description,
            temp_inner_quantity: rowToEdit.data.quantity,
            temp_inner_unit: rowToEdit.data.unit,
            temp_inner_price: rowToEdit.data.price,
            temp_inner_total: rowToEdit.data.total,
            temp_inner_type: "custom",
          },
        };
        this.setState((prevState) => ({
          rows_custom_temp: [...prevState.rows_custom_temp, newTempRow],
        }));
      }
    }

    // Toggle edit mode
    this.setState((prevState) => {
      const editableRows = new Set(prevState.editableRows);
      if (editableRows.has(rowId)) {
        editableRows.delete(rowId);
      } else {
        editableRows.add(rowId);
      }
      return { editableRows };
    });
  };

  handleEditSection = (rowId) => {
    const row = this.state.rows.find((row) => row.id === rowId);
    this.setState({
      editingSection: rowId,
      select_name: row.data.name,
    });
  };

  updateSection = (rowId) => {
    const { select_name, rows } = this.state;
    if (select_name !== "") {
      const updatedRows = rows.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            data: {
              ...row.data,
              name: select_name,
            },
          };
        }
        return row;
      });

      this.setState({
        rows: updatedRows,
        editingSection: null,
        select_name: "",
      });
    }
  };

  handleConfirmation = () => {
    window.$("#modalQuotationConfirmation").modal("show");
  };

  handleEditorChange = (event, editor) => {
    const content = editor.getData();
    this.setState({
      quote_notes: content,
    });
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  saveAllListItem = async () => {
    const { rows, rows_service_temp, rows_custom_temp } = this.state;
    let updatedRows = [...rows];
    let savedRowIds = new Set([...this.state.savedRows]);
    let promises = [];

    const originalEditingSection = this.state.editingSection;

    for (const row of rows) {
      if (!this.state.savedRows.has(row.id)) {
        if (row.data.type === "service") {
          const serviceTempRow = rows_service_temp.find(
            (temp) => temp.temp_serv_id === row.id
          );

          if (serviceTempRow) {
            const selectedServiceId = serviceTempRow.temp_serv_data?.temp_serv_inner_id;

            if (selectedServiceId) {
              const promise = this.getServiceWithId(selectedServiceId).then(selectedServiceRow => {
                if (selectedServiceRow && selectedServiceRow.length > 0) {
                  const qty = serviceTempRow.temp_serv_data.temp_serv_inner_quantity || 1;
                  const unit = serviceTempRow.temp_serv_data.temp_serv_inner_unit || "";

                  const rowIndex = updatedRows.findIndex(r => r.id === row.id);
                  if (rowIndex !== -1) {
                    updatedRows[rowIndex] = {
                      ...updatedRows[rowIndex],
                      data: {
                        id: selectedServiceRow[0],
                        sku: selectedServiceRow[9] || "",
                        name: serviceTempRow.temp_serv_data.temp_serv_inner_name,
                        quantity: qty,
                        unit: unit,
                        price: selectedServiceRow[8]
                          ? Number(selectedServiceRow[8]).toFixed(this.getDecimalPoint())
                          : (0).toFixed(this.getDecimalPoint()),
                        total: Number(selectedServiceRow[8] * qty).toFixed(
                          this.getBillingDecimalPoint()
                        ),
                        type: "service",
                      },
                    };
                    savedRowIds.add(row.id);
                  }
                }
              });

              promises.push(promise);
            }
          }
        }

        else if (row.data.type === "section") {

          const isNewSection = !row.data.name || row.data.name === "";
          let sectionName = row.data.name || "";
          if (this.state.editingSection === row.id || isNewSection) {
            const sectionInputs = document.querySelectorAll("[name='select_name']");
            for (let input of sectionInputs) {
              if (input.closest('tr') && input.closest('tr').contains(document.querySelector(`[data-rbd-draggable-id="${row.id}-1"]`))) {
                sectionName = input.value;
                break;
              }
            }
          }

          if (sectionName !== "") {
            const rowIndex = updatedRows.findIndex(r => r.id === row.id);
            if (rowIndex !== -1) {
              updatedRows[rowIndex] = {
                ...updatedRows[rowIndex],
                data: {
                  id: "",
                  name: sectionName,
                  quantity: "",
                  unit: "",
                  price: "",
                  total: "",
                  type: "section",
                },
              };
              savedRowIds.add(row.id);
            }
          }
        }

        else if (row.data.type === "custom") {

          const customTempRow = rows_custom_temp.find(
            (temp) => temp.temp_id === row.id
          );

          let customData = {
            id: "",
            sku: "",
            name: "",
            description: "",
            quantity: "1",
            unit: "",
            price: "0",
            total: "0",
            type: "custom"
          };

          if (customTempRow) {
            customData = {
              id: customTempRow.temp_data.temp_inner_id || row.data.id || "",
              sku: customTempRow.temp_data.temp_inner_sku || row.data.sku || "",
              name: customTempRow.temp_data.temp_inner_name || row.data.name || "",
              description: customTempRow.temp_data.temp_inner_description || row.data.description || "",
              quantity: customTempRow.temp_data.temp_inner_quantity || row.data.quantity || "1",
              unit: customTempRow.temp_data.temp_inner_unit || row.data.unit || "",
              price: customTempRow.temp_data.temp_inner_price || row.data.price || "0",
              type: "custom"
            };
          }
          else {
            const rowElement = document.querySelector(`[data-rbd-draggable-id="${row.id}-1"]`);
            if (rowElement) {
              const idInput = rowElement.querySelector('[name="select_custom_id"]');
              const skuInput = rowElement.querySelector('[name="select_custom_sku"]');
              const nameInput = rowElement.querySelector('[name="select_custom_name"]');
              const descInput = rowElement.querySelector('[name="select_custom_description"]');
              const qtyInput = rowElement.querySelector('[name="select_custom_quantity"]');
              const unitInput = rowElement.querySelector('[name="select_custom_unit"]');
              const priceInput = rowElement.querySelector('[name="select_custom_price"]');

              customData = {
                id: idInput ? idInput.value : row.data.id || "",
                sku: skuInput ? skuInput.value : row.data.sku || "",
                name: nameInput ? nameInput.value : row.data.name || "",
                description: descInput ? descInput.value : row.data.description || "",
                quantity: qtyInput ? qtyInput.value : row.data.quantity || "1",
                unit: unitInput ? unitInput.value : row.data.unit || "",
                price: priceInput ? priceInput.value : row.data.price || "0",
                type: "custom"
              };
            }
          }

          const qty = parseFloat(customData.quantity) || 0;
          const price = parseFloat(customData.price) || 0;
          customData.total = (qty * price).toFixed(this.getBillingDecimalPoint());

          if (customData.price && parseFloat(customData.price) > 0) {
            customData.price = Number(customData.price).toFixed(this.getDecimalPoint());
          } else {
            customData.price = (0).toFixed(this.getDecimalPoint());
          }
          if (customData.name !== "") {
            const rowIndex = updatedRows.findIndex(r => r.id === row.id);
            if (rowIndex !== -1) {
              updatedRows[rowIndex] = {
                ...updatedRows[rowIndex],
                data: customData
              };
              savedRowIds.add(row.id);
            }
          }
        }
      }
    }

    await Promise.all(promises);

    this.setState({
      rows: updatedRows,
      savedRows: savedRowIds,
      rows_custom_temp: rows_custom_temp.filter(row => !savedRowIds.has(row.temp_id)),
      editingRows: new Set(),
      editableRows: new Set(),
      editingSection: null
    }, () => {
      this.calculateTotalRate();
      if (this.state.discount_type) {
        this.applyDiscount();
      }
      if (this.state.tax_type) {
        this.applyTax();
      }
    });
  };


  getAvailableServices = (currentRowId) => {
    const { all_services, rows_service_temp } = this.state;
    if (!all_services || !rows_service_temp) {
      return [];
    }
    const selectedServiceIds = rows_service_temp
      .filter(row => row.temp_serv_id !== currentRowId)
      .map(row => row.temp_serv_data.temp_serv_inner_id);
    const availableServices = all_services
      .filter(service => !selectedServiceIds.includes(service[0]))
      .map(service => ({
        value: service[0],
        label: service[1] || service[0]
      }));

    return availableServices;
  };


  showDetails = async (service_id) => {
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getServiceDetails(
      auth.getClientAccount(),
      auth.getClientToken(),
      service_id
    );
    console.log("getAgentService: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        unsigned_agreement_title:
          servicesResponce.data.assigned_agreement_title,
        tableLoader: false,
        tableLoaderListing: false,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    window.$("#modalFormDetailDash").modal("show");
  };

  modalHide = () => {
    window.$("#modalFormDetailDash").modal("hide");
  };

  formatAsCurrencys = (amount) => {
    console.log("amount: ", typeof amount);
    //amount = this.formatRate(amount);
    let decimal_point = 2;
    if (
      this.props.quotationSettings[0]?.decimal_point &&
      this.props.quotationSettings[0]?.decimal_point !== ""
    ) {
      decimal_point = this.props.quotationSettings[0]?.decimal_point;
    }
    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }

    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimal_point,
      maximumFractionDigits: decimal_point,
    });
  };

  downloadDeck = async (d_f_name) => {
    if (d_f_name !== "") {
      this.setState({ downloadLoader: true });
      var url = ADMIN_URL + "files_data/logos/" + d_f_name;
      const parts = url.split("/");
      let file_name = d_f_name;

      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
          this.setState({ downloadLoader: false });
        })
        .catch((error) => {
          this.setState({ downloadLoader: false });
          console.error("Fetch error:", error);
        });
    }
  };

  stopAndShowBundle = async (bundleId) => {
    window.$("#modalFormDetail").modal("hide");
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    const fetchToken = async () => {
      try {
        const response = await fetch(
          API_URL + "agents/editor_token_url_clients",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getClientAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content rfq_Block" id="editQuotations">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
            this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
            this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory(
                            "/" + auth.getResellerRegToken() + "/client/"
                          )
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>

                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory(
                            "/" +
                            auth.getResellerRegToken() +
                            "/client/SalesOrders"
                          )
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Sales Orders
                      </Link>
                      <Typography color="textPrimary" className="">
                        Add New Sales Order
                      </Typography>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                {/* Table 5th Col Start */}
                <div className="col-xxl-12">
                  {this.state.errorMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="card card-bordered">
                    <div className="card-inner">
                      <div className="invoice">
                        {/*<div className="invoice-action">
                          <a
                            className="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary"
                            href="#"
                            target="_blank"
                          >
                            <em className="icon ni ni-printer-fill"></em>
                          </a>
                        </div>*/}
                        <div className="invoice-wrap">
                          <div className="invoice-head">
                            <div className="invoice-contact">
                              <span className="overline-title text-dark">
                                Order For
                              </span>
                              <div className="invoice-contact-info">
                                <h4 className="title text-dark">
                                  {this.state.client_name} -{" "}
                                  {this.state.client_company}
                                </h4>
                                <ul className="list-plain">
                                  {this.state.client_address &&
                                    this.state.client_address !== "" ? (
                                    <li>
                                      <em className="icon ni ni-map-pin-fill"></em>
                                      <span className="text-dark">
                                        {this.state.client_address &&
                                          this.state.client_address !== ""
                                          ? this.state.client_address
                                          : ""}{" "}
                                        {this.state.client_address2 &&
                                          this.state.client_address2 !== ""
                                          ? this.state.client_address2
                                          : ""}
                                        <br />
                                        {this.state.client_city &&
                                          this.state.client_city !== ""
                                          ? this.state.client_city
                                          : ""}{" "}
                                        {this.state.client_state &&
                                          this.state.client_state !== ""
                                          ? this.state.client_state
                                          : ""}{" "}
                                        {this.state.client_zip &&
                                          this.state.client_zip !== ""
                                          ? this.state.client_zip
                                          : ""}{" "}
                                        {this.state.client_country &&
                                          this.state.client_country !== ""
                                          ? this.state.client_country
                                          : ""}
                                      </span>
                                    </li>
                                  ) : null}
                                  <li>
                                    <em className="icon ni ni-call-fill"></em>
                                    <span className="text-dark">
                                      {this.state.client_phone &&
                                        this.state.client_phone !== ""
                                        ? this.formatPhoneNumber(
                                          this.state.client_phone
                                        )
                                        : "---"}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="invoice-desc right_invoice">
                              <h3 className="title">
                                Order{" "}
                                {this.state.quote_status !== "" &&
                                  this.state.quote_status === "completed" ? (
                                  <span class="badge badge-outline-success">
                                    Completed
                                  </span>
                                ) : null}
                                {this.state.quote_status !== "" &&
                                  this.state.quote_status === "cancelled" ? (
                                  <span class="badge badge-outline-danger">
                                    Cancelled / Rejected
                                  </span>
                                ) : null}
                              </h3>
                              <ul className="list-plain">
                                <li className="invoice-id border-bottom">
                                  <span className="text-dark">Order No</span>:
                                  <span className="text-dark fw-bold">
                                    {this.state.orderno}
                                  </span>
                                </li>
                                <li className="invoice-date border-bottom">
                                  <span className="text-dark">Issue Date</span>:
                                  <span className="text-dark">
                                    {this.format_date(new Date())}
                                  </span>
                                </li>
                                <li className="invoice-date border-bottom">
                                  <span className="text-dark">From</span>:
                                  <span className="text-dark">
                                    {this.state.reseller_company}
                                  </span>
                                </li>
                                <li className="invoice-date border-bottom">
                                  <span className="text-dark">Company</span>:
                                  <span className="text-dark">
                                    {this.state.client_company}
                                  </span>
                                </li>

                                {this.state.payment_terms !== "" ? (
                                  <li className="invoice-date border-bottom">
                                    <span className="text-dark">
                                      Terms of Payment
                                    </span>
                                    :
                                    <span className="text-dark">
                                      {this.state.payment_terms}
                                    </span>
                                  </li>
                                ) : null}
                                {this.state.currency !== "" ? (
                                  <li className="invoice-date border-bottom">
                                    <span className="text-dark">Currency</span>:
                                    <span className="text-dark">
                                      {this.state.currency}
                                    </span>
                                  </li>
                                ) : null}
                                {this.state.contact_person !== "" ? (
                                  <li className="invoice-date border-bottom">
                                    <span className="text-dark">
                                      Contact Person{" "}
                                    </span>
                                    :
                                    <span className="text-dark">
                                      {this.state.contact_person}
                                    </span>
                                  </li>
                                ) : null}
                                {this.state.contact_email !== "" ? (
                                  <li className="invoice-date border-bottom">
                                    <span className="text-dark">Email</span>:
                                    <span className="text-dark">
                                      {this.state.contact_email}
                                    </span>
                                  </li>
                                ) : null}

                                {this.state.agreementTitle &&
                                  this.state.agreementTitle !== "" ? (
                                  <li className="invoice-date">
                                    <span className="text-dark">
                                      Order Agreement
                                    </span>
                                    :
                                    <span className="text-dark">
                                      {this.state.agreementTitle}
                                    </span>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="nav nav-tabs mt-4">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabItem5"
                          >
                            <em className="icon ni ni-list-ol"></em>
                            <span>Order Lines</span>
                          </a>
                        </li>
                        {/*<li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabItem6"
                          >
                            <em className="icon ni ni-list-ol"></em>
                            <span>Optional Products</span>
                          </a>
                                </li>*/}
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabItem7"
                          >
                            <em className="icon ni ni-info"></em>
                            <span>Other Info</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabItemPayment"
                          //onClick={() => this.getPaymentMethod(this.state.orderno)}
                          >
                            <em class="icon ni ni-sign-usdc"></em>
                            <span>Payments</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tabItem5">
                          <div className="row g-gs">
                            <div className="col-xxl-12 inner_content">
                              <div className="invoice">
                                <div className="invoice-wrap">
                                  <div className="invoice-bills">
                                    <div className=" genericTable">
                                      <DragDropContext
                                        onDragEnd={this.handleDragEnd}
                                      >
                                        <Droppable droppableId="droppable-1">
                                          {(provided) => (
                                            <table
                                              ref={provided.innerRef}
                                              {...provided.droppableProps}
                                              className="table table-striped"
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="w-5">
                                                    <em className="icon ni ni-move"></em>
                                                  </th>
                                                  <th className="w-10">
                                                    ITEM ID
                                                  </th>
                                                  <th className="w-10">SKU</th>
                                                  <th className="w-25">
                                                    PRODUCT NAME
                                                  </th>
                                                  <th className="w-10">QTY</th>
                                                  <th className="w-10">UNIT</th>
                                                  <th className="w-10">
                                                    PRICE($)
                                                  </th>
                                                  <th className="w-10">
                                                    TOTAL($)
                                                  </th>
                                                  <th className="w-10">
                                                    {this.state.saveButtonEnabled ? (
                                                      <span
                                                        className="badge badge-outline-success"
                                                        onClick={() =>
                                                          this.saveAllListItem()
                                                        }
                                                        style={{
                                                          cursor:
                                                            "pointer",
                                                          paddingTop:
                                                            "3px",
                                                          paddingBottom:
                                                            "3px",
                                                          paddingLeft:
                                                            "7px",
                                                          fontSize:
                                                            "13px",
                                                          fontWeight: "600"
                                                        }}
                                                      >
                                                        <em className="icon ni ni-save"></em>
                                                        {" "}Save All
                                                      </span>
                                                    ) : null}

                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.rows.map(
                                                  (row, index) => (
                                                    <Draggable
                                                      key={row.id}
                                                      draggableId={`${row.id}-1`}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <tr
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          {row.data.type &&
                                                            row.data.type ===
                                                            "service" ? (
                                                            <>
                                                              <td>
                                                                <em className="icon ni ni-move"></em>
                                                              </td>
                                                              <td>
                                                                {row.data.id &&
                                                                  row.data.id !==
                                                                  ""
                                                                  ? row.data.id
                                                                  : "---"}
                                                              </td>
                                                              <td>
                                                                {row.data.sku &&
                                                                  row.data.sku !==
                                                                  ""
                                                                  ? row.data.sku
                                                                  : "---"}
                                                              </td>
                                                              <td>
                                                                {row.data.name !== "" ? (
                                                                  !this.state.editingRows.has(row.id) ? (
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                      {row.data.id && (
                                                                        <em
                                                                          className="icon-show icon ni ni-info mr-1"
                                                                          style={{ cursor: "pointer", fontSize: "1.1rem" }}
                                                                          onClick={() => this.showDetails(row.data.id)}
                                                                        ></em>
                                                                      )}
                                                                      <span>
                                                                        {row.data.name && row.data.name !== ""
                                                                          ? row.data.name
                                                                          : "---"}
                                                                      </span>
                                                                    </div>

                                                                  ) : (
                                                                    <Select
                                                                      options={this.getAvailableServices(row.id)}
                                                                      name={`select_a_service_${index}`}
                                                                      placeholder="Select product / service"
                                                                      value={
                                                                        this
                                                                          .state[
                                                                        `select_a_service_${index}`
                                                                        ]
                                                                      }
                                                                      autoComplete="off"
                                                                      emptyMessage="Product not found"
                                                                      onChange={(
                                                                        select_a_service,
                                                                        {
                                                                          action,
                                                                        }
                                                                      ) =>
                                                                        this.handleChangeSearchNew(
                                                                          select_a_service,
                                                                          {
                                                                            action,
                                                                            row,
                                                                            index,
                                                                          }
                                                                        )
                                                                      }
                                                                      isClearable
                                                                      isSearchable
                                                                      components={
                                                                        animatedComponents
                                                                      }
                                                                    />
                                                                  )
                                                                ) : (
                                                                  <Select
                                                                    options={this.getAvailableServices(row.id)}
                                                                    name={`select_a_service_${index}`}
                                                                    placeholder="Select product / service"
                                                                    value={
                                                                      this
                                                                        .state[
                                                                      `select_a_service_${index}`
                                                                      ]
                                                                    }
                                                                    autoComplete="off"
                                                                    emptyMessage="Product not found"
                                                                    onChange={(
                                                                      select_a_service,
                                                                      { action }
                                                                    ) =>
                                                                      this.handleChangeSearchNew(
                                                                        select_a_service,
                                                                        {
                                                                          action,
                                                                          row,
                                                                          index,
                                                                        }
                                                                      )
                                                                    }
                                                                    isClearable
                                                                    isSearchable
                                                                    components={
                                                                      animatedComponents
                                                                    }
                                                                  />
                                                                )}
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="quantity"
                                                                    name="quantity"
                                                                    onChange={this.handleChangeServices(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .quantity
                                                                    }
                                                                    maxLength={
                                                                      6
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                    disabled={!this.state.editingRows.has(row.id) && row.data.name !== ""}
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="unit"
                                                                    name="unit"
                                                                    onChange={this.handleChangeServices(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .unit
                                                                    }
                                                                    maxLength={
                                                                      20
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^a-zA-Z0-9 ]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                    disabled={!this.state.editingRows.has(row.id) && row.data.name !== ""}
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="price"
                                                                    name="price"
                                                                    onChange={this.handleChangeServices(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    value={Number(
                                                                      row.data
                                                                        .price
                                                                    ).toFixed(
                                                                      this.getBillingDecimalPoint()
                                                                    )}
                                                                    maxLength={
                                                                      10
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9.]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    readOnly
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                {this.formatAsCurrency(
                                                                  Number(
                                                                    row.data
                                                                      .total
                                                                  )
                                                                )}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "end",
                                                                }}
                                                              >
                                                                {/* Save button only for new entries */}
                                                                {row.data
                                                                  .name ===
                                                                  "" &&
                                                                  !this.state.editingRows.has(
                                                                    row.id
                                                                  ) ? (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() =>
                                                                      this.addNewService(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                ) : null}
                                                                {/* Edit button for saved entries that aren't being edited */}
                                                                {row.data
                                                                  .name !==
                                                                  "" &&
                                                                  !this.state.editingRows.has(
                                                                    row.id
                                                                  ) ? (
                                                                  <span
                                                                    className="badge badge-outline-dark mr-1"
                                                                    onClick={() =>
                                                                      this.toggleEditMode(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    {this.state
                                                                      .quote_status !==
                                                                      "completed" &&
                                                                      this.state
                                                                        .quote_status !==
                                                                      "cancelled" ? (
                                                                      <em className="icon ni ni-edit"></em>
                                                                    ) : null}
                                                                  </span>
                                                                ) : null}
                                                                {/* Save button for entries being edited */}
                                                                {this.state.editingRows.has(
                                                                  row.id
                                                                ) ? (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() => {
                                                                      this.addNewService(
                                                                        row.id
                                                                      );
                                                                      this.toggleEditMode(
                                                                        row.id
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                ) : null}
                                                                {/* Delete button */}
                                                                {this.state
                                                                  .quote_status !==
                                                                  "completed" &&
                                                                  this.state
                                                                    .quote_status !==
                                                                  "cancelled" ? (
                                                                  <span
                                                                    className="badge badge-outline-danger"
                                                                    onClick={() =>
                                                                      this.removeRow(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-trash"></em>
                                                                  </span>
                                                                ) : null}
                                                              </td>
                                                            </>
                                                          ) : row.data.type ===
                                                            "section" ? (
                                                            <>
                                                              <td>
                                                                <em className="icon ni ni-move"></em>
                                                              </td>
                                                              <td colSpan={7}>
                                                                {row.data
                                                                  .name &&
                                                                  row.data
                                                                    .name !==
                                                                  "" &&
                                                                  this.state
                                                                    .editingSection !==
                                                                  row.id ? (
                                                                  <div className="d-flex align-items-center">
                                                                    <span>
                                                                      {
                                                                        row.data
                                                                          .name
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                ) : (
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="select_name"
                                                                      name="select_name"
                                                                      onChange={this.handleChangeSection(
                                                                        row.id
                                                                      )}
                                                                      placeholder=""
                                                                      autoComplete="off"
                                                                      defaultValue={
                                                                        row.data
                                                                          .name
                                                                      }
                                                                      maxLength={
                                                                        100
                                                                      }
                                                                      required
                                                                    />
                                                                  </div>
                                                                )}
                                                              </td>

                                                              <td
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "end",
                                                                }}
                                                              >
                                                                {row.data
                                                                  .name &&
                                                                  row.data
                                                                    .name !==
                                                                  "" &&
                                                                  this.state
                                                                    .editingSection !==
                                                                  row.id ? (
                                                                  <span
                                                                    className="badge badge-outline-dark mr-1"
                                                                    onClick={() =>
                                                                      this.handleEditSection(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    {this.state
                                                                      .quote_status !==
                                                                      "completed" &&
                                                                      this.state
                                                                        .quote_status !==
                                                                      "cancelled" ? (
                                                                      <em className="icon ni ni-edit"></em>
                                                                    ) : null}
                                                                  </span>
                                                                ) : (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() =>
                                                                      this.state
                                                                        .editingSection
                                                                        ? this.updateSection(
                                                                          row.id
                                                                        )
                                                                        : this.addNewSection(
                                                                          row.id
                                                                        )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      padding:
                                                                        "3px 7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                )}
                                                                {this.state
                                                                  .quote_status !==
                                                                  "completed" &&
                                                                  this.state
                                                                    .quote_status !==
                                                                  "cancelled" && (
                                                                    <span
                                                                      className="badge badge-outline-danger"
                                                                      onClick={() =>
                                                                        this.removeRow(
                                                                          row.id
                                                                        )
                                                                      }
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                        padding:
                                                                          "3px 7px",
                                                                        fontSize:
                                                                          "18px",
                                                                      }}
                                                                    >
                                                                      <em className="icon ni ni-trash"></em>
                                                                    </span>
                                                                  )}
                                                              </td>
                                                            </>
                                                          ) : row.data.type ===
                                                            "custom" ? (
                                                            <>
                                                              <td>
                                                                <em className="icon ni ni-move"></em>
                                                              </td>
                                                              <td>
                                                                {row.data.id &&
                                                                  row.data.id !==
                                                                  "" ? (
                                                                  row.data.id
                                                                ) : (
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="select_custom_id"
                                                                      name="select_custom_id"
                                                                      onChange={this.handleChangeCustom(
                                                                        row.id
                                                                      )}
                                                                      placeholder=""
                                                                      autoComplete={
                                                                        false
                                                                      }
                                                                      defaultValue={
                                                                        row.data
                                                                          .id
                                                                      }
                                                                      onInput={(
                                                                        e
                                                                      ) => {
                                                                        e.target.value =
                                                                          e.target.value.replace(
                                                                            /[^0-9]/gi,
                                                                            ""
                                                                          );
                                                                      }}
                                                                      maxLength={
                                                                        7
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "120px",
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                      required
                                                                    />
                                                                  </div>
                                                                )}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .sku !== "" &&
                                                                  !this.state.editableRows.has(
                                                                    row.id
                                                                  ) ? (
                                                                  row.data
                                                                    .sku ||
                                                                  "---"
                                                                ) : (
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="select_custom_sku"
                                                                      name="select_custom_sku"
                                                                      onChange={this.handleChangeCustom(
                                                                        row.id
                                                                      )}
                                                                      placeholder=""
                                                                      autoComplete={
                                                                        false
                                                                      }
                                                                      value={
                                                                        this.state.rows_custom_temp.find(
                                                                          (r) =>
                                                                            r.temp_id ===
                                                                            row.id
                                                                        )
                                                                          ?.temp_data
                                                                          .temp_inner_sku ||
                                                                        ""
                                                                      }
                                                                      maxLength={
                                                                        25
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "120px",
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                      required
                                                                    />
                                                                  </div>
                                                                )}
                                                              </td>

                                                              <td>
                                                                {row.data
                                                                  .name !==
                                                                  "" &&
                                                                  !this.state.editableRows.has(
                                                                    row.id
                                                                  ) ? (
                                                                  row.data
                                                                    .name ||
                                                                  "---"
                                                                ) : (
                                                                  <div className="form-control-wrap mb-2">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="select_custom_name"
                                                                      name="select_custom_name"
                                                                      onChange={this.handleChangeCustom(
                                                                        row.id
                                                                      )}
                                                                      placeholder=""
                                                                      autoComplete={
                                                                        false
                                                                      }
                                                                      value={
                                                                        this.state.rows_custom_temp.find(
                                                                          (r) =>
                                                                            r.temp_id ===
                                                                            row.id
                                                                        )
                                                                          ?.temp_data
                                                                          .temp_inner_name ||
                                                                        ""
                                                                      }
                                                                      maxLength={
                                                                        100
                                                                      }
                                                                      required
                                                                    />
                                                                  </div>
                                                                )}
                                                                {(!row.data
                                                                  .name ||
                                                                  this.state.editableRows.has(
                                                                    row.id
                                                                  ) ||
                                                                  row.data
                                                                    .description) && (
                                                                    <>
                                                                      {row.data
                                                                        .description !==
                                                                        "" &&
                                                                        !this.state.editableRows.has(
                                                                          row.id
                                                                        ) ? (
                                                                        <div className="text-muted small">
                                                                          {
                                                                            row
                                                                              .data
                                                                              .description
                                                                          }
                                                                        </div>
                                                                      ) : (
                                                                        <div
                                                                          className="form-control-wrap"
                                                                          style={{
                                                                            width:
                                                                              "100%",
                                                                          }}
                                                                        >
                                                                          <textarea
                                                                            className="form-control"
                                                                            id="select_custom_description"
                                                                            name="select_custom_description"
                                                                            onChange={this.handleChangeCustom(
                                                                              row.id
                                                                            )}
                                                                            placeholder="Enter description"
                                                                            autoComplete="off"
                                                                            value={
                                                                              this.state.rows_custom_temp.find(
                                                                                (
                                                                                  r
                                                                                ) =>
                                                                                  r.temp_id ===
                                                                                  row.id
                                                                              )
                                                                                ?.temp_data
                                                                                .temp_inner_description ||
                                                                              ""
                                                                            }
                                                                            maxLength={
                                                                              200
                                                                            }
                                                                            style={{
                                                                              width:
                                                                                "100%",
                                                                              height:
                                                                                "80px",
                                                                              resize:
                                                                                "none",
                                                                              overflow:
                                                                                "hidden",
                                                                              lineHeight:
                                                                                "1.5",
                                                                              padding:
                                                                                "0.375rem 0.75rem",
                                                                              fontSize:
                                                                                "0.8rem",
                                                                              border:
                                                                                "1px solid #ced4da",
                                                                              borderRadius:
                                                                                "0.25rem",
                                                                              minHeight:
                                                                                "38px",
                                                                            }}
                                                                            rows={
                                                                              1
                                                                            }
                                                                            onFocus={(
                                                                              e
                                                                            ) => {
                                                                              e.target.style.height =
                                                                                "80px";
                                                                              e.target.style.height = `${e.target.scrollHeight}px`;
                                                                            }}
                                                                            onBlur={(
                                                                              e
                                                                            ) => {
                                                                              if (
                                                                                !e
                                                                                  .target
                                                                                  .value
                                                                              ) {
                                                                                e.target.style.height =
                                                                                  "80px";
                                                                              }
                                                                            }}
                                                                            onInput={(
                                                                              e
                                                                            ) => {
                                                                              e.target.style.height =
                                                                                "80px";
                                                                              e.target.style.height = `${e.target.scrollHeight}px`;
                                                                            }}
                                                                            required
                                                                          />
                                                                        </div>
                                                                      )}
                                                                    </>
                                                                  )}
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="select_custom_quantity"
                                                                    name="select_custom_quantity"
                                                                    onChange={this.handleChangeCustom(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .quantity
                                                                    }
                                                                    maxLength={
                                                                      6
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                    disabled={!this.state.editableRows.has(row.id) && row.data.name !== ""}
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="select_custom_unit"
                                                                    name="select_custom_unit"
                                                                    onChange={this.handleChangeCustom(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .unit
                                                                    }
                                                                    maxLength={
                                                                      20
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^a-zA-Z0-9 ]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                    disabled={!this.state.editableRows.has(row.id) && row.data.name !== ""}
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="select_custom_price"
                                                                    name="select_custom_price"
                                                                    onChange={this.handleChangeCustom(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={Number(
                                                                      row.data
                                                                        .price
                                                                    ).toFixed(
                                                                      this.getBillingDecimalPoint()
                                                                    )}
                                                                    maxLength={
                                                                      10
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9.]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                    disabled={!this.state.editableRows.has(row.id) && row.data.name !== ""}
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                {this.formatAsCurrency(
                                                                  Number(
                                                                    row.data
                                                                      .total
                                                                  )
                                                                )}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "end",
                                                                }}
                                                              >
                                                                {row.data
                                                                  .name !==
                                                                  "" ? (
                                                                  <>
                                                                    {this.state.editableRows.has(
                                                                      row.id
                                                                    ) ? (
                                                                      <span
                                                                        className="badge badge-outline-primary mr-1"
                                                                        onClick={() =>
                                                                          this.addNewCustomService(
                                                                            row.id
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                          paddingTop:
                                                                            "3px",
                                                                          paddingBottom:
                                                                            "3px",
                                                                          paddingLeft:
                                                                            "7px",
                                                                          fontSize:
                                                                            "18px",
                                                                        }}
                                                                      >
                                                                        <em className="icon ni ni-save"></em>
                                                                      </span>
                                                                    ) : (
                                                                      <span
                                                                        className="badge badge-outline-dark mr-1"
                                                                        onClick={() =>
                                                                          this.toggleEditModeCustom(
                                                                            row.id
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                          paddingTop:
                                                                            "3px",
                                                                          paddingBottom:
                                                                            "3px",
                                                                          paddingLeft:
                                                                            "7px",
                                                                          fontSize:
                                                                            "18px",
                                                                        }}
                                                                      >
                                                                        {this
                                                                          .state
                                                                          .quote_status !==
                                                                          "completed" &&
                                                                          this
                                                                            .state
                                                                            .quote_status !==
                                                                          "cancelled" ? (
                                                                          <em className="icon ni ni-edit"></em>
                                                                        ) : null}
                                                                      </span>
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() =>
                                                                      this.addNewCustomService(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                )}
                                                                {this.state
                                                                  .quote_status !==
                                                                  "completed" &&
                                                                  this.state
                                                                    .quote_status !==
                                                                  "cancelled" ? (
                                                                  <span
                                                                    className="badge badge-outline-danger"
                                                                    onClick={() =>
                                                                      this.removeRow(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-trash"></em>
                                                                  </span>
                                                                ) : null}
                                                              </td>
                                                            </>
                                                          ) : null}
                                                        </tr>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                              </tbody>
                                              <tfoot>
                                                <tr>
                                                  <td colSpan="6"></td>
                                                  <td colSpan="2">Subtotal</td>
                                                  <td>
                                                    {this.formatAsCurrency(
                                                      parseFloat(
                                                        this.state
                                                          .service_grand_sub_total
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td colSpan="6"></td>
                                                  <td colSpan="2">
                                                    {/*<a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.open_discount()
                                                      }
                                                      className="text-primary"
                                                    >
                                                      {this.state
                                                        .discount_value_total &&
                                                      this.state
                                                        .discount_value_total >
                                                        0 &&
                                                      this.state
                                                        .discount_value_total !==
                                                        "0.00"
                                                        ? "Edit discount"
                                                        : "Add discount"}
                                                    </a>
                                                    */}
                                                    {this.state
                                                      .discount_value_total &&
                                                      this.state
                                                        .discount_value_total >
                                                      0 &&
                                                      this.state
                                                        .discount_value_total !==
                                                      "0.00"
                                                      ? "Est. discount"
                                                      : "Est. discount"}
                                                  </td>
                                                  <td>
                                                    {this.formatAsCurrency(
                                                      Number(
                                                        this.state
                                                          .discount_value_total
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td colSpan="6"></td>
                                                  <td colSpan="2">
                                                    {/*<a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.open_shipping()
                                                      }
                                                      className="text-primary"
                                                    >
                                                      {this.state
                                                        .shipping_value_total &&
                                                      this.state
                                                        .shipping_value_total >
                                                        0 &&
                                                      this.state
                                                        .shipping_value_total !==
                                                        "0.00"
                                                        ? "Edit shipping or delivery"
                                                        : "Add shipping or delivery"}
                                                    </a>*/}
                                                    {this.state
                                                      .shipping_value_total &&
                                                      this.state
                                                        .shipping_value_total >
                                                      0 &&
                                                      this.state
                                                        .shipping_value_total !==
                                                      "0.00"
                                                      ? "Est. shipping or delivery"
                                                      : "Est. shipping or delivery"}
                                                  </td>
                                                  <td>
                                                    {this.formatAsCurrency(
                                                      Number(
                                                        this.state
                                                          .shipping_value_total
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td colSpan="6"></td>
                                                  <td colSpan="2">
                                                    {/*<a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.open_tax()
                                                      }
                                                      className="text-primary"
                                                    >
                                                      {this.state
                                                        .tax_value_total &&
                                                      this.state
                                                        .tax_value_total > 0 &&
                                                      this.state
                                                        .tax_value_total !==
                                                        "0.00"
                                                        ? "Edit estimated tax"
                                                        : "Estimated tax"}
                                                    </a>*/}
                                                    {this.state
                                                      .tax_value_total &&
                                                      this.state.tax_value_total >
                                                      0 &&
                                                      this.state
                                                        .tax_value_total !==
                                                      "0.00"
                                                      ? "Est tax"
                                                      : "Est. tax"}
                                                  </td>
                                                  <td>
                                                    {this.formatAsCurrency(
                                                      Number(
                                                        this.state
                                                          .tax_value_total
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td colSpan="6"></td>
                                                  <td colSpan="2">
                                                    Grand Total
                                                  </td>
                                                  <td>
                                                    {this.formatAsCurrency(
                                                      parseFloat(
                                                        this.state
                                                          .service_grand_total
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                              </tfoot>
                                            </table>
                                          )}
                                        </Droppable>
                                      </DragDropContext>

                                      <div className="nk-notes ff-italic fs-12px text-soft">
                                        {" "}
                                      </div>
                                    </div>
                                    <div className="col-xxl-12 mt-5">
                                      {this.state.quote_status !==
                                        "completed" &&
                                        this.state.quote_status !==
                                        "cancelled" ? (
                                        <>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addRow}
                                            //onClick={this.openServices}
                                            className="btn btn-dim btn-outline-primary mr-1"
                                          >
                                            Add a product
                                          </a>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addRowSec}
                                            className="btn btn-dim btn-outline-primary mr-1"
                                          >
                                            Add a section
                                          </a>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addRowCustom}
                                            className="btn btn-dim btn-outline-primary"
                                          >
                                            Add a custom product
                                          </a>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tabItem6">
                          <div className="row g-gs">
                            <div className="col-xxl-12 inner_content">
                              <div className="invoice">
                                <div className="invoice-wrap">
                                  <div className="invoice-bills">
                                    <div className=" genericTable">
                                      <DragDropContext
                                        onDragEnd={this.handleDragEndOptional}
                                      >
                                        <Droppable droppableId="droppable-2">
                                          {(provided) => (
                                            <table
                                              ref={provided.innerRef}
                                              {...provided.droppableProps}
                                              className="table table-striped"
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="w-5">
                                                    <em className="icon ni ni-move"></em>
                                                  </th>
                                                  <th className="w-10">
                                                    ITEM ID
                                                  </th>
                                                  <th className="w-10">SKU</th>
                                                  <th className="w-25">
                                                    PRODUCT NAME
                                                  </th>
                                                  <th className="w-10">QTY</th>
                                                  <th className="w-10">UNIT</th>
                                                  <th className="w-10">
                                                    PRICE($)
                                                  </th>
                                                  <th className="w-10">
                                                    TOTAL($)
                                                  </th>
                                                  <th className="w-10">
                                                    &nbsp;
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.rows_optional.map(
                                                  (row, index) => (
                                                    <Draggable
                                                      key={row.id}
                                                      draggableId={`${row.id}-2`}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <tr
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          {row.data.type &&
                                                            row.data.type ===
                                                            "service" ? (
                                                            <>
                                                              <td>
                                                                <em className="icon ni ni-move"></em>
                                                              </td>
                                                              <td>
                                                                {row.data.id &&
                                                                  row.data.id !==
                                                                  ""
                                                                  ? row.data.id
                                                                  : "---"}
                                                              </td>
                                                              <td>
                                                                {row.data.sku &&
                                                                  row.data.sku !==
                                                                  ""
                                                                  ? row.data.sku
                                                                  : "---"}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .name !==
                                                                  "" ? (
                                                                  row.data
                                                                    .name &&
                                                                    row.data
                                                                      .name !==
                                                                    "" ? (
                                                                    row.data
                                                                      .name
                                                                  ) : (
                                                                    "---"
                                                                  )
                                                                ) : (
                                                                  <Select
                                                                    options={
                                                                      this.state
                                                                        .all_select_services
                                                                    }
                                                                    name="select_a_service_optional"
                                                                    placeholder="Select product / service"
                                                                    value={
                                                                      this.state
                                                                        .select_a_service_optional
                                                                    }
                                                                    autoComplete="off"
                                                                    emptyMessage="Product not found"
                                                                    onChange={
                                                                      this
                                                                        .handleChangeSearchOptional
                                                                    }
                                                                    isClearable
                                                                    isSearchable
                                                                    components={
                                                                      animatedComponents
                                                                    }
                                                                  />
                                                                )}
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="quantity"
                                                                    name="quantity"
                                                                    onChange={this.handleChangeServicesOptional(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "60px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .quantity
                                                                    }
                                                                    maxLength={
                                                                      6
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                    disabled={!this.state.editingRowsOptional.has(row.id) && row.data.name !== ""}
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="unit"
                                                                    name="unit"
                                                                    onChange={this.handleChangeServicesOptional(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .unit
                                                                    }
                                                                    maxLength={
                                                                      20
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^a-zA-Z0-9 ]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                    disabled={!this.state.editingRowsOptional.has(row.id) && row.data.name !== ""}
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="price"
                                                                    name="price"
                                                                    onChange={this.handleChangeServicesOptional(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .price
                                                                    }
                                                                    maxLength={
                                                                      10
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9.]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    readOnly
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                {row.data.total}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .name ===
                                                                  "" ? (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() =>
                                                                      this.addNewServiceOptional(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                ) : null}
                                                                {this.state
                                                                  .quote_status !==
                                                                  "completed" &&
                                                                  this.state
                                                                    .quote_status !==
                                                                  "cancelled" ? (
                                                                  <span
                                                                    className="badge badge-outline-danger"
                                                                    onClick={() =>
                                                                      this.removeRowOptional(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-trash"></em>
                                                                  </span>
                                                                ) : null}
                                                              </td>
                                                            </>
                                                          ) : row.data.type ===
                                                            "section" ? (
                                                            <>
                                                              <td>
                                                                <em className="icon ni ni-move"></em>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                                colSpan={7}
                                                              >
                                                                {row.data
                                                                  .name &&
                                                                  row.data
                                                                    .name !==
                                                                  "" ? (
                                                                  row.data.name
                                                                ) : (
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="select_name"
                                                                      name="select_name"
                                                                      onChange={this.handleChangeSectionOptional(
                                                                        row.id
                                                                      )}
                                                                      placeholder=""
                                                                      autoComplete="off"
                                                                      defaultValue={
                                                                        row.data
                                                                          .name
                                                                      }
                                                                      maxLength={
                                                                        100
                                                                      }
                                                                      required
                                                                    />
                                                                  </div>
                                                                )}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .name ===
                                                                  "" ? (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() =>
                                                                      this.addNewSectionOptional(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                ) : null}
                                                                {this.state
                                                                  .quote_status !==
                                                                  "completed" &&
                                                                  this.state
                                                                    .quote_status !==
                                                                  "cancelled" ? (
                                                                  <span
                                                                    className="badge badge-outline-danger"
                                                                    onClick={() =>
                                                                      this.removeRowOptional(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-trash"></em>
                                                                  </span>
                                                                ) : null}
                                                              </td>
                                                            </>
                                                          ) : null}
                                                        </tr>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          )}
                                        </Droppable>
                                      </DragDropContext>

                                      <div className="nk-notes ff-italic fs-12px text-soft">
                                        {" "}
                                      </div>
                                    </div>
                                    <div className="col-xxl-12 mt-5">
                                      {this.state.quote_status !==
                                        "completed" &&
                                        this.state.quote_status !==
                                        "cancelled" ? (
                                        <>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addRowOptional}
                                            //onClick={this.openServices}
                                            className="btn btn-dim btn-outline-primary mr-1"
                                          >
                                            Add a product
                                          </a>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addRowSecOptional}
                                            className="btn btn-dim btn-outline-primary mr-1"
                                          >
                                            Add a section
                                          </a>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tabItem7">
                          <div className="row g-gs">
                            <div className="col-xxl-12">
                              <div className="form-group mt-1">
                                <MutextField
                                  required
                                  id="quote_title"
                                  name="quote_title"
                                  type="text"
                                  label="Order Title"
                                  value={this.state.quote_title}
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  maxLength={100}
                                  autoComplete="off"
                                  fullWidth
                                />
                              </div>
                            </div>
                            <div className="col-xxl-12">
                              <h6>Notes:</h6>
                              <div className="form-group mt-1">
                                {this.state.isEditorReady ? (
                                  <>
                                    <CKEditor
                                      onReady={(editor) => {
                                        const editableElement =
                                          editor.ui.getEditableElement();
                                        if (
                                          editableElement &&
                                          editableElement.parentElement
                                        ) {
                                          editableElement.parentElement.insertBefore(
                                            editor.ui.view.toolbar.element,
                                            editableElement
                                          );
                                        }

                                        this.editor = editor;
                                      }}
                                      onError={(
                                        error,
                                        { willEditorRestart }
                                      ) => {
                                        if (willEditorRestart) {
                                          if (
                                            this.editor &&
                                            this.editor.ui &&
                                            this.editor.ui.view &&
                                            this.editor.ui.view.toolbar
                                          ) {
                                            this.editor.ui.view.toolbar.element.remove();
                                          }
                                        }
                                      }}
                                      onChange={(event, editor) =>
                                        this.handleEditorChange(event, editor)
                                      }
                                      editor={DecoupledEditor}
                                      data={this.state.quote_notes}
                                      config={{
                                        cloudServices: {
                                          tokenUrl: fetchToken,
                                          uploadUrl:
                                            API_URL +
                                            "agents/editor_image_upload",
                                        },
                                        toolbar: {
                                          items: [
                                            "undo",
                                            "redo",
                                            "|",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strikethrough",
                                            "|",
                                            "fontFamily",
                                            "fontSize",
                                            "fontColor",
                                            "fontBackgroundColor",
                                            "|",
                                            "alignment",
                                            "indent",
                                            "outdent",
                                            "|",
                                            "numberedList",
                                            "bulletedList",
                                            "|",
                                            "link",
                                            "imageUpload",
                                            "insertTable",
                                            "|",
                                            "blockQuote",
                                          ],
                                        },
                                        language: "en",
                                      }}
                                    />
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane" id="tabItemPayment">
                          <div className="row g-gs">
                            <div className="col-xxl-12">
                              {this.state.paymentMethodLoader === true ? (
                                FormLoader()
                              ) : (
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <div className="nk-help">
                                      <div className="nk-help-text">
                                        <h5>
                                          {this.state.payment_method === "bank"
                                            ? "Bank Account Details"
                                            : this.state.payment_method ===
                                              "wire"
                                              ? "Wire Transfer Details"
                                              : ""}
                                        </h5>

                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.payment_details,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane p-4" id="tabItem8">
                          <div className="row g-gs">
                            <div className="col-xxl-12">
                              <h5>Requirements</h5>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: this.state.rfq_requirements,
                                }}
                              />
                            </div>
                            {this.state.rfq_attachment &&
                              this.state.rfq_attachment !== "" ? (
                              <div className="col-xxl-12">
                                <h5>Attachment</h5>
                                <table className="table table-striped">
                                  <tbody>
                                    <tr>
                                      <td>Download Attachment</td>
                                      <td>
                                        <a
                                          onClick={() =>
                                            this.downloadPdfRfq(
                                              this.state.rfq_attachment
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                          title="Download Attachment"
                                        >
                                          <span className="badge badge-outline-secondary">
                                            {" "}
                                            <em className="icon ni ni-download"></em>{" "}
                                            Download Attachment
                                          </span>
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                            {this.state.rows_rfq_services &&
                              this.state.rows_rfq_services.length > 0 ? (
                              <div className="col-xxl-12">
                                <h5>RFQ Products & Services</h5>

                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th className="w-150px">ITEM ID</th>
                                      <th className="w-40">Product name</th>
                                      <th>SKU</th>
                                      <th>Quantity</th>
                                      <th>Unit</th>
                                      <th>Add</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.rows_rfq_services.map(
                                      (row, index) => (
                                        <tr key={index}>
                                          <td>{row.data.id}</td>
                                          <td>{row.data.name}</td>
                                          <td>{row.data.sku}</td>
                                          <td>{row.data.quantity}</td>
                                          <td>{row.data.unit}</td>
                                          <td>
                                            <a
                                              onClick={() =>
                                                this.addToCart(row.data.id)
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <span className="badge badge-outline-dark">
                                                <em className="icon ni ni-cart"></em>
                                              </span>
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                            {this.state.rows_rfq_questions &&
                              this.state.rows_rfq_questions.length > 0 ? (
                              <div className="col-xxl-12">
                                <h5>Questions</h5>

                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th className="w-150px">Question</th>
                                      <th className="w-40">Answer</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.rows_rfq_questions.map(
                                      (rfq, index) => (
                                        <tr key={index}>
                                          <td>{rfq.question}</td>
                                          <td>{rfq.answer}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {this.state.final_unsigned_list &&
                        this.state.final_unsigned_list.length > 0 ? (
                        <div className="invoice mt-4">
                          <div
                            className="invoice-wrap"
                            style={{ padding: "2rem" }}
                          >
                            <div class="nk-block-head">
                              <div class="nk-block-between-md g-4">
                                <div class="nk-block-head-content">
                                  <h5 class="nk-block-title fw-normal">
                                    Unsigned Agreements
                                  </h5>
                                  <div class="nk-block-des">
                                    <p>
                                      The following service agreements are
                                      unsigned. Please sign these agreements to
                                      proceed with your order and maximize the
                                      benefits of our products.
                                      <br />{" "}
                                      <strong>
                                        Note that you cannot 'Create Order' at
                                        this time, but you may save it as a
                                        draft. Save order as a draft to prevent
                                        loosing the data. Refersh this page
                                        after signing the agreement.
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {this.state.final_unsigned_list.map(
                              (finalRow, idx) => (
                                <div
                                  class="example-alert mb-2"
                                  key={`agree_${idx}`}
                                >
                                  <div class="alert alert-warning alert-icon">
                                    <em class="icon ni ni-file-text"></em> The
                                    agreement for the service{" "}
                                    <strong>'{finalRow.service_title}' </strong>
                                    <strong>
                                      ({finalRow.agreement_title})
                                    </strong>{" "}
                                    is not signed.{" "}
                                    <a
                                      href={`${APP_URL}client/agreements/unsignedAgreements`}
                                      target="_blank"
                                    >
                                      <strong>Click Here</strong>
                                    </a>{" "}
                                    to sign it before placing your order.{" "}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ) : null}
                      <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end">
                        <li className="step-next">
                          <button
                            type="button"
                            className="btn btn-wider btn-light"
                            disabled={this.state.disabled}

                            onClick={() =>
                              this.addMenuHistory(
                                "/" + auth.getResellerRegToken() + "client/SalesOrders/"
                              )
                            }
                          >
                            <em className="icon ni ni-arrow-left"></em>
                            <span>Back</span>
                          </button>
                        </li>
                        {this.state.quote_status !== "completed" &&
                          this.state.quote_status !== "cancelled" ? (
                          <>
                            <li className="step-next">
                              {this.state.draft_button === true ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Saving... </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-wider btn-primary"
                                  onClick={() => this.save_as_draft()}
                                >
                                  <span>Save as draft</span>
                                  <em className="icon ni ni-save"></em>
                                </button>
                              )}
                            </li>
                            <li className="step-next">
                              {this.state.send_client_button === true ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Creating Order... </span>
                                </button>
                              ) : (
                                <>
                                  {this.state.final_unsigned_list &&
                                    this.state.final_unsigned_list.length ===
                                    0 ? (
                                    <button
                                      type="button"
                                      className="btn btn-wider btn-success"
                                      disabled={this.state.disabled}
                                      onClick={() => this.handleConfirmation()}
                                    >
                                      <span>Create Order</span>
                                      <em className="icon ni ni-arrow-right"></em>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-wider btn-success"
                                      disabled={true}
                                    >
                                      <span>Create Order</span>
                                      <em className="icon ni ni-arrow-right"></em>
                                    </button>
                                  )}
                                </>
                              )}
                            </li>
                          </>
                        ) : null}
                      </ul>
                    </div>
                    <div className="col-xxl-12 border-top mb-4">
                      <div
                        className="simplebar-content-wrapper"
                        style={{ height: "100%" }}
                      >
                        <div
                          className="simplebar-content"
                          style={{ padding: "0px" }}
                        >
                          {this.state.convErrorMessage !== "" ? (
                            <div
                              className="example-alert mt-4"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.convErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="nk-msg-head py-4 d-lg-none">
                            <h4 className="title">
                              Unable to select currency when order
                            </h4>
                            <ul className="nk-msg-tags">
                              <li>
                                <span className="label-tag">
                                  <em className="icon ni ni-flag-fill"></em>{" "}
                                  <span>Technical Problem</span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Table 5th Col End */}
              </div>
              <div className="modal fade" tabIndex="-1" id="modalFormOptions">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Requirements</h5>
                    </div>
                    <div className="modal-body">
                      {this.state.formLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.rfq_requirements,
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* DISCOUNT MODEL */}
              <div className="modal fade" id="modalFormDiscount">
                <div
                  className="modal-dialog modal-dialog-top modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add/Edit Discount</h5>
                      <a
                        style={{ cursor: "pointer" }}
                        className="close"
                        onClick={() => {
                          this.modalHide("modalFormDiscount");
                        }}
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        {this.state.quote_status !== "completed" &&
                          this.state.quote_status !== "cancelled" ? (
                          <>
                            <div className="col-sm-6 col-xxl-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="discount_type"
                                >
                                  Discount Type
                                </label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <select
                                      className="form-control form-control-lg"
                                      name="discount_type"
                                      id="discount_type"
                                      label="Select an Option"
                                      onChange={this.handleChange}
                                      defaultValue={this.state.discount_type}
                                    >
                                      <option key="sets100" value="amount">
                                        Amount
                                      </option>
                                      <option key="sets102" value="percentage">
                                        Percentage
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xxl-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="discount_value"
                                >
                                  Discount value
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="discount_value"
                                    name="discount_value"
                                    onChange={this.handleChange}
                                    value={this.state.discount_value}
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-xxl-12">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="discount_reason"
                                >
                                  Reason for discount
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="discount_reason"
                                    name="discount_reason"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.discount_reason}
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-xxl-12 d-flex">
                              <div className="col-sm-4 col-xxl-4">
                                {this.state.discount_value_total &&
                                  this.state.discount_value_total > 0 &&
                                  this.state.discount_value_total !== "0.00" ? (
                                  <button
                                    type="button"
                                    onClick={() => this.removeDiscount()}
                                    className="btn btn-danger"
                                  >
                                    Remove Discount
                                  </button>
                                ) : null}{" "}
                              </div>
                              <div className="col-sm-8 col-xxl-8 col-md-8">
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="btn btn-light mr-1"
                                    onClick={() => {
                                      this.modalHide("modalFormDiscount");
                                    }}
                                  >
                                    Cancel
                                  </button>{" "}
                                  <button
                                    type="button"
                                    style={{ marginLeft: "10px" }}
                                    className="btn btn-primary"
                                    onClick={() => this.applyDiscount()}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div class="alert alert-pro alert-warning mt-2 mb-2">
                            <div class="alert-text">
                              <h6>Not Allowed</h6>
                              <p>
                                Discounts cannot be added or edited when an
                                order is in the "Completed" or "Cancelled"
                                status.{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DISCOUNT MODEL */}
              {/* SHIPPING MODEL */}
              <div className="modal fade" id="modalFormShipping">
                <div
                  className="modal-dialog modal-dialog-top modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        Shipping and delivery options
                      </h5>
                      <a
                        onClick={() => this.close_modal("modalFormShipping")}
                        href="#"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        {this.state.quote_status !== "completed" &&
                          this.state.quote_status !== "cancelled" ? (
                          <>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="shipping_title"
                                >
                                  Name
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="shipping_title"
                                    name="shipping_title"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.shipping_title}
                                    placeholder="E.g, Free shippping"
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="shipping_value"
                                >
                                  Price
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="shipping_value"
                                    name="shipping_value"
                                    onChange={this.handleChange}
                                    value={this.state.shipping_value}
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-xxl-12 d-flex">
                              <div className="col-sm-4 col-xxl-4">
                                {this.state.shipping_value_total &&
                                  this.state.shipping_value_total > 0 &&
                                  this.state.shipping_value_total !== "0.00" ? (
                                  <button
                                    type="button"
                                    onClick={() => this.remove_shipping()}
                                    className="btn btn-danger"
                                  >
                                    Remove Discount
                                  </button>
                                ) : null}
                              </div>
                              <div className="col-sm-8 col-xxl-8 col-md-8">
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.close_modal("modalFormShipping")
                                    }
                                    className="btn btn-light mr-1"
                                  >
                                    Cancel
                                  </button>{" "}
                                  <button
                                    type="button"
                                    style={{ marginLeft: "10px" }}
                                    className="btn btn-primary"
                                    onClick={this.applyShipping}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div class="alert alert-pro alert-warning mt-2 mb-2">
                            <div class="alert-text">
                              <h6>Not Allowed</h6>
                              <p>
                                Shipping cannot be added or edited when an order
                                is in the "Completed" or "Cancelled" status.{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SHIPPING MODEL */}
              {/* TAX MODEL */}
              <div className="modal fade" id="modalFormTax">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add/Edit Tax</h5>
                      <a
                        onClick={() => this.close_modal("modalFormTax")}
                        href="#"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        {this.state.quote_status !== "completed" &&
                          this.state.quote_status !== "cancelled" ? (
                          <>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="tax_type"
                                >
                                  Discount Type
                                </label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <select
                                      className="form-control form-control-lg"
                                      name="tax_type"
                                      id="tax_type"
                                      label="Select tax type"
                                      onChange={this.handleChange}
                                      defaultValue={this.state.tax_type}
                                    >
                                      <option key="sets100" value="amount">
                                        Amount
                                      </option>
                                      <option key="sets102" value="percentage">
                                        Percentage
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="tax_value"
                                >
                                  Tax value
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="tax_value"
                                    name="tax_value"
                                    onChange={this.handleChange}
                                    value={this.state.tax_value}
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="tax_reason"
                                >
                                  Reason
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="tax_reason"
                                    name="tax_reason"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.tax_reason}
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-xxl-12 d-flex">
                              <div className="col-sm-4 col-xxl-4">
                                {this.state.tax_value_total &&
                                  this.state.tax_value_total > 0 &&
                                  this.state.tax_value_total !== "0.00" ? (
                                  <button
                                    type="button"
                                    onClick={() => this.removeTax()}
                                    className="btn btn-danger"
                                  >
                                    Remove Tax
                                  </button>
                                ) : null}
                              </div>
                              <div className="col-sm-8 col-xxl-8 col-md-8">
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.close_modal("modalFormTax")
                                    }
                                    className="btn btn-light mr-1"
                                  >
                                    Cancel
                                  </button>{" "}
                                  <button
                                    type="button"
                                    style={{ marginLeft: "10px" }}
                                    className="btn btn-primary"
                                    onClick={this.applyTax}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div class="alert alert-pro alert-warning mt-2 mb-2">
                            <div class="alert-text">
                              <h6>Not Allowed</h6>
                              <p>
                                Taxes cannot be added or edited when a quotation
                                is in the "Completed" or "Cancelled" status.{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* TAX MODEL */}
              {/* SERVICE ADD MODAL*/}
              <div className="modal fade" id="modalFormServices">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add product / service</h5>
                      <a
                        style={{ cursor: "pointer" }}
                        className="close"
                        onClick={() => {
                          this.modalHide("modalFormServices");
                        }}
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>
                    <div className="modal-body">
                      <div className="col-sm-12 mb-4">
                        <div className="row g-gs">
                          <div className="col-sm-8">
                            <div className="form-group">
                              <label className="form-label">
                                Select product and add
                              </label>

                              <div className="form-control-wrap">
                                <Select
                                  options={this.state.all_select_services}
                                  name="select_a_service"
                                  placeholder="Select product / service"
                                  value={this.state.select_a_service}
                                  autoComplete="off"
                                  emptyMessage="Product not found"
                                  onChange={this.handleChangeSearch}
                                  isClearable
                                  isSearchable
                                  components={animatedComponents}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      lineHeight: "40px",
                                    }),
                                  }}
                                />
                                {/*<MutextField
                                  id="select_service"
                                  name="select_service"
                                  select
                                  label="Select product / service"
                                  value={this.state.select_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select a service."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option key="sets100" value=""></option>
                                  {this.state.all_services &&
                                  this.state.all_services.length > 0 ? (
                                    <>
                                      {this.state.all_services.map(
                                        (service, sid) => (
                                          <option
                                            key={`ser${sid}`}
                                            value={service[0]}
                                          >
                                            {service[1]}
                                          </option>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                        </MutextField>*/}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="form-label">&nbsp;</label>
                              <div className="form-control-wrap">
                                <button
                                  type="button"
                                  className="btn btn-lg btn-primary"
                                  onClick={this.addNewService}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-gs">
                        <div className="col-xxl-12">
                          <div className="invoice-bills">
                            <div className="example-alert mt-5 mb-2">
                              <div className="alert alert-light">
                                <strong>NOTE:</strong> After adding the product,
                                It will appear in "Order Lines" section where
                                you can add its unit and quantity.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SERVICE ADD MODAL */}
              {/*** Edit Info Modal ***/}
              <div className="modal fade" tabIndex="-1" id="modalDefaultInfo">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Update Order Information</h5>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        {this.state.errorMessageInfo !== "" ? (
                          <div className="col-xxl-12">
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessageInfo}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessageInfo !== "" ? (
                          <div className="col-xxl-12">
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessageInfo}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="form-group mt-1">
                            <MutextField
                              id="payment_terms"
                              name="payment_terms"
                              select
                              label="Terms of payment"
                              value={this.state.payment_terms}
                              onChange={this.handleChange}
                              SelectProps={{
                                native: true,
                              }}
                              helperText="Please select a payment term."
                              variant="outlined"
                              fullWidth
                            >
                              <option key="sets100" value="Net 30">
                                Net 30
                              </option>
                              <option key="sets101" value="Net 60">
                                Net 60
                              </option>
                              <option key="sets102" value="Net 90">
                                Net 90
                              </option>
                              <option key="sets103" value="Due on Receipt">
                                Due on Receipt
                              </option>
                              <option key="sets104" value="2/10 Net 30">
                                2/10 Net 30
                              </option>
                              <option key="sets105" value="1/10 Net 30">
                                1/10 Net 30
                              </option>
                              <option key="sets106" value="2/10 Net 60">
                                2/10 Net 60
                              </option>
                              <option key="sets107" value="3/10 Net 30">
                                3/10 Net 30
                              </option>
                              <option key="sets108" value="Net 45">
                                Net 45
                              </option>
                              <option key="sets109" value="Net 120">
                                Net 120
                              </option>
                              <option
                                key="sets110"
                                value="Cash on Delivery (COD)"
                              >
                                Cash on Delivery (COD)
                              </option>
                              <option
                                key="sets111"
                                value="Cash in Advance (CIA)"
                              >
                                Cash in Advance (CIA)
                              </option>
                              <option key="sets112" value="Stage Payments">
                                Stage Payments
                              </option>
                              <option key="sets113" value="End of Month (EOM)">
                                End of Month (EOM)
                              </option>
                            </MutextField>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="form-group mt-1">
                            <MutextField
                              required
                              id="currency"
                              name="currency"
                              type="text"
                              label="Currency"
                              value={this.state.currency}
                              onChange={this.handleChange}
                              variant="outlined"
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="form-group mt-1">
                            <MutextField
                              required
                              id="contact_person"
                              name="contact_person"
                              type="text"
                              label="Contact Person"
                              value={this.state.contact_person}
                              onChange={this.handleChange}
                              variant="outlined"
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="form-group mt-1">
                            <MutextField
                              required
                              id="contact_email"
                              name="contact_email"
                              type="text"
                              label="Contact Email"
                              value={this.state.contact_email}
                              onChange={this.handleChange}
                              variant="outlined"
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>
                        {/*<div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="form-group mt-1">
                            <MutextField
                              required
                              id="validity"
                              name="validity"
                              type="text"
                              label="Validity"
                              value={this.state.validity}
                              onChange={this.handleChange}
                              variant="outlined"
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>*/}

                        <div className="col-xxl-12">
                          <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end">
                            <li className="step-next">
                              {this.state.info_button === true ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Saving... </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => this.save_quotation_info()}
                                  className="btn btn-wider btn-primary"
                                >
                                  <span>Save</span>
                                  <em className="icon ni ni-save"></em>
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*** Edit Info Modal ***/}
              {/*** Alert Modal ***/}
              <div className="modal fade" tabIndex="-1" id="modalAlertCart">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                        <h4 className="nk-modal-title">Unable to Process!</h4>
                        <div className="nk-modal-text">
                          <p className="lead">
                            {this.state.service_already_eists_message}
                          </p>
                        </div>
                        <div className="nk-modal-action mt-5">
                          <a
                            href="#"
                            className="btn btn-lg btn-mw btn-light"
                            data-dismiss="modal"
                          >
                            Close
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*** Confirmation Modal ***/}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalQuotationConfirmation"
              >
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em className="icon ni ni-file-docs"></em> Order
                        Confirmation
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        <div className="col-md-12">
                          <p>
                            Are you sure you want to confirm the order? This
                            action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="modal-footer"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <button
                        type="button"
                        className="btn btn-light"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.send_to_customer()}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*** Confirmation Modal ***/}

              <div
                className="modal fade"
                tabIndex="-1"
                id="modalAlertCartSuccess"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a href="#" className="close" data-dismiss="modal">
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                        <h4 className="nk-modal-title">Congratulations!</h4>
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            {this.state.service_success_message}
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            href="#"
                            className="btn btn-lg btn-mw btn-primary"
                            data-dismiss="modal"
                          >
                            OK
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*** Alert Modal ***/}
              {/*  DETAILS MODAL*/}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalFormDetailDash"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em>{" "}
                          Product Details
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.serviceData &&
                        this.state.serviceData.length > 0 ? (
                        <>
                          <div>
                            {this.state.serviceData.map((services, idx) => (
                              <div
                                className="card card-bordered"
                                key={`servi${idx}`}
                              >
                                {this.state.unsigned_agreement_title &&
                                  this.state.unsigned_agreement_title !==
                                  "" ? (
                                  <div class="example-alert">
                                    <div class="alert alert-warning alert-icon">
                                      <em class="icon ni ni-alert-circle"></em>{" "}
                                      Your agreement (
                                      {this.state.unsigned_agreement_title})
                                      for this service is pending sign.
                                      Please sign this agreement to fully
                                      access and use this service.{" "}
                                      <a
                                        href={`${APP_URL}${auth.getResellerRegToken()}/client/agreements/unsignedAgreement/${services[43]
                                          }`}
                                        className="text-primary"
                                        style={{
                                          cursor: "pointer",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Click Here
                                      </a>{" "}
                                      to sign this agreement.{" "}
                                    </div>
                                  </div>
                                ) : null}
                                <div className="card-inner-group">
                                  {services[6] === "did" ||
                                    services[6] === "tfn" ||
                                    services[6] === "teams" ? (
                                    <div className="card-inner">
                                      <div className="sp-plan-head">
                                        <div className="col-md-8">
                                          <h6 className="title">
                                            {services[1]}
                                          </h6>
                                        </div>
                                        <div className="4">
                                          <div class="price-plan-amount">
                                            <div class="amount"></div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                        <ul className="row gx-1">
                                          <li className="col-sm-4">
                                            <p>
                                              <span className="text-soft">
                                                NRC
                                                <Tooltip
                                                  title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                  placement="top"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>
                                              </span>{" "}
                                              ${services[4]}
                                            </p>
                                          </li>
                                          <li className="col-sm-4">
                                            <p>
                                              <span className="text-soft">
                                                MRC{" "}
                                                <Tooltip
                                                  title="Monthly Recurring Charge - what you pay each month for the service."
                                                  placement="top"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>
                                              </span>{" "}
                                              ${services[5]}
                                            </p>
                                          </li>
                                          {services[23] !== "ratedeck" ? (
                                            <li className="col-sm-4">
                                              <p>
                                                <span className="text-soft">
                                                  Rate Per Minute{" "}
                                                  <Tooltip
                                                    title="Rate assigned per minute."
                                                    placement="top"
                                                  >
                                                    <em
                                                      className="icon ni ni-info"
                                                      style={{
                                                        color: "#6576ff",
                                                      }}
                                                    ></em>
                                                  </Tooltip>
                                                </span>{" "}
                                                ${services[7]}
                                              </p>
                                            </li>
                                          ) : null}
                                        </ul>
                                      </div>
                                    </div>
                                  ) : null}
                                  {services[6] === "term" ||
                                    services[6] === "ivr" ||
                                    services[6] === "live_agent" ||
                                    services[6] === "tfn_term" ||
                                    services[6] === "az_term" ||
                                    services[6] === "generic" ? (
                                    <div className="card-inner">
                                      <div className="sp-plan-head">
                                        <div className="row g-gs">
                                          <div className="col-md-8">
                                            <h6 className="title">
                                              {services[1]}
                                            </h6>
                                          </div>
                                          <div className="col-md-4 text-right">
                                            <div class="price-plan-amount">
                                              <div class="amount">
                                                <span className="bill fs-12px">
                                                  Price:{" "}
                                                </span>

                                                {this.formatAsCurrencys(
                                                  services[7]
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                        {services[42]}
                                      </div>
                                      {services[6] === "generic" ? (
                                        <>
                                          <div
                                            className="sp-plan-head"
                                            style={{
                                              marginTop: "15px",
                                              borderTop:
                                                "1px solid #f6f6f6",
                                              paddingTop: "15px",
                                            }}
                                          >
                                            <h6 className="title">
                                              Other Service Rates
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <table className="table">
                                              <thead className="thead-light">
                                                <tr>
                                                  <th scope="col">Title</th>
                                                  <th scope="col">
                                                    Charge Type
                                                  </th>
                                                  <th scope="col">Cycle</th>
                                                  <th scope="col">Price</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {services[34].map(
                                                  (miscrate, idx) => (
                                                    <tr key={`mis${idx}`}>
                                                      <td>
                                                        {
                                                          miscrate.generic_title
                                                        }
                                                      </td>
                                                      <td>
                                                        {miscrate.pay_type ===
                                                          "recurring"
                                                          ? "Recurring"
                                                          : ""}

                                                        {miscrate.pay_type ===
                                                          "one_time"
                                                          ? "One Time"
                                                          : ""}
                                                      </td>
                                                      <td>
                                                        {miscrate.pay_type ===
                                                          "recurring" ? (
                                                          <>
                                                            {miscrate.generic_type ===
                                                              "per_minute"
                                                              ? "Per Minute"
                                                              : ""}
                                                            {miscrate.generic_type ===
                                                              "per_hour"
                                                              ? "Per Hour"
                                                              : ""}
                                                            {miscrate.generic_type ===
                                                              "per_second"
                                                              ? "Per Second"
                                                              : ""}
                                                            {miscrate.generic_type ===
                                                              "per_day"
                                                              ? "Per Day"
                                                              : ""}
                                                            {miscrate.generic_type ===
                                                              "per_month"
                                                              ? "Per Month"
                                                              : ""}
                                                            {miscrate.generic_type ===
                                                              "per_year"
                                                              ? "Per Year"
                                                              : ""}
                                                          </>
                                                        ) : null}
                                                      </td>
                                                      <td>
                                                        {miscrate.generic_rate !==
                                                          "" &&
                                                          miscrate.generic_rate >
                                                          0 ? (
                                                          <>
                                                            {this.formatAsCurrencys(
                                                              miscrate.generic_rate
                                                            )}
                                                          </>
                                                        ) : (
                                                          "$0.00"
                                                        )}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  ) : null}
                                  {services[6] === "sms" ? (
                                    <div className="card-inner">
                                      <div className="sp-plan-head">
                                        <h6 className="title">
                                          {services[1]}{" "}
                                          <span className="badge badge-dim badge-primary badge-pill">
                                            New
                                          </span>
                                        </h6>
                                      </div>

                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                        <ul className="row gx-1">
                                          <li className="col-sm-5">
                                            <p>
                                              <span className="text-soft">
                                                SMS In{" "}
                                                <Tooltip
                                                  title="Price applied to SMS In Services."
                                                  placement="top"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>
                                              </span>{" "}
                                              ${services[10]}
                                            </p>
                                          </li>
                                          <li className="col-sm-5">
                                            <p>
                                              <span className="text-soft">
                                                SMS Out{" "}
                                                <Tooltip
                                                  title="Price applied to SMS Out Services."
                                                  placement="top"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>
                                              </span>{" "}
                                              ${services[11]}
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ) : null}
                                  {services[6] === "did" ||
                                    services[6] === "tfn" ||
                                    services[6] === "teams" ? (
                                    <>
                                      {services[30] &&
                                        services[30].length > 0 ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Porting Rates
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Portin Sell Rate
                                                  </span>{" "}
                                                  $
                                                  {services[30][0].portin_sell_rate.toFixed(
                                                    4
                                                  )}
                                                </p>
                                              </li>
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Portin Sell Rate
                                                  </span>{" "}
                                                  $
                                                  {services[30][0].portout_sell_rate.toFixed(
                                                    4
                                                  )}
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}

                                  {services[23] !== "ratedeck" ? (
                                    <>
                                      {services[29] &&
                                        services[29] !== "" &&
                                        services[29] > 0 ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Cancellation Fee
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  {services[29] &&
                                                    services[29] !== "" ? (
                                                    <>
                                                      {this.formatAsCurrencys(
                                                        services[29]
                                                      )}
                                                    </>
                                                  ) : (
                                                    "$0.00"
                                                  )}
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}

                                  {services[23] === "ratedeck" ? (
                                    <div className="card-inner">
                                      <div className="sp-plan-head">
                                        <h6 className="title">
                                          Rate Deck / Effective Date
                                        </h6>
                                      </div>
                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                        <ul className="row gx-1">
                                          <li className="col-sm-5">
                                            <p>
                                              <span className="text-soft">
                                                Rate Deck
                                              </span>{" "}
                                              {services[25] &&
                                                services[25] !== "" ? (
                                                <>
                                                  <a
                                                    onClick={() =>
                                                      this.downloadCsv(
                                                        services[25]
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    className="btn btn-outline-primary"
                                                  >
                                                    <em className="icon ni ni-download"></em>{" "}
                                                    Download Rate Deck
                                                  </a>
                                                </>
                                              ) : null}
                                            </p>
                                          </li>
                                          <li className="col-sm-5">
                                            <p>
                                              <span className="text-soft">
                                                Effective Date
                                              </span>{" "}
                                              {services[24] &&
                                                services[24] !== "" ? (
                                                <>
                                                  {this.format_date(
                                                    services[24]
                                                  )}
                                                </>
                                              ) : null}
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ) : null}

                                  {services[31] &&
                                    services[31].length > 0 ? (
                                    <div className="card-inner">
                                      <div
                                        className="sp-plan-head"
                                        style={{
                                          marginTop: "15px",
                                          borderTop: "1px solid #f6f6f6",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        <h6 className="title">
                                          Miscellaneous Rates
                                        </h6>
                                      </div>
                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                        <table className="table">
                                          <thead className="thead-light">
                                            <tr>
                                              <th scope="col">Title</th>
                                              <th scope="col">Rate Type</th>
                                              <th scope="col">Rate</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {services[31].map(
                                              (miscrate, idx) => (
                                                <>
                                                  {miscrate.misc_title &&
                                                    miscrate.misc_title !==
                                                    "" ? (
                                                    <tr key={`mis${idx}`}>
                                                      <td>
                                                        {
                                                          miscrate.misc_title
                                                        }
                                                      </td>
                                                      <td>
                                                        {miscrate.misc_type ===
                                                          "per_minute"
                                                          ? "Per Minute"
                                                          : ""}
                                                        {miscrate.misc_type ===
                                                          "per_hour"
                                                          ? "Per Hour"
                                                          : ""}
                                                        {miscrate.misc_type ===
                                                          "one_time"
                                                          ? "One Time"
                                                          : ""}
                                                      </td>
                                                      <td>
                                                        {miscrate.misc_rate !==
                                                          "" &&
                                                          miscrate.misc_rate >
                                                          0 ? (
                                                          <>
                                                            {this.formatAsCurrencys(
                                                              miscrate.misc_rate
                                                            )}
                                                          </>
                                                        ) : (
                                                          "$0.00"
                                                        )}
                                                      </td>
                                                    </tr>
                                                  ) : null}
                                                </>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  ) : null}

                                  {services[35] &&
                                    services[35] === "link" &&
                                    services[36] !== "" ? (
                                    <>
                                      {services[36] !== "" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Marketing Link
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Marketing Link
                                                  </span>{" "}
                                                  <a
                                                    href={services[36]}
                                                    target="_blank"
                                                  >
                                                    <em className="icon ni ni-link-h"></em>{" "}
                                                    Click to open link
                                                  </a>
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}
                                  {services[35] &&
                                    services[35] === "pdf" &&
                                    services[36] !== "" ? (
                                    <>
                                      {services[36] !== "" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Marketing Link
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Marketing Link
                                                  </span>{" "}
                                                  <a
                                                    href={services[36]}
                                                    target="_blank"
                                                  >
                                                    <em className="icon ni ni-file-pdf"></em>{" "}
                                                    View PDF
                                                  </a>
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}

                                  {services[39] && services[39] !== "" ? (
                                    <div className="card-inner">
                                      <div className="sp-plan-head-group">
                                        <div className="sp-plan-head">
                                          <h6 className="title">
                                            Document / Rate Deck{" "}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                        <ul className="row gx-1">
                                          <li className="col-sm-5">
                                            <p>
                                              <br />
                                              <a
                                                onClick={() =>
                                                  this.downloadDeck(
                                                    services[39]
                                                  )
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                className="text-primary"
                                              >
                                                <em class="icon ni ni-download"></em>{" "}
                                                {services[38]}{" "}
                                                {this.state
                                                  .downloadLoader ===
                                                  true ? (
                                                  <div
                                                    class="spinner-border spinner-border-sm text-primary"
                                                    role="status"
                                                  >
                                                    <span class="sr-only">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                ) : null}
                                              </a>
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ) : null}

                                  {services[40] && services[40] !== "" ? (
                                    <div className="card-inner">
                                      <div className="sp-plan-head-group">
                                        <div className="sp-plan-head">
                                          <h6 className="title">
                                            3rd Party Integration{" "}
                                          </h6>
                                          <span className="text-body">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: services[41],
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="card-inner p_description">
                                    <div className="sp-plan-head-group">
                                      <div className="sp-plan-head">
                                        <h6 className="title">
                                          Description
                                        </h6>
                                        <span className="text-body">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: services[3],
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAILS MODAL */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
    quotationSettings: state.quotationSettings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewOrder);
