import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.css";
import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Container } from "react-bootstrap";
import $ from "jquery";
import {
  TextField,
  Checkbox,
  Radio,
  FormControlLabel,
  FormGroup,
  FormControl,
  Button,
  FormHelperText,
} from "@material-ui/core";
import { APP_LIVE_URL, ADMIN_URL } from "../../config/config";
import {
  DateTimePicker,
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import { CopyToClipboard } from "react-copy-to-clipboard";
import html2canvas from "html2canvas";

import { PhoneInput } from "react-international-phone";
import Select from "react-select";
import countryList from "react-select-country-list";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { PhoneNumberUtil } from "google-libphonenumber";

const populatedynamicValues = (clientsData, value_name) => {
  if (clientsData && clientsData.length > 0) {
    const valueMap = {
      fullname: clientsData[0].name,
      company: clientsData[0].comp_name,
      email: clientsData[0].email,
      phone: clientsData[0].phone,
      address: clientsData[0].address,
      appartment: clientsData[0].address2,
      city: clientsData[0].city,
      state: clientsData[0].state,
      zip: clientsData[0].zip,
      country: clientsData[0].country,
    };

    if (value_name === "full_address") {
      return [
        clientsData[0].address,
        clientsData[0].address2,
        clientsData[0].city,
        clientsData[0].state,
        clientsData[0].zip,
        clientsData[0].country,
      ]
        .filter(Boolean)
        .join(", ");
    }

    return valueMap[value_name] || "";
  } else {
    return "";
  }
};

const loadFonts = () => {
  const link = document.createElement("link");
  link.href =
    "https://fonts.googleapis.com/css2?family=Amita&family=Bad+Script&family=Damion&family=Dancing+Script&family=Felipa&family=Julee&family=Kaushan+Script&family=Lugrasimo&family=Pacifico&family=Sofia&family=Yellowtail&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

const cursiveFonts = [
  { name: "Your Signature", value: "'Amita', cursive" },
  { name: "Your Signature", value: "'Bad Script', cursive" },
  { name: "Your Signature", value: "'Damion', cursive" },
  { name: "Your Signature", value: "'Dancing Script', cursive" },
  { name: "Your Signature", value: "'Felipa', cursive" },
  { name: "Your Signature", value: "'Julee', cursive" },
  { name: "Your Signature", value: "'Kaushan Script', cursive" },
  { name: "Your Signature", value: "'Lugrasimo', cursive" },
  { name: "Your Signature", value: "'Pacifico', cursive" },
  { name: "Your Signature", value: "'Sofia', cursive" },
  { name: "Your Signature", value: "'Yellowtail', cursive" },
  // Add more cursive fonts here
];

const FormRendererClient = ({
  formData,
  formTitle,
  formDescription,
  onSubmit,
  groups,
  responseMessage,
  clientData,
  allow_submit,
  draftData,
  saveDraft,
  progressStep,
  viewFrom,
  service_data,
  service_id
}) => {
  const [formState, setFormState] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(progressStep);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [signatureType, setSignatureType] = useState("draw");
  const [signatureText, setSignatureText] = useState("");
  const [signatureClass, setSignatureClass] = useState("'Amita', cursive");
  const sigPadRefs = useRef([]);

  const [country, setCountry] = useState("");
  const [Address, setAddress] = useState("");
  const [submission, setSubmission] = useState("");
  const [selectedServices, setSelectedServices] = useState(() => {
    return service_id ? [Number(service_id)] : service_data?.map(([id]) => id) || [];
  });
  const countryOptions = countryList().getData();
  const phoneInputRef = useRef(null);
  //console.log("draftData: ", draftData);
  useEffect(() => {
    const initialState = {};
    // Assuming questions is an array and you need to access its nested `questions` property
    formData.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.dynamicValue) {
          initialState[question.id] = populatedynamicValues(
            clientData,
            question.dynamicValue
          );
        } else {
          initialState[question.id] = ""; // or any default value if needed
        }
      });
    });
    setFormState(initialState);
  }, []);

  useEffect(() => {
    if (draftData && draftData.length > 0) {
      const initialFormState = {};
      draftData[0].questions.forEach((question) => {
        initialFormState[question.id] = question.answer || "";
      });
      setFormState(initialFormState);
      //setCurrentStep(progressStep);
    }
  }, []);

  const hasUngroupedQuestions = formData.some((form) =>
    form.questions.some((q) => !groups?.flat().includes(q.id))
  );

  const handleCheckboxChangeNew = (id) => {
    setSelectedServices((prev) =>
      prev.includes(id) ? prev.filter((serviceId) => serviceId !== id) : [...prev, id]
    );
  };

  const ungroupedQuestions = formData
    .flatMap((form) => form.questions)
    .filter((question) => !groups.flat().includes(question.id));

  const handleChange = (questionId, value, inputFormat) => {
    if (inputFormat === "number_only") {
      value = value.replace(/[^0-9]/g, "");
    } else if (inputFormat === "alphabet_only") {
      value = value.replace(/[^a-zA-Z\s]/g, "");
    } else if (inputFormat === "alphanumeric") {
      value = value.replace(/[^a-zA-Z0-9\s]/g, "");
    }
    console.log("questionId  value: " + questionId + "  " + value);
    setFormState({
      ...formState,
      [questionId]: value,
    });
    setFormErrors({
      ...formErrors,
      [questionId]: "",
    });
  };

  const handleChangeSignature = (questionId, value) => {
    setSignatureText(value);
  };

  const handleChangeFixed = (questionId, value) => {
    setFormState({
      ...formState,
    });
  };

  const handleCheckboxChange = (questionId, option, isChecked) => {
    const currentOptions = formState[questionId] || [];
    if (isChecked) {
      setFormState({
        ...formState,
        [questionId]: [...currentOptions, option],
      });
    } else {
      setFormState({
        ...formState,
        [questionId]: currentOptions.filter((opt) => opt !== option),
      });
    }
    setFormErrors({
      ...formErrors,
      [questionId]: "",
    });
  };

  const handleFileChange = (questionId, event) => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        axios
          .post(
            `${process.env.REACT_APP_API_URL}forms/form_image_response`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log("handleFileChange: ", response);
            const imageUrl = response.data.default;
            setFormState((prevFormState) => ({
              ...prevFormState,
              [questionId]: imageUrl,
            }));
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              [questionId]: "",
            }));
          })
          .catch((error) => {
            console.error("There was an error uploading the image!", error);
          });
      }
    } else {
      console.error("No file selected or event target is undefined.");
    }
  };

  const handleFocus = () => {
    const input = phoneInputRef.current?.input || phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (value) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(value));
    } catch (error) {
      return false;
    }
  };

  const handleChangeCountry = (questionId, selectedOption) => {
    const selectedLabel = selectedOption ? selectedOption.label : "";

    setFormState((prevState) => ({
      ...prevState,
      [questionId]: selectedLabel,
    }));

    setFormErrors((prevState) => ({
      ...prevState,
      [questionId]: "",
    }));

    setCountry(selectedOption);

    //console.log("Selected Country Label:", selectedLabel);
  };

  const handleAddressSelect = async (questionId, place) => {
    console.log("place", place);

    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();
      console.log("handleAddressSelect data: ", data);
      if (data.status === "OK") {
        const formattedAddress = data.results[0].formatted_address;
        const addressComponents = data.results[0].address_components;
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        let zip = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }

        let full_address =
          streetAddress +
          address2 +
          ", " +
          city +
          ", " +
          state +
          ", " +
          zipCode +
          ", " +
          country;

        setFormState((prevState) => ({
          ...prevState,
          [questionId]: full_address || "",
        }));
        setFormErrors((prevState) => ({
          ...prevState,
          [questionId]: "",
        }));

        // Store the formatted address
        setAddress(full_address || "");
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  const handleChangePhone = (questionId, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [questionId]: value,
    }));

    setFormErrors((prevState) => ({
      ...prevState,
      [questionId]: "",
    }));

    //console.log("Phone Number:", value);
  };

  const handleSubmit = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const requiredQuestions = formData.flatMap((form) =>
      form.questions.filter((question) => question.required)
    );

    const currentGroup = groups[currentStep];

    const validationQuestions = formData.flatMap((form) =>
      form.questions.filter(
        (question) =>
          question.validationValue &&
          currentGroup.questions.includes(question.id)
      )
    );

    let hasErrors = false;
    let emptyFieldMessages = [];
    let validationMessages = [];
    let filerId = null; // Initialize variable to store filer_id
    let fullAddress = null; // Initialize variable to store full_address

    requiredQuestions.forEach((question) => {
      if (!formState[question.id]) {
        hasErrors = true;
      }
    });

    const { isValid, missingFields } = validateCurrentGroup();

    if (!isValid) {
      const fieldsList = missingFields.join(", ");
      emptyFieldMessages.push(
        `Please fill out all required fields: ${fieldsList}`
      );
      hasErrors = true;
    }

    validationQuestions.forEach((question) => {
      const value = formState[question.id]?.trim();
      console.log("value", value);

      if (value && value !== "") {
        // Perform various validations based on question validation value
        if (question.validationValue === "validation_email") {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!emailRegex.test(value)) {
            hasErrors = true;
            validationMessages.push("Please enter a valid email address.");
          }
        }

        if (question.validationValue === "validation_phone") {
          if (!isPhoneValid(value)) {
            hasErrors = true;
            validationMessages.push("Please enter a valid phone number.");
          }
        }

        if (question.validationValue === "validation_zip") {
          const zipRegex = /^[A-Za-z0-9\s\-]{4,10}$/;
          if (!zipRegex.test(value)) {
            hasErrors = true;
            validationMessages.push("Please enter a valid postal code.");
          }
        }

        if (question.validationValue === "validation_address") {
          const addressRegex = /^[0-9a-zA-Z\s,.'\-#()]+$/;
          const wordCount = value.trim().split(/\s+/).length;

          if (!addressRegex.test(value) || wordCount < 5) {
            hasErrors = true;
            validationMessages.push(
              "Please enter a complete address containing at least 5 words."
            );
          }
        }

        if (question.validationValue === "validation_city") {
          const cityRegex = /^[a-zA-Z\s.'\-áéíóúÁÉÍÓÚñÑüÜ]+$/;
          if (!cityRegex.test(value)) {
            hasErrors = true;
            validationMessages.push(
              "City must contain only letters, spaces, hyphens, or valid special characters."
            );
          }
        }

        if (question.validationValue === "validation_state") {
          const stateRegex = /^[a-zA-Z\s]+$/;
          if (!stateRegex.test(value)) {
            hasErrors = true;
            validationMessages.push(
              "State should contain only letters and spaces."
            );
          }
        }

        if (question.validationValue === "validation_ip") {
          // IPv4 regex - no leading zeros allowed
          const ipv4Regex =
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

          // IPv6 regex - supports shorthand (::) and expanded addresses
          const ipv6Regex =
            /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}|(?:[a-fA-F0-9]{1,4}:){1,7}:|::(?:[a-fA-F0-9]{1,4}:){0,5}(?:[a-fA-F0-9]{1,4})$/;

          if (!ipv4Regex.test(value) && !ipv6Regex.test(value)) {
            hasErrors = true;
            validationMessages.push(
              "Please enter a valid IP address (IPv4 or IPv6)."
            );
          }
        }

        if (question.validationValue === "validation_filerId") {
          const filerIdRegex = /^[A-Z0-9]{0,10}$/;
          if (!filerIdRegex.test(value)) {
            hasErrors = true;
            validationMessages.push(
              "Filer ID should be exactly 6 alphanumeric characters."
            );
          }
          if (value) {
            filerId = value;
          }
        }

        if (question.validationValue === "validation_fulladdress") {
          if (value) {
            fullAddress = value;
          }
        }
      }
    });

    if (hasErrors) {
      let finalErrorMessage = "";

      if (emptyFieldMessages.length > 0) {
        finalErrorMessage += emptyFieldMessages.join("<br />") + "<br />";
      }

      if (validationMessages.length > 0) {
        finalErrorMessage += validationMessages.join("<br />");
      }

      setErrorMessage(finalErrorMessage);
      setTimeout(() => {
        setErrorMessage("");
      }, 4500);
      return;
    }

    setLoading(true);
    //console.log("formData Before: ", formData);
    try {
      // Await the verification process before proceeding
      await verifyInfoSubmit(filerId, fullAddress);

      // Prepare form data to send
      const formDataToSend = formData.map((form) => ({
        title: form.title,
        id: form.id,
        questions: form.questions.map((question) => ({
          ...question,
          answer:
            question.answerType === "fixed"
              ? question.answer
              : formState[question.id],
        })),
        errorMessage: form.errorMessage,
      }));

      // Submit form data only after the verification is complete
      await onSubmit(formDataToSend, selectedServices);
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    } catch (error) {
      console.error("Error submitting the form:", error);
      setLoading(false);
    }
  };

  /*const handleNextStep = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log("validateCurrentGroup(): ", validateCurrentGroup());
    if (validateCurrentGroup()) {
      setCurrentStep(currentStep + 1);
    } else {
      setErrorMessage(
        "Please fill out all required fields in the current group."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 3500);
    }
  };*/

  /*const validateCurrentGroup = () => {
    const currentGroup = groups[currentStep];
    if (!currentGroup) return false;

    const hasEmptyRequiredFields = currentGroup.questions.some((questionId) => {
      const question = formData[0].questions.find((q) => q.id === questionId);
      
      if (question) {
        //return question.required && !formState[question.id];
        return question;
      }
    });

    return !hasEmptyRequiredFields;
  };*/

  const handleNextStep = async () => {
    const { isValid, missingFields } = validateCurrentGroup();
    window.scrollTo({ top: 0, behavior: "smooth" });
    let hasErrors = false;
    let emptyFieldMessages = [];
    let validationMessages = [];
    let filerId = null; // Initialize variable to store filer_id
    let fullAddress = null; // Initialize variable to store full_address

    if (!isValid) {
      const fieldsList = missingFields.join(", ");
      emptyFieldMessages.push(
        `Please fill out all required fields: ${fieldsList}`
      );
      hasErrors = true;
    }

    const currentGroup = groups[currentStep];

    const validationQuestions = formData.flatMap((form) =>
      form.questions.filter(
        (question) =>
          question.validationValue &&
          currentGroup.questions.includes(question.id)
      )
    );

    validationQuestions.forEach((question) => {
      const value = formState[question.id]?.trim();

      if (value && value !== "") {
        if (question.validationValue === "validation_email") {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!emailRegex.test(value)) {
            hasErrors = true;
            validationMessages.push("Please enter a valid email address.");
          }
        }

        if (question.validationValue === "validation_phone") {
          if (!isPhoneValid(value)) {
            hasErrors = true;
            validationMessages.push("Please enter a valid phone number.");
          }
        }

        if (question.validationValue === "validation_zip") {
          const zipRegex = /^[A-Za-z0-9\s\-]{4,10}$/;
          if (!zipRegex.test(value)) {
            hasErrors = true;
            validationMessages.push("Please enter a valid postal code.");
          }
        }

        if (question.validationValue === "validation_address") {
          const addressRegex = /^[0-9a-zA-Z\s,.'\-#()]+$/;
          if (!addressRegex.test(value) || value.length < 5) {
            hasErrors = true;
            validationMessages.push(
              "Address should be at least 5 characters long and contain valid characters."
            );
          }
        }

        if (question.validationValue === "validation_city") {
          const cityRegex = /^[a-zA-Z\s.'\-áéíóúÁÉÍÓÚñÑüÜ]+$/;
          if (!cityRegex.test(value)) {
            hasErrors = true;
            validationMessages.push(
              "City must contain only letters, spaces, hyphens, or valid special characters."
            );
          }
        }

        if (question.validationValue === "validation_state") {
          const stateRegex = /^[a-zA-Z\s]+$/;
          if (!stateRegex.test(value)) {
            hasErrors = true;
            validationMessages.push(
              "State should contain only letters and spaces."
            );
          }
        }

        if (question.validationValue === "validation_ip") {
          // IPv4 regex - no leading zeros allowed
          const ipv4Regex =
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

          // IPv6 regex - supports shorthand (::) and expanded addresses
          const ipv6Regex =
            /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}|(?:[a-fA-F0-9]{1,4}:){1,7}:|::(?:[a-fA-F0-9]{1,4}:){0,5}(?:[a-fA-F0-9]{1,4})$/;

          if (!ipv4Regex.test(value) && !ipv6Regex.test(value)) {
            hasErrors = true;
            validationMessages.push(
              "Please enter a valid IP address (IPv4 or IPv6)."
            );
          }
        }

        if (question.validationValue === "validation_filerId") {
          const filerIdRegex = /^[A-Z0-9]{0,10}$/;
          if (!filerIdRegex.test(value)) {
            hasErrors = true;
            validationMessages.push(
              "Filer ID should be exactly 6 alphanumeric characters."
            );
          }
        }
        if (question.validationValue === "validation_filerId") {
          if (value) {
            filerId = value;
          }
        }

        if (question.validationValue === "validation_fulladdress") {
          if (value) {
            fullAddress = value;
          }
        }
      }
    });

    if (hasErrors) {
      let finalErrorMessage = "";

      if (emptyFieldMessages.length > 0) {
        finalErrorMessage += emptyFieldMessages.join("<br />") + "<br />";
      }

      if (validationMessages.length > 0) {
        finalErrorMessage += validationMessages.join("<br />");
      }

      setErrorMessage(finalErrorMessage);
      setTimeout(() => {
        setErrorMessage("");
      }, 4500);
      return;
    }

    if (filerId) {
      console.log("Filer ID:", filerId);
    }

    if (fullAddress) {
      console.log("Full Address:", fullAddress);
    }

    //verifyInfo(filerId, fullAddress);

    let isFilerVerified = true;
    setLoading(true);
    if (!filerId || !fullAddress) {
      isFilerVerified = await verifyInfo(filerId, fullAddress);
    }
    if (isFilerVerified) {
      const formDataToSend = formData.map((form) => ({
        title: form.title,
        id: form.id,
        questions: form.questions.map((question) => ({
          ...question,
          answer:
            question.answerType === "fixed"
              ? question.answer
              : formState[question.id],
        })),
        errorMessage: form.errorMessage,
      }));
      const isSaved = await saveDraft(formDataToSend, currentStep + 1);
      if (isSaved) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  };

  const validateCurrentGroup = () => {
    const currentGroup = groups[currentStep];
    if (!currentGroup) return { isValid: false, missingFields: [] };

    const missingFields = currentGroup.questions
      .map((questionId) => {
        const question = formData[0].questions.find((q) => q.id === questionId);

        if (question && question.required && !formState[question.id]) {
          return question.questionText; // Return the question text (field name) if it's required and empty
        }
        return null;
      })
      .filter(Boolean); // Filter out any null values

    return {
      isValid: missingFields.length === 0,
      missingFields: missingFields,
    };
  };

  const verifyInfo = async (filerId, fullAddress) => {
    const payload = {
      filer_id: filerId,
      full_address: fullAddress,
    };

    const TIMEOUT_MS = 6000; // 6 seconds timeout

    const verificationRequest = axios.post(
      `${process.env.REACT_APP_API_URL}forms/verify_info`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const timeout = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), TIMEOUT_MS)
    );

    try {
      const response = await Promise.race([verificationRequest, timeout]);
      console.log("Verification response: ", response);
      //setCurrentStep((prevStep) => prevStep + 1);

      // Return the response data for further use if needed
      return true;
    } catch (error) {
      let errorMessage = "";

      if (error.message === "Request timed out") {
        errorMessage =
          "Your FCC Filer verification or Address validation request is taking longer than expected. Please try again.";
      } else if (error.response) {
        const { status, data } = error.response;
        const formattedErrorMessage = data.errors
          ? String(data.errors).replace(/,/g, "<br />")
          : null;

        switch (status) {
          case 411:
            errorMessage = `Invalid Filer ID<br />${formattedErrorMessage ||
              "Both Filer ID and address validation failed."
              }`;
            break;
          case 410:
            errorMessage = "Invalid FCC Filer ID.";
            break;
          case 409:
            errorMessage = formattedErrorMessage || "Address validation failed";
            break;
          default:
            errorMessage = "An unexpected error occurred.";
        }
      } else {
        errorMessage = "There was an error verifying the information!";
      }

      setErrorMessage(errorMessage);
      setTimeout(() => {
        setErrorMessage("");
      }, 4500);
      return false;
    } finally {
      // Reset the loading state after the request completes
      setLoading(false);
    }
  };

  /*const verifyInfo = (filerId, fullAddress) => {
    const payload = {
      filer_id: filerId,
      full_address: fullAddress,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}forms/verify_info`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Verification response: ", response.data);
        setCurrentStep(currentStep + 1);
      })
      .catch((error) => {
        if (error.response) {
          const { status, data } = error.response;
          let errorMessage;

          window.scrollTo({ top: 0, behavior: "smooth" });

          const formattedErrorMessage = data.errors
            ? String(data.errors).replace(/,/g, "<br />")
            : null;

          switch (status) {
            case 411:
              errorMessage = `Invalid Filer ID<br />${
                formattedErrorMessage ||
                "Both Filer ID and address validation failed."
              }`;
              break;
            case 410:
              errorMessage = "Invalid FCC Filer ID.";
              break;
            case 409:
              errorMessage =
                formattedErrorMessage || "Address validation failed";
              break;
            default:
              errorMessage = "An unexpected error occurred.";
          }

          setErrorMessage(errorMessage);
          setTimeout(() => {
            setErrorMessage("");
          }, 4500);

          return;
        } else {
          setErrorMessage("There was an error verifying the information!");
          setTimeout(() => {
            setErrorMessage("");
          }, 4500);
        }
      });
  };*/

  const verifyInfoSubmit = async (filerId, fullAddress) => {
    const payload = {
      filer_id: filerId,
      full_address: fullAddress,
    };

    const TIMEOUT_MS = 6000; // 6 seconds timeout

    // Create a timeout promise
    const timeout = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), TIMEOUT_MS)
    );

    try {
      // Use Promise.race to handle both the request and the timeout
      const response = await Promise.race([
        axios.post(
          `${process.env.REACT_APP_API_URL}forms/verify_info`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ),
        timeout,
      ]);

      console.log("Verification response: ", response);
      setSubmission(true);
      return response;
    } catch (error) {
      setSubmission(false);

      let errorMessage = "";

      if (error.message === "Request timed out") {
        errorMessage =
          "Your FCC Filer verification or Address validation request is taking longer than expected. Please try again.";
      } else if (error.response) {
        const { status, data } = error.response;

        window.scrollTo({ top: 0, behavior: "smooth" });

        const formattedErrorMessage = data.errors
          ? String(data.errors).replace(/,/g, "<br />")
          : null;

        switch (status) {
          case 411:
            errorMessage = `Invalid Filer ID<br />${formattedErrorMessage ||
              "Both Filer ID and address validation failed."
              }`;
            break;
          case 410:
            errorMessage = "Invalid FCC Filer ID.";
            break;
          case 409:
            errorMessage = formattedErrorMessage || "Address validation failed";
            break;
          default:
            errorMessage = "An unexpected error occurred.";
        }
      } else {
        errorMessage = "There was an error verifying the information!";
      }

      setErrorMessage(errorMessage);
      setTimeout(() => {
        setErrorMessage("");
      }, 4500);

      throw error;
    }
  };

  /*const verifyInfoSubmit = async (filerId, fullAddress) => {
    const payload = {
      filer_id: filerId,
      full_address: fullAddress,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}forms/verify_info`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Verification response: ", response);
      setSubmission(true); // Set submission success state
      return response; // Return response so that it can be used elsewhere if needed
    } catch (error) {
      setSubmission(false); // Set submission failure state

      if (error.response) {
        const { status, data } = error.response;
        let errorMessage;

        window.scrollTo({ top: 0, behavior: "smooth" });

        const formattedErrorMessage = data.errors
          ? String(data.errors).replace(/,/g, "<br />")
          : null;

        switch (status) {
          case 411:
            errorMessage = `Invalid Filer ID<br />${
              formattedErrorMessage ||
              "Both Filer ID and address validation failed."
            }`;
            break;
          case 410:
            errorMessage = "Invalid FCC Filer ID.";
            break;
          case 409:
            errorMessage = formattedErrorMessage || "Address validation failed";
            break;
          default:
            errorMessage = "An unexpected error occurred.";
        }

        setErrorMessage(errorMessage);
        setTimeout(() => {
          setErrorMessage("");
        }, 4500);
      } else {
        setErrorMessage("There was an error verifying the information!");
        setTimeout(() => {
          setErrorMessage("");
        }, 4500);
      }

      throw error; // Ensure errors propagate so the caller knows about the failure
    }
  };*/

  const clearSignature = (index) => {
    if (sigPadRefs.current[index]) {
      sigPadRefs.current[index].clear();
      $("#divSig" + index).hide();
      $(".clsimgSig").attr("src", "");
      $(".clsimgSig").hide();
    }
  };

  const previewSignature = (index) => {
    if (sigPadRefs.current[index]) {
      const dataUrl = sigPadRefs.current[index].toDataURL();
      var data = sigPadRefs.current[index]
        .getTrimmedCanvas()
        .toDataURL("image/svg+xml");
      $("#divSig" + index).show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
      console.log("Signature Preview:", dataUrl);
      // You can do something with the dataUrl, like displaying it or saving it
    }
  };

  const saveSignature = async (index) => {
    const signaturePad = sigPadRefs.current[index];

    if (signaturePad && !signaturePad.isEmpty()) {
      try {
        // Generate the trimmed signature as a base64 string
        const trimmedDataUrl = signaturePad
          .getTrimmedCanvas()
          .toDataURL("image/svg+xml");

        // Prepare the JSON payload
        const payload = {
          signature: trimmedDataUrl,
        };

        // Send the signature data as JSON to the server
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}forms/form_signature_response`,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Check if response status is 200 and contains the expected data
        if (response.status === 200 && response.data.default) {
          // Extract the image URL from the response
          const imageUrl = response.data.default;

          // Update the form state with the new image URL
          setFormState((prevFormState) => ({
            ...prevFormState,
            [index]: imageUrl,
          }));

          // Clear any previous errors related to this signature
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            [index]: "",
          }));

          console.log(`Signature ${index + 1} saved successfully:`, imageUrl);
        } else {
          throw new Error(
            `Unexpected response format or status: ${response.status}`
          );
        }
      } catch (error) {
        // Handle errors and update state
        console.error(
          `Error uploading the signature for index ${index + 1}:`,
          error.message
        );

        // Optionally, update form errors state to show the error message
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [index]: error.message || "Failed to save signature",
        }));
        setErrorMessage("Failed to save signature. Please try again.");
        setTimeout(() => {
          setErrorMessage("");
        }, 2500);
      }
    } else {
      setErrorMessage("Signature is empty.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
      console.log(`Signature ${index + 1} is empty or does not exist.`);
    }
  };

  const saveChooseImage = async (index) => {
    const element = document.getElementById("styled-text-container" + index);
    if (element) {
      html2canvas(element, { scale: 2, backgroundColor: null }).then(
        async (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const payload = {
            signature: imgData,
          };

          // Send the signature data as JSON to the server
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}forms/form_signature_choose_response`,
            JSON.stringify(payload),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200 && response.data.default) {
            // Extract the image URL from the response
            const imageUrl = response.data.default;

            // Update the form state with the new image URL
            setFormState((prevFormState) => ({
              ...prevFormState,
              [index]: imageUrl,
            }));

            // Clear any previous errors related to this signature
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              [index]: "",
            }));

            console.log(`Signature ${index + 1} saved successfully:`, imageUrl);
          } else {
            throw new Error(
              `Unexpected response format or status: ${response.status}`
            );
          }
        }
      );
    } else {
      setErrorMessage("Signature is empty.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
      console.log(`Signature ${index + 1} is empty or does not exist.`);
    }
  };

  const downloadPdf = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url = ADMIN_URL + "files_data/form_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "File not found.",
      });
      /*setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);*/
    }
  };

  //Show Groups by sequence Number
  if (groups) {
    groups = groups.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
  }

  const currentGroup = groups && groups.length > 0 ? groups[currentStep] : null;
  //console.log("formData: ", formData);
  console.log("service_id: ", service_id);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="form-renderer-container">
        {viewFrom == "client-logged-in" ? (
          <>
            {currentGroup ? (
              <>
                <div class="card">
                  <div class="card-inner">
                    <div className="mb-5" style={{ background: "#fff" }}>
                      <div className="nk-kycfm-title">
                        <h5 className="title">
                          {formTitle || "Untitled Form"}
                        </h5>
                        {currentStep === 0 && (
                          <p className="sub-title">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: formDescription,
                              }}
                            />
                          </p>
                        )}
                      </div>
                      {service_data && service_data.length ? (
                        <div style={{
                          border: "1px solid #2222",
                          padding: "15px",
                          borderRadius: "10px",
                          marginTop: "10px"
                        }}>
                          <p style={{ marginBottom: '15px' }}>
                            By filling out this form, a sales order will be automatically created with the selected services.
                          </p>
                          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '16px' }}>
                            {service_data?.map(([id, name]) => (
                              <div key={id} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <input
                                  type="checkbox"
                                  id={`service-${id}`}
                                  checked={selectedServices.includes(id)}
                                  onChange={() => handleCheckboxChangeNew(id)}
                                  style={{ width: '16px', height: '16px' }}
                                />
                                <label htmlFor={`service-${id}`} style={{ marginBottom: "2px" }}>{name}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}


                    </div>
                    <form
                      action="#"
                      class="nk-wizard nk-wizard-simple is-alter wizard clearfix"
                      id="wizard-01"
                      role="application"
                      novalidate="novalidate"
                    >
                      <div class="steps clearfix">
                        <ul role="tablist" className="">
                          {groups.map((group, index) => {
                            const isCurrent = currentStep === index;
                            const isCompleted = currentStep > index;
                            const isDisabled = currentStep < index;

                            return (
                              <li
                                key={group.id}
                                role="tab"
                                className={`${isCurrent ? "current" : ""} ${isDisabled ? "disabled" : ""
                                  } ${index === 0 ? "first" : ""} ${index === groups.length - 1 ? "last" : ""
                                  } ${isCompleted ? "done" : ""}`}
                                aria-disabled={isDisabled}
                                aria-selected={isCurrent}
                                style={{ marginBottom: "0.725rem" }}
                              >
                                <div
                                  id={`wizard-01-t-${index}`}
                                  aria-controls={`wizard-01-p-${index}`}
                                >
                                  {isCurrent && (
                                    <span className="current-info audible">
                                      current step:{" "}
                                    </span>
                                  )}
                                  <span className="number">
                                    {String(index + 1).padStart(2, "0")}
                                  </span>
                                  <h5>{group.group_title}</h5>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <div className="nk-stepper-content">
                          <div className="nk-stepper-steps stepper-steps">
                            <div className="nk-kycfm">
                              <div className="">
                                {responseMessage && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: responseMessage,
                                    }}
                                  ></div>
                                )}
                                {errorMessage && (
                                  <div class="alert alert-danger alert-icon mb-3">
                                    <em class="icon ni ni-cross-circle"></em>{" "}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: errorMessage,
                                      }}
                                    ></div>
                                  </div>
                                )}
                                <div className="mb-3">
                                  <h5 className="title">
                                    {currentGroup.group_title}
                                  </h5>
                                  <p
                                    className="sub-title"
                                    dangerouslySetInnerHTML={{
                                      __html: currentGroup.group_desc
                                        ? currentGroup.group_desc.replace(
                                          /\n/g,
                                          "<br />"
                                        )
                                        : "",
                                    }}
                                  ></p>
                                </div>
                                <div className="row g-gs">
                                  {/*currentGroup.questions.map((questionId) => {
                          const question = formData[0].questions.find(
                            (q) => q.id === questionId
                          );
                          if (!question) {
                            return null;
                          }*/}

                                  {currentGroup.questions
                                    .map((questionId) => {
                                      // Find the corresponding question from formData
                                      const question =
                                        formData[0].questions.find(
                                          (q) => q.id === questionId
                                        );
                                      return question;
                                    })
                                    .filter(Boolean) // Remove any undefined values if a question is not found
                                    .sort(
                                      (a, b) =>
                                        a.sequenceNumber - b.sequenceNumber
                                    ) // Sort by sequence number
                                    .map((question) => {
                                      const value =
                                        formState[question.id] || "";

                                      return (
                                        <div
                                          key={question.id}
                                          className={
                                            question.answerType === "label"
                                              ? "question-container col-sm-12"
                                              : "question-container col-sm-6"
                                          }
                                        >
                                          <div className="form-group question-text mb-0">
                                            {question.answerType === "label" ? (
                                              <label
                                                className="form-label"
                                                style={{
                                                  fontSize: "1.275rem",
                                                }}
                                              >
                                                {question.questionText}{" "}
                                                {question.required && (
                                                  <sup className="text-danger">
                                                    *
                                                  </sup>
                                                )}
                                              </label>
                                            ) : (
                                              <label className="form-label">
                                                {question.questionText}{" "}
                                                {question.required && (
                                                  <sup className="text-danger">
                                                    *
                                                  </sup>
                                                )}
                                              </label>
                                            )}
                                            {question.imageUrl && (
                                              <>
                                                <a
                                                  onClick={() =>
                                                    downloadPdf(
                                                      question.imageUrl
                                                    )
                                                  }
                                                  style={{
                                                    float: "right",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <span class="badge badge-outline-primary">
                                                    <em class="icon ni ni-download"></em>{" "}
                                                    Download File
                                                  </span>
                                                </a>
                                              </>
                                            )}
                                          </div>

                                          {question.answerType === "input" && (
                                            <div className="form-group mb-0">
                                              <div className="form-control-wrap address_cont">
                                                {/* Phone validation */}
                                                {question.validationValue ===
                                                  "validation_phone" ? (
                                                  <PhoneInput
                                                    className="customStyle-phone_input-add_sharelink"
                                                    required
                                                    defaultCountry={"us"}
                                                    value={
                                                      formState[question.id] ||
                                                      ""
                                                    }
                                                    onChange={(value) =>
                                                      handleChangePhone(
                                                        question.id,
                                                        value
                                                      )
                                                    }
                                                    ref={phoneInputRef}
                                                    onFocus={handleFocus}
                                                  />
                                                ) : question.validationValue ===
                                                  "validation_fulladdress" ? (
                                                  <GooglePlacesAutocomplete
                                                    apiKey={
                                                      process.env
                                                        .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                                    }
                                                    selectProps={{
                                                      value: formState[
                                                        question.id
                                                      ]
                                                        ? {
                                                          label:
                                                            formState[
                                                            question.id
                                                            ],
                                                          value:
                                                            formState[
                                                            question.id
                                                            ],
                                                        }
                                                        : null,
                                                      onChange: (address) =>
                                                        handleAddressSelect(
                                                          question.id,
                                                          address
                                                        ),
                                                      placeholder: Address
                                                        ? null
                                                        : "Enter your address...",
                                                      styles: {
                                                        input: (provided) => ({
                                                          ...provided,
                                                          color: "black",
                                                          minHeigh: "40px",
                                                        }),
                                                        option: (provided) => ({
                                                          ...provided,
                                                          color: "black",
                                                        }),
                                                        singleValue: (
                                                          provided
                                                        ) => ({
                                                          ...provided,
                                                          color: "black",
                                                        }),
                                                        menu: (provided) => ({
                                                          ...provided,
                                                          zIndex: 9,
                                                        }),
                                                      },
                                                    }}
                                                  />
                                                ) : question.validationValue ===
                                                  "validation_country" ? (
                                                  <Select
                                                    id="_country"
                                                    className="customCountrySelect-addClient"
                                                    name="_country"
                                                    label="Country"
                                                    options={countryOptions}
                                                    value={country}
                                                    onChange={(
                                                      selectedOption
                                                    ) =>
                                                      handleChangeCountry(
                                                        question.id,
                                                        selectedOption
                                                      )
                                                    }
                                                    styles={{
                                                      control: (provided) => ({
                                                        ...provided,
                                                        minHeight: "45px",
                                                      }),
                                                      menu: (
                                                        provided,
                                                        state
                                                      ) => ({
                                                        ...provided,
                                                        zIndex: 9,
                                                      }),
                                                    }}
                                                    placeholder="Select Country"
                                                  />
                                                ) : (
                                                  <input
                                                    className="form-control form-control-lg"
                                                    onChange={(e) =>
                                                      handleChange(
                                                        question.id,
                                                        e.target.value,
                                                        question.inputFormat
                                                      )
                                                    }
                                                    value={value}
                                                    type="text"
                                                    maxLength={
                                                      question.maxLength || 100
                                                    }
                                                  />
                                                )}
                                              </div>
                                              <div
                                                className="helper-text"
                                                style={{ marginTop: "6px" }}
                                              >
                                                {question.maxLength && (
                                                  <small
                                                    className="text-muted"
                                                    style={{ display: "block" }}
                                                  >
                                                    <span
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                    >
                                                      •
                                                    </span>{" "}
                                                    Maximum length is{" "}
                                                    {question.maxLength}{" "}
                                                    characters.
                                                    <br />
                                                  </small>
                                                )}
                                                {question.inputFormat ===
                                                  "number_only" && (
                                                    <small
                                                      className="text-muted"
                                                      style={{ display: "block" }}
                                                    >
                                                      <span
                                                        style={{
                                                          marginRight: "5px",
                                                        }}
                                                      >
                                                        •
                                                      </span>{" "}
                                                      Only numbers are allowed
                                                      (e.g. 123456).
                                                      <br />
                                                    </small>
                                                  )}
                                                {question.inputFormat ===
                                                  "alphabet_only" && (
                                                    <small
                                                      className="text-muted"
                                                      style={{ display: "block" }}
                                                    >
                                                      <span
                                                        style={{
                                                          marginRight: "5px",
                                                        }}
                                                      >
                                                        •
                                                      </span>{" "}
                                                      Only alphabets are allowed
                                                      (e.g. JohnDoe).
                                                      <br />
                                                    </small>
                                                  )}
                                                {question.inputFormat ===
                                                  "alphanumeric" && (
                                                    <small
                                                      className="text-muted"
                                                      style={{ display: "block" }}
                                                    >
                                                      <span
                                                        style={{
                                                          marginRight: "5px",
                                                        }}
                                                      >
                                                        •
                                                      </span>{" "}
                                                      Only alphanumeric characters
                                                      are allowed (e.g. John123).
                                                      <br />
                                                    </small>
                                                  )}
                                                {question.inputFormat ===
                                                  "open" && (
                                                    <small
                                                      className="text-muted"
                                                      style={{ display: "block" }}
                                                    >
                                                      <span
                                                        style={{
                                                          marginRight: "5px",
                                                        }}
                                                      >
                                                        •
                                                      </span>{" "}
                                                      You can enter any
                                                      characters.
                                                      <br />
                                                    </small>
                                                  )}
                                              </div>

                                              {/* Display form errors if present */}
                                              {formErrors[question.id] && (
                                                <FormHelperText error>
                                                  {formErrors[question.id]}
                                                </FormHelperText>
                                              )}
                                            </div>
                                          )}
                                          {question.answerType ===
                                            "signature" && (
                                              <>
                                                <ul className="nav nav-tabs">
                                                  <li className="nav-item">
                                                    <a
                                                      className={
                                                        signatureType ===
                                                          "draw" ||
                                                          signatureType === ""
                                                          ? "nav-link active"
                                                          : "nav-link"
                                                      }
                                                      data-toggle="tab"
                                                      onClick={() =>
                                                        setSignatureType("draw")
                                                      }
                                                      href="#tabItem6"
                                                    >
                                                      <em className="icon ni ni-edit-alt"></em>
                                                      <span>Draw</span>
                                                    </a>
                                                  </li>
                                                  {/*<li className="nav-item">
                                      <a
                                        className={
                                          signatureType === "choose"
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        data-toggle="tab"
                                        onClick={() =>
                                          setSignatureType("choose")
                                        }
                                        href="#tabItem5"
                                      >
                                        <em className="icon ni ni-list-check"></em>
                                        <span>Choose</span>
                                      </a>
                                    </li>*/}
                                                </ul>
                                                <div className="tab-content">
                                                  <div
                                                    className={
                                                      signatureType === "choose"
                                                        ? "tab-pane active"
                                                        : "tab-pane"
                                                    }
                                                    id="tabItem5"
                                                  >
                                                    <div class="example-alert mb-4">
                                                      <div class="alert alert-light">
                                                        Pick a style for your
                                                        signature (Authorized
                                                        Person Name).
                                                      </div>
                                                    </div>
                                                    <div className="form-group mb-4">
                                                      <div className="form-control-wrap">
                                                        <input
                                                          className={`form-control form-control-lg`}
                                                          onChange={(e) =>
                                                            handleChangeSignature(
                                                              question.id,
                                                              e.target.value
                                                            )
                                                          }
                                                          onInput={(e) => {
                                                            e.target.value =
                                                              e.target.value.replace(
                                                                /[^a-zA-Z0-9 ]/gi,
                                                                ""
                                                              );
                                                          }}
                                                          value={signatureText}
                                                          type="text"
                                                          maxLength={100}
                                                          placeholder="Authorized Person Name"
                                                        />
                                                      </div>
                                                      {formErrors[
                                                        question.id
                                                      ] && (
                                                          <FormHelperText error>
                                                            {
                                                              formErrors[
                                                              question.id
                                                              ]
                                                            }
                                                          </FormHelperText>
                                                        )}
                                                    </div>

                                                    <div class="row">
                                                      <div className="col-sm-6">
                                                        <h6 className="title">
                                                          Choose Style
                                                        </h6>

                                                        <ul className="cc-pay-method">
                                                          <li className="cc-pay-dd dropdown">
                                                            <a
                                                              href="#"
                                                              className="btn btn-white btn-outline-light dropdown-toggle dropdown-indicator"
                                                              data-toggle="dropdown"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontFamily:
                                                                    signatureClass,
                                                                }}
                                                              >
                                                                {signatureText ||
                                                                  "Your Signature"}
                                                              </span>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-auto">
                                                              <ul className="link-list-plain">
                                                                {cursiveFonts.map(
                                                                  (
                                                                    font,
                                                                    index
                                                                  ) => (
                                                                    <li
                                                                      key={index}
                                                                    >
                                                                      <a
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                          setSignatureClass(
                                                                            font.value
                                                                          )
                                                                        }
                                                                        className="cc-pay-item"
                                                                      >
                                                                        <span className="cc-pay-item-name">
                                                                          <span
                                                                            className="cc-pay-item-method"
                                                                            style={{
                                                                              fontFamily:
                                                                                font.value,
                                                                            }}
                                                                          >
                                                                            {signatureText !==
                                                                              ""
                                                                              ? signatureText
                                                                              : font.name}
                                                                          </span>
                                                                        </span>
                                                                      </a>
                                                                    </li>
                                                                  )
                                                                )}
                                                              </ul>
                                                            </div>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                      <div className="col-sm-6">
                                                        <div
                                                          className="signature-preview"
                                                          id={`styled-text-container${question.id}`}
                                                          style={{
                                                            fontFamily:
                                                              signatureClass,
                                                            fontSize: "40px",

                                                            height: "80px",
                                                            background:
                                                              "trasnparent",
                                                            backgroundColor:
                                                              "transparent",
                                                            border: "none",
                                                            lineHeight: "80px",
                                                            color: "#000",
                                                          }}
                                                        >
                                                          {signatureText ||
                                                            "Your Signature"}
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="col-sm-12 mt-4 pl-0"
                                                      style={{
                                                        clear: "both",
                                                      }}
                                                    >
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        style={{
                                                          margin:
                                                            "13px 0px 12px 0px",
                                                        }}
                                                        onClick={() =>
                                                          saveChooseImage(
                                                            question.id
                                                          )
                                                        }
                                                      >
                                                        <em class="icon ni ni-folder-check"></em>{" "}
                                                        <span>
                                                          Save Signature
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={
                                                      signatureType === "draw" ||
                                                        signatureType === ""
                                                        ? "tab-pane active"
                                                        : "tab-pane"
                                                    }
                                                    id="tabItem6"
                                                  >
                                                    <div className="row">
                                                      <div className="col-sm-12">
                                                        <div class="example-alert mb-4">
                                                          <div class="alert alert-light">
                                                            Please Save the
                                                            signature to proceed.
                                                          </div>
                                                        </div>
                                                        {formState[
                                                          question.id
                                                        ] && (
                                                            <div className="uploaded-file-preview">
                                                              <img
                                                                src={`${APP_LIVE_URL}files_data/form_response_uploads/${formState[
                                                                  question.id
                                                                ]
                                                                  }`}
                                                                alt="Uploaded Signature"
                                                                style={{
                                                                  maxWidth: "200px",
                                                                }}
                                                              />
                                                            </div>
                                                          )}
                                                        <div
                                                          id={`divSig${question.id}`}
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        >
                                                          <div class="mb-4">
                                                            <img
                                                              src=""
                                                              class="clsimgSig"
                                                              height="100"
                                                              width="200"
                                                              onerror="this.style.display='none'"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div>
                                                          <strong>
                                                            Signature
                                                          </strong>
                                                        </div>
                                                      </div>

                                                      <div className="col-sm-12">
                                                        <SignatureCanvas
                                                          clearOnResize={true}
                                                          canvasProps={{
                                                            className:
                                                              styles.sigPad,
                                                          }}
                                                          velocityFilterWeight={
                                                            0.7
                                                          }
                                                          throttle={50}
                                                          ref={(ref) =>
                                                          (sigPadRefs.current[
                                                            question.id
                                                          ] = ref)
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-sm-12 mt-4">
                                                        <button
                                                          className="btn btn-warning"
                                                          style={{
                                                            margin:
                                                              "13px 0px 12px 0px",
                                                          }}
                                                          onClick={() =>
                                                            clearSignature(
                                                              question.id
                                                            )
                                                          }
                                                          type="button"
                                                        >
                                                          <em class="icon ni ni-delete"></em>{" "}
                                                          <span>Clear</span>
                                                        </button>
                                                        {/*<button
                                            className="btn btn-success"
                                            style={{
                                              margin: "13px 0px 12px 10px",
                                            }}
                                            type="button"
                                            onClick={() =>
                                              previewSignature(question.id)
                                            }
                                          >
                                            <em class="icon ni ni-eye"></em>{" "}
                                            <span>Preview</span>
                                          </button>*/}
                                                        <button
                                                          type="button"
                                                          className="btn btn-primary"
                                                          style={{
                                                            margin:
                                                              "13px 0px 12px 10px",
                                                          }}
                                                          onClick={() =>
                                                            saveSignature(
                                                              question.id
                                                            )
                                                          }
                                                        >
                                                          <em class="icon ni ni-folder-check"></em>{" "}
                                                          <span>
                                                            Save Signature
                                                          </span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          {question.answerType === "fixed" && (
                                            <>
                                              <div className="form-group mb-0">
                                                <div className="form-control-wrap">
                                                  <input
                                                    className={`form-control form-control-lg`}
                                                    onChange={(e) =>
                                                      handleChangeFixed(
                                                        question.id,
                                                        question.answer
                                                      )
                                                    }
                                                    value={question.answer}
                                                    type="text"
                                                    maxLength={255}
                                                  />
                                                </div>
                                                {formErrors[question.id] && (
                                                  <FormHelperText error>
                                                    {formErrors[question.id]}
                                                  </FormHelperText>
                                                )}
                                              </div>
                                              <CopyToClipboard
                                                text={question.answer}
                                                onCopy={() => setCopied(true)}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <div class="form-note text-primary">
                                                  <strong>
                                                    Copy to Clipboard{" "}
                                                    {copied ? (
                                                      <span>
                                                        <em className="icon ni ni-done"></em>
                                                      </span>
                                                    ) : null}
                                                  </strong>
                                                </div>
                                              </CopyToClipboard>
                                            </>
                                          )}
                                          {question.answerType === "label" && (
                                            <div className="form-group mb-0">
                                              <h6></h6>
                                            </div>
                                          )}
                                          {question.answerType ===
                                            "textarea" && (
                                              <div className="form-group">
                                                <div className="form-control-wrap">
                                                  <textarea
                                                    className={`form-control form-control-lg`}
                                                    onChange={(e) =>
                                                      handleChange(
                                                        question.id,
                                                        e.target.value
                                                      )
                                                    }
                                                    placeholder=""
                                                    value={value}
                                                    maxLength={
                                                      question.maxLength || 500
                                                    }
                                                  ></textarea>
                                                </div>
                                                <div
                                                  className="helper-text"
                                                  style={{ marginTop: "6px" }}
                                                >
                                                  {question.maxLength && (
                                                    <small
                                                      className="text-muted"
                                                      style={{ display: "block" }}
                                                    >
                                                      <span
                                                        style={{
                                                          marginRight: "5px",
                                                        }}
                                                      >
                                                        •
                                                      </span>{" "}
                                                      Maximum length is{" "}
                                                      {question.maxLength}{" "}
                                                      characters.
                                                      <br />
                                                    </small>
                                                  )}
                                                  {question.inputFormat ===
                                                    "number_only" && (
                                                      <small
                                                        className="text-muted"
                                                        style={{ display: "block" }}
                                                      >
                                                        <span
                                                          style={{
                                                            marginRight: "5px",
                                                          }}
                                                        >
                                                          •
                                                        </span>{" "}
                                                        Only numbers are allowed
                                                        (e.g. 123456).
                                                        <br />
                                                      </small>
                                                    )}
                                                  {question.inputFormat ===
                                                    "alphabet_only" && (
                                                      <small
                                                        className="text-muted"
                                                        style={{ display: "block" }}
                                                      >
                                                        <span
                                                          style={{
                                                            marginRight: "5px",
                                                          }}
                                                        >
                                                          •
                                                        </span>{" "}
                                                        Only alphabets are allowed
                                                        (e.g. JohnDoe).
                                                        <br />
                                                      </small>
                                                    )}
                                                  {question.inputFormat ===
                                                    "alphanumeric" && (
                                                      <small
                                                        className="text-muted"
                                                        style={{ display: "block" }}
                                                      >
                                                        <span
                                                          style={{
                                                            marginRight: "5px",
                                                          }}
                                                        >
                                                          •
                                                        </span>{" "}
                                                        Only alphanumeric characters
                                                        are allowed (e.g. John123).
                                                        <br />
                                                      </small>
                                                    )}
                                                  {question.inputFormat ===
                                                    "open" && (
                                                      <small
                                                        className="text-muted"
                                                        style={{ display: "block" }}
                                                      >
                                                        <span
                                                          style={{
                                                            marginRight: "5px",
                                                          }}
                                                        >
                                                          •
                                                        </span>{" "}
                                                        You can enter any
                                                        characters.
                                                        <br />
                                                      </small>
                                                    )}
                                                </div>
                                                {formErrors[question.id] && (
                                                  <FormHelperText error>
                                                    {formErrors[question.id]}
                                                  </FormHelperText>
                                                )}
                                              </div>
                                            )}
                                          {question.answerType ===
                                            "datetime" && (
                                              <div className="form-group">
                                                <DateTimePicker
                                                  variant="inline"
                                                  inputVariant="outlined"
                                                  fullWidth
                                                  value={
                                                    formState[question.id] || null
                                                  }
                                                  onChange={(date) =>
                                                    handleChange(
                                                      question.id,
                                                      date
                                                    )
                                                  }
                                                />
                                                {formErrors[question.id] && (
                                                  <FormHelperText error>
                                                    {formErrors[question.id]}
                                                  </FormHelperText>
                                                )}
                                              </div>
                                            )}
                                          {question.answerType === "date" && (
                                            <div className="form-group">
                                              <DatePicker
                                                variant="inline"
                                                inputVariant="outlined"
                                                fullWidth
                                                value={
                                                  formState[question.id] || null
                                                }
                                                onChange={(date) =>
                                                  handleChange(
                                                    question.id,
                                                    date
                                                  )
                                                }
                                              />
                                              {formErrors[question.id] && (
                                                <FormHelperText error>
                                                  {formErrors[question.id]}
                                                </FormHelperText>
                                              )}
                                            </div>
                                          )}
                                          {question.answerType === "time" && (
                                            <div className="form-group">
                                              <TimePicker
                                                variant="inline"
                                                inputVariant="outlined"
                                                fullWidth
                                                value={
                                                  formState[question.id] || null
                                                }
                                                onChange={(time) =>
                                                  handleChange(
                                                    question.id,
                                                    time
                                                  )
                                                }
                                              />
                                              {formErrors[question.id] && (
                                                <FormHelperText error>
                                                  {formErrors[question.id]}
                                                </FormHelperText>
                                              )}
                                            </div>
                                          )}
                                          {question.answerType ===
                                            "dropdown" && (
                                              <div className="form-group">
                                                <div className="form-control-wrap">
                                                  <div className="form-control-select">
                                                    <select
                                                      className={`form-control form-control-lg`}
                                                      onChange={(e) =>
                                                        handleChange(
                                                          question.id,
                                                          e.target.value
                                                        )
                                                      }
                                                      value={value}
                                                    >
                                                      <option value="">
                                                        Select an option
                                                      </option>
                                                      {question.options.map(
                                                        (option, index) => (
                                                          <option
                                                            key={index}
                                                            value={option}
                                                          >
                                                            {option}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </div>
                                                </div>
                                                {formErrors[question.id] && (
                                                  <FormHelperText error>
                                                    {formErrors[question.id]}
                                                  </FormHelperText>
                                                )}
                                              </div>
                                            )}
                                          {question.answerType ===
                                            "checkbox" && (
                                              <>
                                                <div className="mt-2 prettyprint ">
                                                  <div className="row">
                                                    {question.options.map(
                                                      (option, index) => (
                                                        <div
                                                          className="col-md-6 mb-1"
                                                          key={`options_${index}`}
                                                        >
                                                          <div className="custom-control custom-control-md custom-checkbox custom-control-pro">
                                                            <input
                                                              type="checkbox"
                                                              className="custom-control-input vdrSelected"
                                                              id={`question_checkbox_${question.id}_${index}`}
                                                              checked={
                                                                formState[
                                                                  question.id
                                                                ]?.includes(
                                                                  option
                                                                ) || false
                                                              }
                                                              onChange={(e) =>
                                                                handleCheckboxChange(
                                                                  question.id,
                                                                  option,
                                                                  e.target.checked
                                                                )
                                                              }
                                                            />
                                                            <label
                                                              className="custom-control-label"
                                                              htmlFor={`question_checkbox_${question.id}_${index}`}
                                                            >
                                                              {option}
                                                            </label>
                                                          </div>
                                                          {formErrors[
                                                            question.id
                                                          ] && (
                                                              <FormHelperText error>
                                                                {
                                                                  formErrors[
                                                                  question.id
                                                                  ]
                                                                }
                                                              </FormHelperText>
                                                            )}
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                            )}

                                          {question.answerType === "radio" && (
                                            <div className="form-pm-group">
                                              <ul className="buysell-pm-list">
                                                {question.options.map(
                                                  (option, index) => (
                                                    <li
                                                      className="buysell-pm-item"
                                                      key={index}
                                                    >
                                                      <input
                                                        className="buysell-pm-control"
                                                        type="radio"
                                                        name={`radio_${question.id}`}
                                                        id={`radio_${question.id}_${index}`}
                                                        onChange={(e) =>
                                                          handleChange(
                                                            question.id,
                                                            option
                                                          )
                                                        }
                                                        value={option}
                                                      />
                                                      <label
                                                        className="buysell-pm-label"
                                                        htmlFor={`radio_${question.id}_${index}`}
                                                      >
                                                        <span className="pm-name">
                                                          {option}
                                                        </span>
                                                        <span className="pm-icon">
                                                          <em className="icon ni ni-question"></em>
                                                        </span>
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                              {formErrors[question.id] && (
                                                <FormHelperText error>
                                                  {formErrors[question.id]}
                                                </FormHelperText>
                                              )}
                                            </div>
                                          )}
                                          {question.answerType ===
                                            "image_input" && (
                                              <div className="form-group">
                                                <div
                                                  className="form-control-group"
                                                  id="fileContainer"
                                                >
                                                  <input
                                                    type="file"
                                                    id={`add_picture_${question.id}`}
                                                    className="file-block"
                                                    name="add_picture"
                                                    accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlx, .xlxs, .csv"
                                                    onChange={(e) =>
                                                      handleFileChange(
                                                        question.id,
                                                        e
                                                      )
                                                    }
                                                  />
                                                  {/* Display the uploaded file name or image preview if the URL exists in the state */}
                                                  {formState[question.id] && (
                                                    <div className="uploaded-file-preview">
                                                      {formState[
                                                        question.id
                                                      ].match(
                                                        /\.(jpeg|jpg|png)$/i
                                                      ) ? (
                                                        <img
                                                          src={`${APP_LIVE_URL}files_data/form_response_uploads/${formState[question.id]
                                                            }`}
                                                          alt="Uploaded preview"
                                                          style={{
                                                            maxWidth: "100px",
                                                          }}
                                                        />
                                                      ) : (
                                                        <p>
                                                          {formState[question.id]
                                                            .split("/")
                                                            .pop()}
                                                        </p> // Display file name for non-images
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                                {formErrors[question.id] && (
                                                  <FormHelperText error>
                                                    {formErrors[question.id]}
                                                  </FormHelperText>
                                                )}
                                              </div>
                                            )}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="actions clearfix border-top"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "30px",
                        }}
                      >
                        <ul
                          role="menu"
                          aria-label="Pagination"
                          className="mt-4"
                        >
                          {currentStep > 0 && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => setCurrentStep(currentStep - 1)}
                            >
                              Back
                            </Button>
                          )}
                          {currentStep < groups.length - 1 ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNextStep}
                              style={{ marginLeft: "10px" }}
                              disabled={loading}
                            >
                              Save & Continue
                            </Button>
                          ) : (
                            <>
                              {allow_submit === 1 && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleSubmit}
                                  style={{ marginLeft: "10px" }}
                                  disabled={loading}
                                >
                                  {loading ? "Loading..." : "Save & Finish"}
                                </Button>
                              )}
                              {allow_submit === 0 && (
                                <button
                                  type="button"
                                  class="btn btn-outline-warning"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Submission is disabled for this form.
                                </button>
                              )}
                            </>
                          )}
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              hasUngroupedQuestions && (
                <>
                  <div className="nk-block nk-block-lg">
                    <div className="card">
                      <form
                        className="nk-stepper stepper-init is-alter nk-stepper-s1"
                        action="#"
                        id="stepper-create-project"
                        data-step-current="2"
                        noValidate
                        style={{ display: "block" }}
                      >
                        <div className="row g-0 col-sep col-sep-md col-sep-xl">
                          <div className="col-md-3 col-xl-3">
                            <div className="card-inner">
                              <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                                {groups.map((group, index) => (
                                  <li
                                    key={group.id}
                                    className={
                                      currentStep === index
                                        ? "current"
                                        : currentStep > index
                                          ? "done"
                                          : ""
                                    }
                                  >
                                    <div className="step-item">
                                      <div className="step-text">
                                        <div className="lead-text">
                                          {group.group_title}
                                        </div>
                                        <div
                                          className="sub-text"
                                          dangerouslySetInnerHTML={{
                                            __html: group.group_desc,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div className="col-md-9 col-xl-9">
                            <div className="card-inner">
                              <div className="nk-stepper-content">
                                <div className="nk-stepper-steps stepper-steps">
                                  <div className="nk-kycfm">
                                    <div
                                      className="nk-kycfm-head"
                                      style={{ background: "#f5f6fa" }}
                                    >
                                      <div className="nk-kycfm-title">
                                        <h5 className="title">
                                          {formTitle || "Untitled Form"}
                                        </h5>
                                        <p className="sub-title">
                                          {formDescription || "Untitled Form"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="nk-kycfm-content">
                                      {responseMessage && (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: responseMessage,
                                          }}
                                        ></div>
                                      )}
                                      {errorMessage && (
                                        <div class="alert alert-danger alert-icon mb-3">
                                          <em class="icon ni ni-cross-circle"></em>{" "}
                                          Please fill out all the required
                                          questions!
                                        </div>
                                      )}
                                      <div className="row g-gs">
                                        {ungroupedQuestions.map((question) => (
                                          <div
                                            key={question.id}
                                            className="question-container col-sm-6"
                                          >
                                            <div className="form-group question-text mb-0">
                                              <label className="form-label">
                                                {question.questionText}{" "}
                                                {question.required && (
                                                  <sup className="text-danger">
                                                    *
                                                  </sup>
                                                )}
                                              </label>
                                            </div>
                                            {question.imageUrl && (
                                              <img
                                                src={question.imageUrl}
                                                alt="Admin Uploaded"
                                                className="question-image"
                                              />
                                            )}
                                            {question.answerType ===
                                              "input" && (
                                                <div className="form-group mb-0">
                                                  <div className="form-control-wrap">
                                                    <input
                                                      className={`form-control form-control-lg`}
                                                      onChange={(e) =>
                                                        handleChange(
                                                          question.id,
                                                          e.target.value
                                                        )
                                                      }
                                                      type="text"
                                                      maxLength={
                                                        question.maxLength || 100
                                                      }
                                                    />
                                                  </div>
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            {question.answerType ===
                                              "textarea" && (
                                                <div className="form-group">
                                                  <div className="form-control-wrap">
                                                    <textarea
                                                      className={`form-control form-control-lg`}
                                                      onChange={(e) =>
                                                        handleChange(
                                                          question.id,
                                                          e.target.value
                                                        )
                                                      }
                                                      placeholder=""
                                                      maxLength={
                                                        question.maxLength || 500
                                                      }
                                                    ></textarea>
                                                  </div>
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            {question.answerType ===
                                              "datetime" && (
                                                <div className="form-group">
                                                  <DateTimePicker
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    value={
                                                      formState[question.id] ||
                                                      null
                                                    }
                                                    onChange={(date) =>
                                                      handleChange(
                                                        question.id,
                                                        date
                                                      )
                                                    }
                                                  />
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            {question.answerType === "date" && (
                                              <div className="form-group">
                                                <DatePicker
                                                  variant="inline"
                                                  inputVariant="outlined"
                                                  fullWidth
                                                  value={
                                                    formState[question.id] ||
                                                    null
                                                  }
                                                  onChange={(date) =>
                                                    handleChange(
                                                      question.id,
                                                      date
                                                    )
                                                  }
                                                />
                                                {formErrors[question.id] && (
                                                  <FormHelperText error>
                                                    {formErrors[question.id]}
                                                  </FormHelperText>
                                                )}
                                              </div>
                                            )}
                                            {question.answerType === "time" && (
                                              <div className="form-group">
                                                <TimePicker
                                                  variant="inline"
                                                  inputVariant="outlined"
                                                  fullWidth
                                                  value={
                                                    formState[question.id] ||
                                                    null
                                                  }
                                                  onChange={(time) =>
                                                    handleChange(
                                                      question.id,
                                                      time
                                                    )
                                                  }
                                                />
                                                {formErrors[question.id] && (
                                                  <FormHelperText error>
                                                    {formErrors[question.id]}
                                                  </FormHelperText>
                                                )}
                                              </div>
                                            )}
                                            {question.answerType ===
                                              "dropdown" && (
                                                <div className="form-group">
                                                  <div className="form-control-wrap">
                                                    <div className="form-control-select">
                                                      <select
                                                        className={`form-control form-control-lg`}
                                                        onChange={(e) =>
                                                          handleChange(
                                                            question.id,
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option value="">
                                                          Select an option
                                                        </option>
                                                        {question.options.map(
                                                          (option, index) => (
                                                            <option
                                                              key={index}
                                                              value={option}
                                                            >
                                                              {option}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    </div>
                                                  </div>
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            {question.answerType ===
                                              "checkbox" && (
                                                <>
                                                  <div className="mt-2 prettyprint ">
                                                    <div className="row">
                                                      {question.options.map(
                                                        (option, index) => (
                                                          <div
                                                            className="col-md-6 mb-1"
                                                            key={`options_${index}`}
                                                          >
                                                            <div className="custom-control custom-control-md custom-checkbox custom-control-pro">
                                                              <input
                                                                type="checkbox"
                                                                className="custom-control-input vdrSelected"
                                                                id={`question_checkbox_${question.id}_${index}`}
                                                                checked={
                                                                  formState[
                                                                    question.id
                                                                  ]?.includes(
                                                                    option
                                                                  ) || false
                                                                }
                                                                onChange={(e) =>
                                                                  handleCheckboxChange(
                                                                    question.id,
                                                                    option,
                                                                    e.target
                                                                      .checked
                                                                  )
                                                                }
                                                              />
                                                              <label
                                                                className="custom-control-label"
                                                                htmlFor={`question_checkbox_${question.id}_${index}`}
                                                              >
                                                                {option}
                                                              </label>
                                                            </div>
                                                            {formErrors[
                                                              question.id
                                                            ] && (
                                                                <FormHelperText
                                                                  error
                                                                >
                                                                  {
                                                                    formErrors[
                                                                    question.id
                                                                    ]
                                                                  }
                                                                </FormHelperText>
                                                              )}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              )}

                                            {question.answerType ===
                                              "radio" && (
                                                <div className="form-pm-group">
                                                  <ul className="buysell-pm-list">
                                                    {question.options.map(
                                                      (option, index) => (
                                                        <li
                                                          className="buysell-pm-item"
                                                          key={index}
                                                        >
                                                          <input
                                                            className="buysell-pm-control"
                                                            type="radio"
                                                            name={`radio_${question.id}`}
                                                            id={`radio_${question.id}_${index}`}
                                                            onChange={(e) =>
                                                              handleChange(
                                                                question.id,
                                                                option
                                                              )
                                                            }
                                                          />
                                                          <label
                                                            className="buysell-pm-label"
                                                            htmlFor={`radio_${question.id}_${index}`}
                                                          >
                                                            <span className="pm-name">
                                                              {option}
                                                            </span>
                                                            <span className="pm-icon">
                                                              <em className="icon ni ni-question"></em>
                                                            </span>
                                                          </label>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            {question.answerType ===
                                              "image_input" && (
                                                <div className="form-group">
                                                  <div
                                                    className="form-control-group"
                                                    id="fileContainer"
                                                  >
                                                    <input
                                                      type="file"
                                                      id={`add_picture_${question.id}`}
                                                      className="file-block"
                                                      name="add_picture"
                                                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlx, .xlxs, .csv"
                                                      onChange={(e) =>
                                                        handleFileChange(
                                                          question.id,
                                                          e
                                                        )
                                                      }
                                                    />
                                                    {/* Display the uploaded file name or image preview if the URL exists in the state */}
                                                    {formState[question.id] && (
                                                      <div className="uploaded-file-preview">
                                                        {formState[
                                                          question.id
                                                        ].match(
                                                          /\.(jpeg|jpg|png)$/i
                                                        ) ? (
                                                          <img
                                                            src={`${APP_LIVE_URL}files_data/form_response_uploads/${formState[
                                                              question.id
                                                            ]
                                                              }`}
                                                            alt="Uploaded preview"
                                                            style={{
                                                              maxWidth: "100px",
                                                            }}
                                                          />
                                                        ) : (
                                                          <p>
                                                            {formState[
                                                              question.id
                                                            ]
                                                              .split("/")
                                                              .pop()}
                                                          </p> // Display file name for non-images
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "20px",
                              }}
                            >
                              {allow_submit === 1 && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleSubmit}
                                  style={{ marginLeft: "10px" }}
                                >
                                  Submit
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )
            )}
          </>
        ) : viewFrom == "client-not-logged-in" ? (
          <>
            {currentGroup ? (
              <>
                <div className="nk-block nk-block-lg">
                  <div className="card">
                    <form
                      className="nk-stepper stepper-init is-alter nk-stepper-s1"
                      action="#"
                      id="stepper-create-project"
                      data-step-current="2"
                      noValidate
                      style={{ display: "block" }}
                    >
                      <div className="row g-0 col-sep col-sep-md col-sep-xl">
                        <div className="col-md-3 col-xl-3">
                          <div className="card-inner">
                            <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                              {groups.map((group, index) => (
                                <li
                                  key={group.id}
                                  className={
                                    currentStep === index
                                      ? "current"
                                      : currentStep > index
                                        ? "done"
                                        : ""
                                  }
                                  style={{ marginBottom: "0.725rem" }}
                                >
                                  <div
                                    className="step-item"
                                    data-group-number={index + 1}
                                  >
                                    <div className="step-text">
                                      <div className="lead-text">
                                        {group.group_title}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div
                          className="col-md-9 col-xl-9 p-0"
                          style={{ borderLeft: "1px solid #e5e9f2" }}
                        >
                          <div className="card-inner p-0">
                            <div className="nk-stepper-content">
                              <div className="nk-stepper-steps stepper-steps">
                                <div className="nk-kycfm">
                                  <div
                                    className="nk-kycfm-head"
                                    style={{ background: "#fff" }}
                                  >
                                    <div className="nk-kycfm-title">
                                      <h5 className="title">
                                        {formTitle || "Untitled Form"}
                                      </h5>
                                      {currentStep === 0 && (
                                        <p className="sub-title">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: formDescription,
                                            }}
                                          />
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="nk-kycfm-content">
                                    {responseMessage && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: responseMessage,
                                        }}
                                      ></div>
                                    )}
                                    {errorMessage && (
                                      <div class="alert alert-danger alert-icon mb-3">
                                        <em class="icon ni ni-cross-circle"></em>{" "}
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: errorMessage,
                                          }}
                                        ></div>
                                      </div>
                                    )}
                                    <div className="mb-3">
                                      <h5 className="title">
                                        {currentGroup.group_title}
                                      </h5>
                                      <p
                                        className="sub-title"
                                        dangerouslySetInnerHTML={{
                                          __html: currentGroup.group_desc
                                            ? currentGroup.group_desc.replace(
                                              /\n/g,
                                              "<br />"
                                            )
                                            : "",
                                        }}
                                      ></p>
                                    </div>
                                    <div className="row g-gs">
                                      {/*currentGroup.questions.map((questionId) => {
                          const question = formData[0].questions.find(
                            (q) => q.id === questionId
                          );
                          if (!question) {
                            return null;
                          }*/}

                                      {currentGroup.questions
                                        .map((questionId) => {
                                          // Find the corresponding question from formData
                                          const question =
                                            formData[0].questions.find(
                                              (q) => q.id === questionId
                                            );
                                          return question;
                                        })
                                        .filter(Boolean) // Remove any undefined values if a question is not found
                                        .sort(
                                          (a, b) =>
                                            a.sequenceNumber - b.sequenceNumber
                                        ) // Sort by sequence number
                                        .map((question) => {
                                          const value =
                                            formState[question.id] || "";

                                          return (
                                            <div
                                              key={question.id}
                                              className={
                                                question.answerType === "label"
                                                  ? "question-container col-sm-12"
                                                  : "question-container col-sm-6"
                                              }
                                            >
                                              <div className="form-group question-text mb-0">
                                                {question.answerType ===
                                                  "label" ? (
                                                  <label
                                                    className="form-label"
                                                    style={{
                                                      fontSize: "1.275rem",
                                                    }}
                                                  >
                                                    {question.questionText}{" "}
                                                    {question.required && (
                                                      <sup className="text-danger">
                                                        *
                                                      </sup>
                                                    )}
                                                  </label>
                                                ) : (
                                                  <label className="form-label">
                                                    {question.questionText}{" "}
                                                    {question.required && (
                                                      <sup className="text-danger">
                                                        *
                                                      </sup>
                                                    )}
                                                  </label>
                                                )}
                                                {question.imageUrl && (
                                                  <>
                                                    <a
                                                      onClick={() =>
                                                        downloadPdf(
                                                          question.imageUrl
                                                        )
                                                      }
                                                      style={{
                                                        float: "right",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <span class="badge badge-outline-primary">
                                                        <em class="icon ni ni-download"></em>{" "}
                                                        Download File
                                                      </span>
                                                    </a>
                                                  </>
                                                )}
                                              </div>

                                              {question.answerType ===
                                                "input" && (
                                                  <div className="form-group mb-0">
                                                    <div className="form-control-wrap address_cont">
                                                      {/* Phone validation */}
                                                      {question.validationValue ===
                                                        "validation_phone" ? (
                                                        <PhoneInput
                                                          className="customStyle-phone_input-add_sharelink"
                                                          required
                                                          defaultCountry={"us"}
                                                          value={
                                                            formState[
                                                            question.id
                                                            ] || ""
                                                          }
                                                          onChange={(value) =>
                                                            handleChangePhone(
                                                              question.id,
                                                              value
                                                            )
                                                          }
                                                          ref={phoneInputRef}
                                                          onFocus={handleFocus}
                                                        />
                                                      ) : question.validationValue ===
                                                        "validation_fulladdress" ? (
                                                        <GooglePlacesAutocomplete
                                                          apiKey={
                                                            process.env
                                                              .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                                          }
                                                          selectProps={{
                                                            value: formState[
                                                              question.id
                                                            ]
                                                              ? {
                                                                label:
                                                                  formState[
                                                                  question.id
                                                                  ],
                                                                value:
                                                                  formState[
                                                                  question.id
                                                                  ],
                                                              }
                                                              : null,
                                                            onChange: (address) =>
                                                              handleAddressSelect(
                                                                question.id,
                                                                address
                                                              ),
                                                            placeholder: Address
                                                              ? null
                                                              : "Enter your address...",
                                                            styles: {
                                                              input: (
                                                                provided
                                                              ) => ({
                                                                ...provided,
                                                                color: "black",
                                                                minHeigh: "40px",
                                                              }),
                                                              option: (
                                                                provided
                                                              ) => ({
                                                                ...provided,
                                                                color: "black",
                                                              }),
                                                              singleValue: (
                                                                provided
                                                              ) => ({
                                                                ...provided,
                                                                color: "black",
                                                              }),
                                                              menu: (
                                                                provided
                                                              ) => ({
                                                                ...provided,
                                                                zIndex: 9,
                                                              }),
                                                            },
                                                          }}
                                                        />
                                                      ) : question.validationValue ===
                                                        "validation_country" ? (
                                                        <Select
                                                          id="_country"
                                                          className="customCountrySelect-addClient"
                                                          name="_country"
                                                          label="Country"
                                                          options={countryOptions}
                                                          value={country}
                                                          onChange={(
                                                            selectedOption
                                                          ) =>
                                                            handleChangeCountry(
                                                              question.id,
                                                              selectedOption
                                                            )
                                                          }
                                                          styles={{
                                                            control: (
                                                              provided
                                                            ) => ({
                                                              ...provided,
                                                              minHeight: "45px",
                                                            }),
                                                            menu: (
                                                              provided,
                                                              state
                                                            ) => ({
                                                              ...provided,
                                                              zIndex: 9,
                                                            }),
                                                          }}
                                                          placeholder="Select Country"
                                                        />
                                                      ) : (
                                                        <input
                                                          className="form-control form-control-lg"
                                                          onChange={(e) =>
                                                            handleChange(
                                                              question.id,
                                                              e.target.value
                                                            )
                                                          }
                                                          value={value}
                                                          type="text"
                                                          maxLength={
                                                            question.maxLength ||
                                                            100
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                    <div
                                                      className="helper-text"
                                                      style={{ marginTop: "6px" }}
                                                    >
                                                      {question.maxLength && (
                                                        <small
                                                          className="text-muted"
                                                          style={{
                                                            display: "block",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              marginRight: "5px",
                                                            }}
                                                          >
                                                            •
                                                          </span>{" "}
                                                          Maximum length is{" "}
                                                          {question.maxLength}{" "}
                                                          characters.
                                                          <br />
                                                        </small>
                                                      )}
                                                      {question.inputFormat ===
                                                        "number_only" && (
                                                          <small
                                                            className="text-muted"
                                                            style={{
                                                              display: "block",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                marginRight: "5px",
                                                              }}
                                                            >
                                                              •
                                                            </span>{" "}
                                                            Only numbers are allowed
                                                            (e.g. 123456).
                                                            <br />
                                                          </small>
                                                        )}
                                                      {question.inputFormat ===
                                                        "alphabet_only" && (
                                                          <small
                                                            className="text-muted"
                                                            style={{
                                                              display: "block",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                marginRight: "5px",
                                                              }}
                                                            >
                                                              •
                                                            </span>{" "}
                                                            Only alphabets are
                                                            allowed (e.g. JohnDoe).
                                                            <br />
                                                          </small>
                                                        )}
                                                      {question.inputFormat ===
                                                        "alphanumeric" && (
                                                          <small
                                                            className="text-muted"
                                                            style={{
                                                              display: "block",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                marginRight: "5px",
                                                              }}
                                                            >
                                                              •
                                                            </span>{" "}
                                                            Only alphanumeric
                                                            characters are allowed
                                                            (e.g. John123).
                                                            <br />
                                                          </small>
                                                        )}
                                                      {question.inputFormat ===
                                                        "open" && (
                                                          <small
                                                            className="text-muted"
                                                            style={{
                                                              display: "block",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                marginRight: "5px",
                                                              }}
                                                            >
                                                              •
                                                            </span>{" "}
                                                            You can enter any
                                                            characters.
                                                            <br />
                                                          </small>
                                                        )}
                                                    </div>

                                                    {/* Display form errors if present */}
                                                    {formErrors[question.id] && (
                                                      <FormHelperText error>
                                                        {formErrors[question.id]}
                                                      </FormHelperText>
                                                    )}
                                                  </div>
                                                )}
                                              {question.answerType ===
                                                "signature" && (
                                                  <>
                                                    <ul className="nav nav-tabs">
                                                      <li className="nav-item">
                                                        <a
                                                          className={
                                                            signatureType ===
                                                              "draw" ||
                                                              signatureType === ""
                                                              ? "nav-link active"
                                                              : "nav-link"
                                                          }
                                                          data-toggle="tab"
                                                          onClick={() =>
                                                            setSignatureType(
                                                              "draw"
                                                            )
                                                          }
                                                          href="#tabItem6"
                                                        >
                                                          <em className="icon ni ni-edit-alt"></em>
                                                          <span>Draw</span>
                                                        </a>
                                                      </li>
                                                      {/*<li className="nav-item">
                                      <a
                                        className={
                                          signatureType === "choose"
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        data-toggle="tab"
                                        onClick={() =>
                                          setSignatureType("choose")
                                        }
                                        href="#tabItem5"
                                      >
                                        <em className="icon ni ni-list-check"></em>
                                        <span>Choose</span>
                                      </a>
                                    </li>*/}
                                                    </ul>
                                                    <div className="tab-content">
                                                      <div
                                                        className={
                                                          signatureType ===
                                                            "choose"
                                                            ? "tab-pane active"
                                                            : "tab-pane"
                                                        }
                                                        id="tabItem5"
                                                      >
                                                        <div class="example-alert mb-4">
                                                          <div class="alert alert-light">
                                                            Pick a style for your
                                                            signature (Authorized
                                                            Person Name).
                                                          </div>
                                                        </div>
                                                        <div className="form-group mb-4">
                                                          <div className="form-control-wrap">
                                                            <input
                                                              className={`form-control form-control-lg`}
                                                              onChange={(e) =>
                                                                handleChangeSignature(
                                                                  question.id,
                                                                  e.target.value
                                                                )
                                                              }
                                                              onInput={(e) => {
                                                                e.target.value =
                                                                  e.target.value.replace(
                                                                    /[^a-zA-Z0-9 ]/gi,
                                                                    ""
                                                                  );
                                                              }}
                                                              value={
                                                                signatureText
                                                              }
                                                              type="text"
                                                              maxLength={100}
                                                              placeholder="Authorized Person Name"
                                                            />
                                                          </div>
                                                          {formErrors[
                                                            question.id
                                                          ] && (
                                                              <FormHelperText error>
                                                                {
                                                                  formErrors[
                                                                  question.id
                                                                  ]
                                                                }
                                                              </FormHelperText>
                                                            )}
                                                        </div>

                                                        <div class="row">
                                                          <div className="col-sm-6">
                                                            <h6 className="title">
                                                              Choose Style
                                                            </h6>

                                                            <ul className="cc-pay-method">
                                                              <li className="cc-pay-dd dropdown">
                                                                <a
                                                                  href="#"
                                                                  className="btn btn-white btn-outline-light dropdown-toggle dropdown-indicator"
                                                                  data-toggle="dropdown"
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontFamily:
                                                                        signatureClass,
                                                                    }}
                                                                  >
                                                                    {signatureText ||
                                                                      "Your Signature"}
                                                                  </span>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-auto">
                                                                  <ul className="link-list-plain">
                                                                    {cursiveFonts.map(
                                                                      (
                                                                        font,
                                                                        index
                                                                      ) => (
                                                                        <li
                                                                          key={
                                                                            index
                                                                          }
                                                                        >
                                                                          <a
                                                                            style={{
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                              setSignatureClass(
                                                                                font.value
                                                                              )
                                                                            }
                                                                            className="cc-pay-item"
                                                                          >
                                                                            <span className="cc-pay-item-name">
                                                                              <span
                                                                                className="cc-pay-item-method"
                                                                                style={{
                                                                                  fontFamily:
                                                                                    font.value,
                                                                                }}
                                                                              >
                                                                                {signatureText !==
                                                                                  ""
                                                                                  ? signatureText
                                                                                  : font.name}
                                                                              </span>
                                                                            </span>
                                                                          </a>
                                                                        </li>
                                                                      )
                                                                    )}
                                                                  </ul>
                                                                </div>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                          <div className="col-sm-6">
                                                            <div
                                                              className="signature-preview"
                                                              id={`styled-text-container${question.id}`}
                                                              style={{
                                                                fontFamily:
                                                                  signatureClass,
                                                                fontSize: "40px",

                                                                height: "80px",
                                                                background:
                                                                  "trasnparent",
                                                                backgroundColor:
                                                                  "transparent",
                                                                border: "none",
                                                                lineHeight:
                                                                  "80px",
                                                                color: "#000",
                                                              }}
                                                            >
                                                              {signatureText ||
                                                                "Your Signature"}
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div
                                                          className="col-sm-12 mt-4 pl-0"
                                                          style={{
                                                            clear: "both",
                                                          }}
                                                        >
                                                          <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            style={{
                                                              margin:
                                                                "13px 0px 12px 0px",
                                                            }}
                                                            onClick={() =>
                                                              saveChooseImage(
                                                                question.id
                                                              )
                                                            }
                                                          >
                                                            <em class="icon ni ni-folder-check"></em>{" "}
                                                            <span>
                                                              Save Signature
                                                            </span>
                                                          </button>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          signatureType ===
                                                            "draw" ||
                                                            signatureType === ""
                                                            ? "tab-pane active"
                                                            : "tab-pane"
                                                        }
                                                        id="tabItem6"
                                                      >
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <div class="example-alert mb-4">
                                                              <div class="alert alert-light">
                                                                Please Save the
                                                                signature to
                                                                proceed.
                                                              </div>
                                                            </div>
                                                            {formState[
                                                              question.id
                                                            ] && (
                                                                <div className="uploaded-file-preview">
                                                                  <img
                                                                    src={`${APP_LIVE_URL}files_data/form_response_uploads/${formState[
                                                                      question.id
                                                                    ]
                                                                      }`}
                                                                    alt="Uploaded Signature"
                                                                    style={{
                                                                      maxWidth:
                                                                        "200px",
                                                                    }}
                                                                  />
                                                                </div>
                                                              )}
                                                            <div
                                                              id={`divSig${question.id}`}
                                                              style={{
                                                                display: "none",
                                                              }}
                                                            >
                                                              <div class="mb-4">
                                                                <img
                                                                  src=""
                                                                  class="clsimgSig"
                                                                  height="100"
                                                                  width="200"
                                                                  onerror="this.style.display='none'"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div>
                                                              <strong>
                                                                Signature
                                                              </strong>
                                                            </div>
                                                          </div>

                                                          <div className="col-sm-12">
                                                            <SignatureCanvas
                                                              clearOnResize={true}
                                                              canvasProps={{
                                                                className:
                                                                  styles.sigPad,
                                                              }}
                                                              velocityFilterWeight={
                                                                0.7
                                                              }
                                                              throttle={50}
                                                              ref={(ref) =>
                                                              (sigPadRefs.current[
                                                                question.id
                                                              ] = ref)
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col-sm-12 mt-4">
                                                            <button
                                                              className="btn btn-warning"
                                                              style={{
                                                                margin:
                                                                  "13px 0px 12px 0px",
                                                              }}
                                                              onClick={() =>
                                                                clearSignature(
                                                                  question.id
                                                                )
                                                              }
                                                              type="button"
                                                            >
                                                              <em class="icon ni ni-delete"></em>{" "}
                                                              <span>Clear</span>
                                                            </button>
                                                            {/*<button
                                            className="btn btn-success"
                                            style={{
                                              margin: "13px 0px 12px 10px",
                                            }}
                                            type="button"
                                            onClick={() =>
                                              previewSignature(question.id)
                                            }
                                          >
                                            <em class="icon ni ni-eye"></em>{" "}
                                            <span>Preview</span>
                                          </button>*/}
                                                            <button
                                                              type="button"
                                                              className="btn btn-primary"
                                                              style={{
                                                                margin:
                                                                  "13px 0px 12px 10px",
                                                              }}
                                                              onClick={() =>
                                                                saveSignature(
                                                                  question.id
                                                                )
                                                              }
                                                            >
                                                              <em class="icon ni ni-folder-check"></em>{" "}
                                                              <span>
                                                                Save Signature
                                                              </span>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              {question.answerType ===
                                                "fixed" && (
                                                  <>
                                                    <div className="form-group mb-0">
                                                      <div className="form-control-wrap">
                                                        <input
                                                          className={`form-control form-control-lg`}
                                                          onChange={(e) =>
                                                            handleChangeFixed(
                                                              question.id,
                                                              question.answer
                                                            )
                                                          }
                                                          value={question.answer}
                                                          type="text"
                                                          maxLength={255}
                                                        />
                                                      </div>
                                                      {formErrors[
                                                        question.id
                                                      ] && (
                                                          <FormHelperText error>
                                                            {
                                                              formErrors[
                                                              question.id
                                                              ]
                                                            }
                                                          </FormHelperText>
                                                        )}
                                                    </div>
                                                    <CopyToClipboard
                                                      text={question.answer}
                                                      onCopy={() =>
                                                        setCopied(true)
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <div class="form-note text-primary">
                                                        <strong>
                                                          Copy to Clipboard{" "}
                                                          {copied ? (
                                                            <span>
                                                              <em className="icon ni ni-done"></em>
                                                            </span>
                                                          ) : null}
                                                        </strong>
                                                      </div>
                                                    </CopyToClipboard>
                                                  </>
                                                )}
                                              {question.answerType ===
                                                "label" && (
                                                  <div className="form-group mb-0">
                                                    <h6></h6>
                                                  </div>
                                                )}
                                              {question.answerType ===
                                                "textarea" && (
                                                  <div className="form-group">
                                                    <div className="form-control-wrap">
                                                      <textarea
                                                        className={`form-control form-control-lg`}
                                                        onChange={(e) =>
                                                          handleChange(
                                                            question.id,
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder=""
                                                        value={value}
                                                        maxLength={
                                                          question.maxLength ||
                                                          500
                                                        }
                                                      ></textarea>
                                                    </div>
                                                    {formErrors[question.id] && (
                                                      <FormHelperText error>
                                                        {formErrors[question.id]}
                                                      </FormHelperText>
                                                    )}
                                                  </div>
                                                )}
                                              {question.answerType ===
                                                "datetime" && (
                                                  <div className="form-group">
                                                    <DateTimePicker
                                                      variant="inline"
                                                      inputVariant="outlined"
                                                      fullWidth
                                                      value={
                                                        formState[question.id] ||
                                                        null
                                                      }
                                                      onChange={(date) =>
                                                        handleChange(
                                                          question.id,
                                                          date
                                                        )
                                                      }
                                                    />
                                                    {formErrors[question.id] && (
                                                      <FormHelperText error>
                                                        {formErrors[question.id]}
                                                      </FormHelperText>
                                                    )}
                                                  </div>
                                                )}
                                              {question.answerType ===
                                                "date" && (
                                                  <div className="form-group">
                                                    <DatePicker
                                                      variant="inline"
                                                      inputVariant="outlined"
                                                      fullWidth
                                                      value={
                                                        formState[question.id] ||
                                                        null
                                                      }
                                                      onChange={(date) =>
                                                        handleChange(
                                                          question.id,
                                                          date
                                                        )
                                                      }
                                                    />
                                                    {formErrors[question.id] && (
                                                      <FormHelperText error>
                                                        {formErrors[question.id]}
                                                      </FormHelperText>
                                                    )}
                                                  </div>
                                                )}
                                              {question.answerType ===
                                                "time" && (
                                                  <div className="form-group">
                                                    <TimePicker
                                                      variant="inline"
                                                      inputVariant="outlined"
                                                      fullWidth
                                                      value={
                                                        formState[question.id] ||
                                                        null
                                                      }
                                                      onChange={(time) =>
                                                        handleChange(
                                                          question.id,
                                                          time
                                                        )
                                                      }
                                                    />
                                                    {formErrors[question.id] && (
                                                      <FormHelperText error>
                                                        {formErrors[question.id]}
                                                      </FormHelperText>
                                                    )}
                                                  </div>
                                                )}
                                              {question.answerType ===
                                                "dropdown" && (
                                                  <div className="form-group">
                                                    <div className="form-control-wrap">
                                                      <div className="form-control-select">
                                                        <select
                                                          className={`form-control form-control-lg`}
                                                          onChange={(e) =>
                                                            handleChange(
                                                              question.id,
                                                              e.target.value
                                                            )
                                                          }
                                                          value={value}
                                                        >
                                                          <option value="">
                                                            Select an option
                                                          </option>
                                                          {question.options.map(
                                                            (option, index) => (
                                                              <option
                                                                key={index}
                                                                value={option}
                                                              >
                                                                {option}
                                                              </option>
                                                            )
                                                          )}
                                                        </select>
                                                      </div>
                                                    </div>
                                                    {formErrors[question.id] && (
                                                      <FormHelperText error>
                                                        {formErrors[question.id]}
                                                      </FormHelperText>
                                                    )}
                                                  </div>
                                                )}
                                              {question.answerType ===
                                                "checkbox" && (
                                                  <>
                                                    <div className="mt-2 prettyprint ">
                                                      <div className="row">
                                                        {question.options.map(
                                                          (option, index) => (
                                                            <div
                                                              className="col-md-6 mb-1"
                                                              key={`options_${index}`}
                                                            >
                                                              <div className="custom-control custom-control-md custom-checkbox custom-control-pro">
                                                                <input
                                                                  type="checkbox"
                                                                  className="custom-control-input vdrSelected"
                                                                  id={`question_checkbox_${question.id}_${index}`}
                                                                  checked={
                                                                    formState[
                                                                      question.id
                                                                    ]?.includes(
                                                                      option
                                                                    ) || false
                                                                  }
                                                                  onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                      question.id,
                                                                      option,
                                                                      e.target
                                                                        .checked
                                                                    )
                                                                  }
                                                                />
                                                                <label
                                                                  className="custom-control-label"
                                                                  htmlFor={`question_checkbox_${question.id}_${index}`}
                                                                >
                                                                  {option}
                                                                </label>
                                                              </div>
                                                              {formErrors[
                                                                question.id
                                                              ] && (
                                                                  <FormHelperText
                                                                    error
                                                                  >
                                                                    {
                                                                      formErrors[
                                                                      question.id
                                                                      ]
                                                                    }
                                                                  </FormHelperText>
                                                                )}
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                )}

                                              {question.answerType ===
                                                "radio" && (
                                                  <div className="form-pm-group">
                                                    <ul className="buysell-pm-list">
                                                      {question.options.map(
                                                        (option, index) => (
                                                          <li
                                                            className="buysell-pm-item"
                                                            key={index}
                                                          >
                                                            <input
                                                              className="buysell-pm-control"
                                                              type="radio"
                                                              name={`radio_${question.id}`}
                                                              id={`radio_${question.id}_${index}`}
                                                              onChange={(e) =>
                                                                handleChange(
                                                                  question.id,
                                                                  option
                                                                )
                                                              }
                                                              value={option}
                                                            />
                                                            <label
                                                              className="buysell-pm-label"
                                                              htmlFor={`radio_${question.id}_${index}`}
                                                            >
                                                              <span className="pm-name">
                                                                {option}
                                                              </span>
                                                              <span className="pm-icon">
                                                                <em className="icon ni ni-question"></em>
                                                              </span>
                                                            </label>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                    {formErrors[question.id] && (
                                                      <FormHelperText error>
                                                        {formErrors[question.id]}
                                                      </FormHelperText>
                                                    )}
                                                  </div>
                                                )}
                                              {question.answerType ===
                                                "image_input" && (
                                                  <div className="form-group">
                                                    <div
                                                      className="form-control-group"
                                                      id="fileContainer"
                                                    >
                                                      <input
                                                        type="file"
                                                        id={`add_picture_${question.id}`}
                                                        className="file-block"
                                                        name="add_picture"
                                                        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlx, .xlxs, .csv"
                                                        onChange={(e) =>
                                                          handleFileChange(
                                                            question.id,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      {/* Display the uploaded file name or image preview if the URL exists in the state */}
                                                      {formState[question.id] && (
                                                        <div className="uploaded-file-preview">
                                                          {formState[
                                                            question.id
                                                          ].match(
                                                            /\.(jpeg|jpg|png)$/i
                                                          ) ? (
                                                            <img
                                                              src={`${APP_LIVE_URL}files_data/form_response_uploads/${formState[
                                                                question.id
                                                              ]
                                                                }`}
                                                              alt="Uploaded preview"
                                                              style={{
                                                                maxWidth: "100px",
                                                              }}
                                                            />
                                                          ) : (
                                                            <p>
                                                              {formState[
                                                                question.id
                                                              ]
                                                                .split("/")
                                                                .pop()}
                                                            </p> // Display file name for non-images
                                                          )}
                                                        </div>
                                                      )}
                                                    </div>
                                                    {formErrors[question.id] && (
                                                      <FormHelperText error>
                                                        {formErrors[question.id]}
                                                      </FormHelperText>
                                                    )}
                                                  </div>
                                                )}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "20px",
                            }}
                            class="border-top"
                          >
                            <ul
                              role="menu"
                              aria-label="Pagination"
                              className="mt-4"
                            >
                              {currentStep > 0 && (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => setCurrentStep(currentStep - 1)}
                                >
                                  Back
                                </Button>
                              )}
                              {currentStep < groups.length - 1 ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleNextStep}
                                  style={{ marginLeft: "10px" }}
                                >
                                  Save & Continue
                                </Button>
                              ) : (
                                <>
                                  {allow_submit === 1 && (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={handleSubmit}
                                      style={{ marginLeft: "10px" }}
                                      disabled={loading}
                                    >
                                      {loading ? "Loading..." : "Save & Finish"}
                                    </Button>
                                  )}
                                  {allow_submit === 0 && (
                                    <button
                                      type="button"
                                      class="btn btn-outline-warning"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Submission is disabled for this form.
                                    </button>
                                  )}
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              hasUngroupedQuestions && (
                <>
                  <div className="nk-block nk-block-lg">
                    <div className="card">
                      <form
                        className="nk-stepper stepper-init is-alter nk-stepper-s1"
                        action="#"
                        id="stepper-create-project"
                        data-step-current="2"
                        noValidate
                        style={{ display: "block" }}
                      >
                        <div className="row g-0 col-sep col-sep-md col-sep-xl">
                          <div className="col-md-3 col-xl-3">
                            <div className="card-inner">
                              <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                                {groups.map((group, index) => (
                                  <li
                                    key={group.id}
                                    className={
                                      currentStep === index
                                        ? "current"
                                        : currentStep > index
                                          ? "done"
                                          : ""
                                    }
                                  >
                                    <div className="step-item">
                                      <div className="step-text">
                                        <div className="lead-text">
                                          {group.group_title}
                                        </div>
                                        <div
                                          className="sub-text"
                                          dangerouslySetInnerHTML={{
                                            __html: group.group_desc,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div className="col-md-9 col-xl-9">
                            <div className="card-inner">
                              <div className="nk-stepper-content">
                                <div className="nk-stepper-steps stepper-steps">
                                  <div className="nk-kycfm">
                                    <div
                                      className="nk-kycfm-head"
                                      style={{ background: "#f5f6fa" }}
                                    >
                                      <div className="nk-kycfm-title">
                                        <h5 className="title">
                                          {formTitle || "Untitled Form"}
                                        </h5>
                                        <p className="sub-title">
                                          {formDescription || "Untitled Form"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="nk-kycfm-content">
                                      {responseMessage && (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: responseMessage,
                                          }}
                                        ></div>
                                      )}
                                      {errorMessage && (
                                        <div class="alert alert-danger alert-icon mb-3">
                                          <em class="icon ni ni-cross-circle"></em>{" "}
                                          Please fill out all the required
                                          questions!
                                        </div>
                                      )}
                                      <div className="row g-gs">
                                        {ungroupedQuestions.map((question) => (
                                          <div
                                            key={question.id}
                                            className="question-container col-sm-6"
                                          >
                                            <div className="form-group question-text mb-0">
                                              <label className="form-label">
                                                {question.questionText}{" "}
                                                {question.required && (
                                                  <sup className="text-danger">
                                                    *
                                                  </sup>
                                                )}
                                              </label>
                                            </div>
                                            {question.imageUrl && (
                                              <img
                                                src={question.imageUrl}
                                                alt="Admin Uploaded"
                                                className="question-image"
                                              />
                                            )}
                                            {question.answerType ===
                                              "input" && (
                                                <div className="form-group mb-0">
                                                  <div className="form-control-wrap">
                                                    <input
                                                      className={`form-control form-control-lg`}
                                                      onChange={(e) =>
                                                        handleChange(
                                                          question.id,
                                                          e.target.value
                                                        )
                                                      }
                                                      type="text"
                                                      maxLength={
                                                        question.maxLength || 100
                                                      }
                                                    />
                                                  </div>
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            {question.answerType ===
                                              "textarea" && (
                                                <div className="form-group">
                                                  <div className="form-control-wrap">
                                                    <textarea
                                                      className={`form-control form-control-lg`}
                                                      onChange={(e) =>
                                                        handleChange(
                                                          question.id,
                                                          e.target.value
                                                        )
                                                      }
                                                      placeholder=""
                                                      maxLength={
                                                        question.maxLength || 500
                                                      }
                                                    ></textarea>
                                                  </div>
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            {question.answerType ===
                                              "datetime" && (
                                                <div className="form-group">
                                                  <DateTimePicker
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    value={
                                                      formState[question.id] ||
                                                      null
                                                    }
                                                    onChange={(date) =>
                                                      handleChange(
                                                        question.id,
                                                        date
                                                      )
                                                    }
                                                  />
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            {question.answerType === "date" && (
                                              <div className="form-group">
                                                <DatePicker
                                                  variant="inline"
                                                  inputVariant="outlined"
                                                  fullWidth
                                                  value={
                                                    formState[question.id] ||
                                                    null
                                                  }
                                                  onChange={(date) =>
                                                    handleChange(
                                                      question.id,
                                                      date
                                                    )
                                                  }
                                                />
                                                {formErrors[question.id] && (
                                                  <FormHelperText error>
                                                    {formErrors[question.id]}
                                                  </FormHelperText>
                                                )}
                                              </div>
                                            )}
                                            {question.answerType === "time" && (
                                              <div className="form-group">
                                                <TimePicker
                                                  variant="inline"
                                                  inputVariant="outlined"
                                                  fullWidth
                                                  value={
                                                    formState[question.id] ||
                                                    null
                                                  }
                                                  onChange={(time) =>
                                                    handleChange(
                                                      question.id,
                                                      time
                                                    )
                                                  }
                                                />
                                                {formErrors[question.id] && (
                                                  <FormHelperText error>
                                                    {formErrors[question.id]}
                                                  </FormHelperText>
                                                )}
                                              </div>
                                            )}
                                            {question.answerType ===
                                              "dropdown" && (
                                                <div className="form-group">
                                                  <div className="form-control-wrap">
                                                    <div className="form-control-select">
                                                      <select
                                                        className={`form-control form-control-lg`}
                                                        onChange={(e) =>
                                                          handleChange(
                                                            question.id,
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option value="">
                                                          Select an option
                                                        </option>
                                                        {question.options.map(
                                                          (option, index) => (
                                                            <option
                                                              key={index}
                                                              value={option}
                                                            >
                                                              {option}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    </div>
                                                  </div>
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            {question.answerType ===
                                              "checkbox" && (
                                                <>
                                                  <div className="mt-2 prettyprint ">
                                                    <div className="row">
                                                      {question.options.map(
                                                        (option, index) => (
                                                          <div
                                                            className="col-md-6 mb-1"
                                                            key={`options_${index}`}
                                                          >
                                                            <div className="custom-control custom-control-md custom-checkbox custom-control-pro">
                                                              <input
                                                                type="checkbox"
                                                                className="custom-control-input vdrSelected"
                                                                id={`question_checkbox_${question.id}_${index}`}
                                                                checked={
                                                                  formState[
                                                                    question.id
                                                                  ]?.includes(
                                                                    option
                                                                  ) || false
                                                                }
                                                                onChange={(e) =>
                                                                  handleCheckboxChange(
                                                                    question.id,
                                                                    option,
                                                                    e.target
                                                                      .checked
                                                                  )
                                                                }
                                                              />
                                                              <label
                                                                className="custom-control-label"
                                                                htmlFor={`question_checkbox_${question.id}_${index}`}
                                                              >
                                                                {option}
                                                              </label>
                                                            </div>
                                                            {formErrors[
                                                              question.id
                                                            ] && (
                                                                <FormHelperText
                                                                  error
                                                                >
                                                                  {
                                                                    formErrors[
                                                                    question.id
                                                                    ]
                                                                  }
                                                                </FormHelperText>
                                                              )}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              )}

                                            {question.answerType ===
                                              "radio" && (
                                                <div className="form-pm-group">
                                                  <ul className="buysell-pm-list">
                                                    {question.options.map(
                                                      (option, index) => (
                                                        <li
                                                          className="buysell-pm-item"
                                                          key={index}
                                                        >
                                                          <input
                                                            className="buysell-pm-control"
                                                            type="radio"
                                                            name={`radio_${question.id}`}
                                                            id={`radio_${question.id}_${index}`}
                                                            onChange={(e) =>
                                                              handleChange(
                                                                question.id,
                                                                option
                                                              )
                                                            }
                                                          />
                                                          <label
                                                            className="buysell-pm-label"
                                                            htmlFor={`radio_${question.id}_${index}`}
                                                          >
                                                            <span className="pm-name">
                                                              {option}
                                                            </span>
                                                            <span className="pm-icon">
                                                              <em className="icon ni ni-question"></em>
                                                            </span>
                                                          </label>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            {question.answerType ===
                                              "image_input" && (
                                                <div className="form-group">
                                                  <div
                                                    className="form-control-group"
                                                    id="fileContainer"
                                                  >
                                                    <input
                                                      type="file"
                                                      id={`add_picture_${question.id}`}
                                                      className="file-block"
                                                      name="add_picture"
                                                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlx, .xlxs, .csv"
                                                      onChange={(e) =>
                                                        handleFileChange(
                                                          question.id,
                                                          e
                                                        )
                                                      }
                                                    />
                                                    {/* Display the uploaded file name or image preview if the URL exists in the state */}
                                                    {formState[question.id] && (
                                                      <div className="uploaded-file-preview">
                                                        {formState[
                                                          question.id
                                                        ].match(
                                                          /\.(jpeg|jpg|png)$/i
                                                        ) ? (
                                                          <img
                                                            src={`${APP_LIVE_URL}files_data/form_response_uploads/${formState[
                                                              question.id
                                                            ]
                                                              }`}
                                                            alt="Uploaded preview"
                                                            style={{
                                                              maxWidth: "100px",
                                                            }}
                                                          />
                                                        ) : (
                                                          <p>
                                                            {formState[
                                                              question.id
                                                            ]
                                                              .split("/")
                                                              .pop()}
                                                          </p> // Display file name for non-images
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                  {formErrors[question.id] && (
                                                    <FormHelperText error>
                                                      {formErrors[question.id]}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "20px",
                              }}
                            >
                              {allow_submit === 1 && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleSubmit}
                                  style={{ marginLeft: "10px" }}
                                >
                                  Submit
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )
            )}
          </>
        ) : null}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default FormRendererClient;
